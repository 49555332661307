import React from 'react';
import {Button , ButtonGroup} from '@mui/material';

import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import './Auditorias.css';


function Auditorias() {
  //let hoteles = localStorage.getItem('hoteles');
  //hoteles.unshift({"value":"0","label":""});


  //Boton Añadir / Filtrar/ Respuestas.
  return (
    <div>
      <div className='header'>
        <div className='containerAcciones'>
          <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">

            <Button
              className="boton"
              onClick={""}
            >
              <RotateLeftIcon className='icon-button' />
            </Button>
            <Button
              className="boton nuevoParte"
              onClick={""}
            >
              <AddCircleOutlineIcon className='icon-button' />
            </Button>
            <Button
              className="boton filtro"
              onClick={""}
            >
              <FilterAltIcon className='icon-button' />
            </Button>

          </ButtonGroup>
          <div className='containerAcciones'>

          </div>
        </div>

      </div>
      <div className='grid-container'>

      </div>

    </div>
  );
}

export default Auditorias;