import * as React from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { amber, grey } from '@mui/material/colors';
// 
import Navbar from './components/Navbar/Navbar'
// 
import { Provider } from 'react-redux'
import generateStore from './store';

import Auditorias from './pages/Auditorias/Auditorias';
import Pisos from './pages/Pisos/Pisos';
import Maquinas from './pages/Maquinas/Maquinas';
import ConfMaquinas from './pages/Maquinas/ConfMaquinas';
import Lecturas from './pages/Lecturas/Lecturas';
import Prueba from './components/Prueba';
import Login from './pages/Login/Login';
import Partes from './pages/Partes/Partes';
import Salidas from './pages/Partes/Salidas';
import Ajustes from './pages/Ajustes/Ajustes';
import Usuarios from './pages/Configuracion/Usuarios/Usuarios';
import Home from './pages/Home/Home';
import SubirYConsultar from './pages/Documentos/SubirYConsultar';
import Cristian from './pages/Cristian';
import './App.css';
import { current } from "@reduxjs/toolkit";



const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...amber,
      ...(mode === 'dark' && {
        main: amber[300],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: '#131418',
        paper: '#131418',
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
          primary: grey[900],
          secondary: grey[800],
        }
        : {
          primary: '#fff',
          secondary: grey[500],
        }),
    },
  },
});
const theme = createTheme({
  typography: {
    fontSize: 12,
  }
});
const darkModeTheme = createTheme(getDesignTokens('light'));

function App() {

  let token = localStorage.getItem('token');
  if (token) {
    if (token !== 'CADUCADO') {
      const store = generateStore()
      return (
        <div>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes>
                <Route path="/" element={<Navbar />} exact>

                  <Route path="Auditorias" element={<Auditorias />} />
                  <Route path="Pisos" element={<Pisos />} />
                  <Route path="Maquinas" element={<Maquinas />} />
                  <Route path="Lecturas" element={<Lecturas />} />
                  <Route path="ConfMaquinas" element={<ConfMaquinas />} />
                  <Route path="Usuarios" element={<Usuarios />} />
                  <Route path="Prueba/*" element={<Prueba />} />
                  <Route path="Partes/Salidas" element={<Salidas />} />
                  <Route index path="Partes/Entradas" element={<Partes />} />
                  <Route path="Partes/Entradas/:tipoEstado" element={<Partes />} />

                  <Route index path="Ajustes" element={<Ajustes />} />
                  <Route index path="Home" element={<Home />} />
                  <Route index path="SubirYConsultar" element={<SubirYConsultar />} />
                  <Route index path="Cristian" element={<Cristian />} />

                  <Route path="" element={<Navigate to="Home" />} />
                  <Route path="*" element={<Navigate to="Home" />} />
                  <Route path="/*" element={<Navigate to="Home" />} />

                </Route>
              </Routes>

              <Outlet />
            </ThemeProvider>
          </Provider>
        </div>
      );
    } else {
/*
      Object.keys(localStorage).map((id) => {
        //Unicamente no borraremos el idHotel de configuracion.
        if (id !== 'idHotelSeleccionado') {
          localStorage.removeItem(id);
        }
        //return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
      })
      */
      return (
        <Routes>
          <Route path="/*" element={<Login />} />
          <Route path="" element={<Navigate to="Login" />} />
          <Route path="*" element={<Navigate to="Login" />} />
          <Route path="/*" element={<Navigate to="Login" />} />
        </Routes>
      )
    }

  } else {
    return (
      <Routes>
        <Route path="/*" element={<Login />} />
        <Route path="" element={<Navigate to="Login" />} />
        <Route path="*" element={<Navigate to="Login" />} />
        <Route path="/*" element={<Navigate to="Login" />} />
      </Routes>
    )
  }
}
export default App;
