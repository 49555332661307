import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import GridParte from '../../components/GridParte/GridParte';
import DatosParte from '../../components/DatosParte/DatosParte';
import './Partes.css';

function Partes() {//Si recibe un estado de los botones de Home
  const [actuGridParte, setActuGridParte] = useState(false);
  const { tipoEstado } = useParams();

  // console.log("En Partes");
  // console.log(tipoEstado);

  const datosParteAParte = (datos) => {
    if (datos === true) {
      setActuGridParte(true);
      const timer2 = setTimeout(() => {
        setActuGridParte(false);
      }, 1000);
      return () => clearTimeout(timer2);
    }
  }
  return (
    <>
      <div className='container'>
        <div className='content'>
          <div className='article'>
            {/*Se pasa por parametro*/}
            <GridParte tipoSalidas={0} actuGridParte={actuGridParte} tipoEstado={tipoEstado} />
          </div>
          <div className='aside' >
            <DatosParte tipoSalidas={0} datosParteAParte={datosParteAParte} />
          </div>
        </div>
      </div>
    </>
  )
}
export default Partes;