import React, { useState, useEffect } from 'react';
import { Button, Typography, Tooltip, tooltipClasses, styled, Snackbar, Alert, ListItemText, Zoom, ButtonGroup, CardHeader, Avatar, IconButton, TextField, MenuItem, List, ListItemButton, ListItem } from '@mui/material';

import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './Lecturas.css';

import CargaSpinner from '../../components/AccionesPartes/CargaSpinner';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(10),
    border: '1px solid #dadde9',

  },
}));


function Lecturas() {

  let hoteles = localStorage.getItem('hoteles');
  const [hotelesLista, SetHotelesLista] = useState([]);
  const [hotelTrabajo, SetHotelTrabajo] = useState(0);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [severidad, setSeveridad] = React.useState('success');
  const [habitaciones, setHabitaciones] = React.useState([]);

  const [contadorSpinner, setContadorSpinner] = React.useState(false);

  const [actualizo, SetActualizo] = useState(true);

  const [estadoInicial, setEstadoInicial] = React.useState(true);

  const actualizar = () => {
    SetActualizo(!actualizo);
  }
  const ocultarAlerta = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };
  const cambiaHotelTrabajo = (event) => {
    if (event.target.name === 'id_hotel') {
      SetHotelTrabajo(event.target.value);
    }
  }
  const fd = new FormData();
  const requestOptions = {
    method: 'POST',
    body: fd
  };

  useEffect(() => {


    // hoteles.unshift({"value":"0","label":""});
    let listaHoteles = JSON.parse(hoteles);
    //listaHoteles.unshift({ "value": "", "label": "" });
    SetHotelesLista(listaHoteles);

    //Si tenemos un hotel guardado en el localStorage utilizando Ajustes.
    let idHotel = localStorage.getItem('idHotelSeleccionado');
    if (estadoInicial) {
      if (idHotel) {
        SetHotelTrabajo(idHotel);
        setEstadoInicial(false);
      } else {
        console.log(listaHoteles);
      }
    }

    if (hotelTrabajo !== 0) {
      //setDepartamentosLista('');

    } else {
      let estructura = [];
      estructura.push(
        <div className='noEncontrado'>{"No hay ningun hotel seleccionado."}
          <ErrorOutlineIcon className='icon-button-Editar ' />
        </div>
      );
    }
    const interval = setInterval(() => {
      actualizar();
    }, 5 * 60 * 1000); // 5 minutos * 60 segundos/minuto * 1000 milisegundos/segundo

    // Limpieza del intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, [hotelTrabajo, actualizo]);


  return (
    <>
      <div className="container">
        <div className='content' style={{ "height": "70px" }}>
          <div className='article'>
            <div className='header'>
              <div className='containerAcciones contenidoIzquierda'>
                <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <>
                        <Typography color="inherit">{"Actualizar"}</Typography>
                      </>
                    }
                  >
                    <Button
                      className="boton"
                      onClick={actualizar}
                    >
                      <RotateLeftIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                </ButtonGroup>
              </div>
            </div>
            <div className='grid-container'>
              <div className='lecturas-list-container'>
                <List>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <div className='lectura-flex'>
                        <div><span>#3</span></div>
                        <div><span>Lecturas hotel</span></div>
                        <div><span>Semanal</span></div>
                        <div><span>07-09-2024 17:13:00</span></div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                </List>

              </div>
            </div>
          </div>
          <div className="aside">
            <div className='header'>
              <TextField
                className='fifty'
                variant="outlined"
                margin="normal"
                label="Hotel"
                name="id_hotel"
                size="small"

                value={hotelTrabajo ? hotelTrabajo : ""}
                onChange={cambiaHotelTrabajo}
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { maxHeight: { sm: 36 * 8 + 8 } }
                    }
                  }
                }}
              >
                {hotelesLista.map((hotel) => (
                  <MenuItem key={hotel.value} value={hotel.value} >
                    {hotel.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className='grid-container'>
              Test
            </div>
          </div>
        </div>

        <CargaSpinner contadorSpinner={contadorSpinner} />
        <Snackbar open={openAlert} autoHideDuration={3000} onClose={ocultarAlerta}>
          <Alert onClose={ocultarAlerta} severity={severidad} sx={{ width: '100%' }}>
            {mensaje}
          </Alert>
        </Snackbar>
      </div >


    </>
  );
}

export default Lecturas;