import React, { useState, useEffect } from 'react';

import './Maquinas.css';

import { DataGrid } from '@mui/x-data-grid';
import {
  IconButton, ButtonGroup, Tooltip, MenuItem, tooltipClasses, Typography, styled, Zoom, Button, TextField, Dialog, DialogActions,
  DialogContent, DialogTitle, useMediaQuery, Checkbox, FormControlLabel, List, ListItem, ListItemButton, ListItemText, Box, Autocomplete,
  Alert, Snackbar, Badge, Tab, Tabs, Collapse, InputAdornment, FormControl, InputLabel, Select, OutlinedInput, Chip
} from '@mui/material';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import PrintIcon from '@mui/icons-material/Print';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SearchIcon from '@mui/icons-material/Search';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

import CancelIcon from '@mui/icons-material/Cancel';

import { useTheme } from '@mui/material/styles';
import MostrarError from '../../components/AccionesPartes/MostrarError';
import CargaSpinner from '../../components/AccionesPartes/CargaSpinner';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(10),
    border: '1px solid #dadde9',

  },
}));

const columnas = [
  {
    headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
  },
  {
    headerClassName: 'header-grid', field: 'actualizado', headerName: 'Actualizado', width: 90,
  },
  {
    headerClassName: 'header-grid', field: 'nombre', headerName: 'Nombre', width: 500,
  },
  {
    headerClassName: 'header-grid', field: 'n_serie', headerName: 'Num Serie', width: 160,
  },
  {
    headerClassName: 'header-grid', field: 'grupo', headerName: 'Grupo', width: 200,
  },
  {
    headerClassName: 'header-grid', field: 'hotel', headerName: 'Hotel', width: 160,
  },
  {
    headerClassName: 'header-grid', field: 'departamento', headerName: 'Departamento', width: 160,
  },
  {
    headerClassName: 'header-grid', field: 'activo', headerName: 'Activo', width: 80,
  },
  {
    headerClassName: 'header-grid',
    field: 'borrar',
    headerName: 'Borrar',
    width: 80,
    renderCell: (params) => {
      const onClickBorrar = () => handleBorrar(params.row.id);
      return (
        <HtmlTooltip
          placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
          title={
            <React.Fragment>
              <Typography color="inherit">{"Borrar"}</Typography>
            </React.Fragment>
          }
        >
          <Button
            className="nuevoParte"
            onClick={onClickBorrar}
          >
            <DeleteIcon className='icon-button' />
          </Button>
        </HtmlTooltip>
      );
    },
  }
]

const columnasGruposCaracteristicas = [
  {
    headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80
  },
  {
    headerClassName: 'header-grid', field: 'hotel', headerName: 'Hotel', width: 200
  },
  {
    headerClassName: 'header-grid', field: 'caracteristica', headerName: 'Caracteristica', width: 300
  },
  {
    headerClassName: 'header-grid', field: 'tipo', headerName: 'Tipo', width: 300
  },
  {
    headerClassName: 'header-grid', field: 'valor', headerName: 'Valor', width: 100
  },
  {
    headerClassName: 'header-grid', field: 'unidad', headerName: 'Unidad', width: 120
  }
]

// Función para manejar el clic en el botón de "Borrar"
const handleBorrar = (id) => {
  // Aquí puedes implementar la lógica para borrar la fila con el ID proporcionado
  console.log(`Borrar fila con ID: ${id}`);
};

function Maquinas() {
  const API_URL = 'https://api.grupotel.com/workers.2/';
  const Api_Maquinas_Img = 'https://api.grupotel.com/workers.2/imagenes/maquinas/';
  const Api_Maquinas_Antiguo_Img = 'https://api.grupotel.com/workers/imagenes/maquinas/';

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  let hoteles = localStorage.getItem('hoteles');

  const [hotelesLista, SetHotelesLista] = useState([]);
  const [hotelTrabajo, SetHotelTrabajo] = useState(0);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [severidad, setSeveridad] = React.useState('success');

  const [estadoInicial, setEstadoInicial] = React.useState(true);

  const [actualizo, SetActualizo] = useState(true);

  //Boolean para mostrar o ocultar el spinner de carga
  const [contadorSpinner, setContadorSpinner] = React.useState(false);

  //Pruebas para controlar el Focus en DataGrid
  const [filaSeleccionada, setFilaSeleccionada] = useState(null);

  const [listaMaquinas, setListaMaquinas] = useState([]);
  const [nuevaMaquina, setNuevaMaquina] = React.useState(false);
  const [mostrarMaquina, setMostrarMaquina] = React.useState(false);
  // Controlador del Dialog para borrar la imagen de una maquina
  const [estadoBorrarImagen, setEstadoBorrarImagen] = React.useState(false);
  const [imagenMaquina, setImagenMaquina] = useState(0);


  const [btnActivo, setBtnActivo] = React.useState(false);
  const [estadoFiltros, setEstadoFiltros] = React.useState(false);
  const [activoFiltro, setActivoFiltro] = useState(false);
  const [gruposLista, setGruposLista] = useState([]);
  const [proveedoresLista, setProveedoresLista] = useState([]);
  const [departamentosLista, setDepartamentosLista] = useState([]);

  // Crear Parte
  const [estadoNuevoParte, setEstadoNuevoParte] = React.useState(false);

  const limpiarDatosRuta = () => {
    setRutaActual({ idDepartamento: '', nombre: '' });
  }

  //Tabs del Dialog de Mostrar informacion de la maquina
  const [tabValue, setTabValue] = useState(0); // Estado para la pestaña activa
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    let id = datosInfoMaquina.id;
    switch (newValue) {
      case 0:
        getInfoMaquina(id);
        break;
      case 1:
        getFichaMaquina(id);
        break;
      case 2:
        getFotosMaquina(id);
        break;
      case 3:
        getArchivosMaquina(id);
        break;
      case 4:
        listarTodosPartesMaquina(id);
        break;
    }
  };

  //Conjunto para mostrar un Alert con el resultado de la petición
  const [mensajeError, setMensajeError] = useState('');
  const [tipoError, setTipoError] = useState('');
  const [contadorError, setContadorError] = useState(1);
  const [estadoAlert, setEstadoAlert] = React.useState(false);

  //Funcion basica para mostrar alert de errores o success.
  const mostrarError = (mensajeError, tipoError) => {
    setMensajeError(mensajeError);
    if (tipoError) {
      setTipoError(tipoError);
    } else {
      setTipoError("error");
    }
    setContadorError(contadorError + 1);
    setEstadoAlert(true);
    const timer2 = setTimeout(() => {
      setEstadoAlert(false);
    }, 3000);
    return () => clearTimeout(timer2);
  };

  const ocultarAlerta = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const cambiaHotelTrabajo = (event) => {
    if (event.target.name === 'id_hotel') {
      SetHotelTrabajo(event.target.value);
    }
  }

  const actualizar = () => {
    SetActualizo(!actualizo);
  }

  const fd = new FormData();
  const requestOptions = {
    method: 'POST',
    body: fd
  };

  const [datosFiltroMaquina, setDatosFiltroMaquina] = useState({
    id: '',
    nombre: '',
    n_serie: '',
    grupo: -1,
    activo: false,
    actualizado: 0,
    noactualizado: 0
  });

  const [datosInfoMaquina, setDatosInfoMaquina] = useState({
    id: '',
    id_departamento: '',
    id_grupo: '',
    id_hotel: '',
    id_lugar: '',
    n_serie: '',
    modelo: '',
    nombre: '',
    cif_instalador: '',
    cif_mantenedor: '',
    fabricante: '',
    fecha_instalacion: '',
    anyo_fabricacion: '',
    alto: '',
    ancho: '',
    largo: '',
    comentarios: '',
    activo: false
  });
  //Copia de la maquina para evitar que hagan muchas solicitudes de actualizar maquina sin ningun cambio.
  const [copiaInfoMaquina, setCopiaInfoMaquina] = useState({
    id: '',
    id_departamento: '',
    id_grupo: '',
    id_hotel: '',
    id_lugar: '',
    n_serie: '',
    modelo: '',
    nombre: '',
    cif_instalador: '',
    cif_mantenedor: '',
    fabricante: '',
    fecha_instalacion: '',
    anyo_fabricacion: '',
    alto: '',
    ancho: '',
    largo: '',
    comentarios: '',
    activo: false
  });

  //Nuevas imagenes
  const [listaNuevasImagenMaquina, setListaNuevasImagenMaquina] = useState([]);

  //Imagenes de la maquina seleccionada
  const [listaImagenesMaquina, setListaImagenesMaquina] = useState([]);

  // Lista de los nuevos pdf
  const [nuevaListaPdfMaquina, setNuevaListaPdfMaquina] = useState([]);
  // Pdf asociados a la maquina. En maquinas documentos.
  const [listaPdfMaquina, setListaPdfMaquina] = useState([]);
  // Documentos de la maquina. Documentos > Subir/Consultar
  const [listaArchivosMaquina, setListaArchivosMaquina] = useState([]);

  // Id del Documento a borrar.
  const [documentoMaquina, setDocumentoMaquina] = useState(0);
  const [dialogConfirmDocumento, setDialogConfirmDocumento] = React.useState(false);


  const [listaPartesMaquina, setListaPartesMaquina] = useState([]);
  //Listado de Fotos del parte
  const [listaFotosParte, setListaFotosParte] = useState([]);
  //Listado de PDF del parte
  const [listaPdfParte, setListaPdfParte] = useState([]);


  const getMaquinas = () => {
    let maquinas = [];
    const datos = datosFiltroMaquina;
    let params = '';

    Object.keys(datos).forEach((nombre, i) => {
      const valor = Object.values(datos)[i];
      if (valor && valor !== '') {
        if (nombre === 'nombre') {
          params += `${nombre}='${JSON.stringify(valor)}'|`;
        } else if (nombre === 'grupo') {
          const valorBloque = parseInt(valor);
          if (!isNaN(valorBloque)) {
            params += `${nombre}=${valor}|`;
          } else {
            mostrarError("El grupo debe ser un ID válido");
            return;
          }
        } else {
          params += `${nombre}=${valor}|`;
        }
      }
    });
    if (btnActivo === true) {//Si esta true
      params += 'activo=1|';
    }
    // console.log(params)

    fd.append("f", "maquinas.getMaquinas");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_hotel", hotelTrabajo);
    fd.append("params", params);

    fetch(API_URL, requestOptions)
      .then(response => response.json(), setContadorSpinner(true))
      .then(result => {
        let error = result.error;
        const handleResponse = () => {
          setContadorSpinner(false);
          if (error.id === 0) {
            delete result.error;
            const InfoMaquinas = result.maquinas;
            Object.values(InfoMaquinas).forEach(maquina => {
              const parteObj = {};
              Object.entries(maquina).forEach(([key, value]) => {
                parteObj[key] = value;
              });

              maquinas.push(parteObj);
            });
            setListaMaquinas(maquinas);
          } else if (error.id >= 501 && error.id <= 510) {
            localStorage.setItem('token', 'CADUCADO');
            window.location.reload();
          } else {
            setListaMaquinas('');
            mostrarError(error.msg);
          }
        };

        setTimeout(handleResponse, 500);
      })
      .catch(() => {
        mostrarError("No se ha podido obtener las Máquinas");
      });
  };

  /******************************************************************/
  //                   Inicio  Dialog Info Maquina                  //
  /******************************************************************/

  ///////////////////////////////////////////////
  //  Inicio  SUB Apartado Información Maquina //
  ///////////////////////////////////////////////

  const getInfoMaquina = (id_maquina) => {
    const fd = new FormData();
    fd.append("f", "maquinas.getInfoMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id_maquina);
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          // console.log("La información de la maquina: ")
          // console.log(result);
          let fechaInstalacion = result.maquina.fecha_instalacion ? result.maquina.fecha_instalacion : ''; // Si es nula o vacía, asignar una cadena vacía

          setDatosInfoMaquina(result.maquina);
          setCopiaInfoMaquina(result.maquina);

          if (!fechaInstalacion || fechaInstalacion.trim() === "" || fechaInstalacion === null || fechaInstalacion === "0000-00-00 00:00:00") {
            setDatosInfoMaquina(prevState => ({
              ...prevState,
              fecha_instalacion: ''
            }));
            setCopiaInfoMaquina(prevState => ({
              ...prevState,
              fecha_instalacion: ''
            }));
          } else {
            let soloFecha = fechaInstalacion.split(" ");
            fechaInstalacion = soloFecha[0];
            setDatosInfoMaquina(prevState => ({
              ...prevState,
              fecha_instalacion: fechaInstalacion
            }));
            setCopiaInfoMaquina(prevState => ({
              ...prevState,
              fecha_instalacion: fechaInstalacion
            }));
          }
          let ruta = '';
          if (result.ruta) {
            ruta = result.ruta;
            setRutaActual({ idDepartamento: ruta.ids, nombre: ruta.nombres });
            setRutaActualCopia({ idDepartamento: ruta.ids, nombre: ruta.nombres });
          } else {
            setRutaActual({ idDepartamento: "", nombre: "" });
            setRutaActualCopia({ idDepartamento: "", nombre: "" });
          }

        } else {
          //vaciar datosMaquina
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener la informacion de la maquina: " + id_maquina);
      })
  };

  //Falta añadir tema de caracteristicas, modificación de Lugar.

  ///////////////////////////////////////////////
  //   Fin  SUB Apartado Información Maquina   //
  ///////////////////////////////////////////////

  ///////////////////////////////////////////////
  //  Inicio  SUB Apartado Caracteristicas     //
  ///////////////////////////////////////////////

  const [listaMaquinaFicha, setListaMaquinaFicha] = useState([]);
  const [tablaMaquinaFicha, setTablaMaquinaFicha] = useState("");

  const columnasMaquinaFicha = [
    {
      headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
    },
    {
      headerClassName: 'header-grid', field: 'caracteristica', headerName: 'Caracteristica', width: 400,
    },
    {
      headerClassName: 'header-grid', field: 'tipo', headerName: 'Tipo', width: 300,
    },
    {
      headerClassName: 'header-grid', field: 'valor', headerName: 'Valor', width: 120,
    },
    {
      headerClassName: 'header-grid', field: 'unidad', headerName: 'Unidad', width: 160,
    },
    {
      headerClassName: 'header-grid',
      field: 'editar',
      headerName: 'Editar',
      width: 80,
      renderCell: (params) => {
        const onClickEditar = () => mostrarEditarFichaGrupo(params.row.id, params.row.id_caracteristica, params.row.id_caracteristica_tipo, params.row.valor);
        return (
          <HtmlTooltip
            placement="left" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
            title={
              <React.Fragment>
                <Typography color="inherit">{"Editar"}</Typography>
              </React.Fragment>
            }
          >
            <Button
              className="nuevoParte"
              onClick={onClickEditar}
            >
              <ModeEditIcon className='icon-button' />
            </Button>
          </HtmlTooltip>
        );
      },
    }, {
      headerClassName: 'header-grid',
      field: 'borrar',
      headerName: 'Borrar',
      width: 80,
      renderCell: (params) => {
        const onClickBorrar = () => mostrarBorrarFichaMaquina(params.row.id, params.row.caracteristica, params.row.tipo, params.row.valor);
        return (
          <HtmlTooltip
            placement="right" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
            title={
              <React.Fragment>
                <Typography color="inherit">{"Borrar"}</Typography>
              </React.Fragment>
            }
          >
            <Button
              className="nuevoParte borrarIcono"
              onClick={onClickBorrar}
            >
              <DeleteIcon className='icon-button ' />
            </Button>
          </HtmlTooltip>
        );
      },
    }
  ]

  const getFichaMaquina = (id_maquina, actualizo = 0) => {
    const fd = new FormData();
    fd.append("f", "maquinas.getMaquinasFicha");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id_maquina);
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          setListaMaquinaFicha(result.ficha);
          if (actualizo === 1) {
            mostrarError("Lista Actualizada", "success");
          }
        } else {
          setListaMaquinaFicha([]);
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se han podido obtener las caracteristicas de la maquina: " + id_maquina);
      })
  };

  const actualizarCrearFichaGrupo = (event) => {
    setDatosFichaMaquina({
      ...datosFichaMaquina,
      [event.target.name]: parseInt(event.target.value)
    });
    if (event.target.name === "id_caracteristica") {
      getCaracteristicasTipos(event.target.value);
    }
  };

  /**************************************************************************************************/
  //                        Crear la caracteristica de la maquina
  /**************************************************************************************************/
  const [estadoCrearFichaMaquina, setEstadoCrearFichaMaquina] = React.useState(false);
  const [creandoFichaMaquina, setCreandoFichaMaquina] = React.useState(false);

  const [datosFichaMaquina, setDatosFichaMaquina] = useState({
    id: '',
    id_caracteristica: '',
    id_caracteristica_tipo: '',
    valor: ''
  });

  const mostrarCrearFichaMaquina = () => {
    getMaquinasCaracteristicas(0, 1);
    setDatosFichaMaquina({
      id: '',
      id_caracteristica: '',
      id_caracteristica_tipo: '',
      valor: ''
    });
    setCreandoFichaMaquina(false);
    setEstadoCrearFichaMaquina(true);
  };

  const comprobarESCFichaMaquina = (event) => {
    if (event.keyCode === 27) {
      ocultarCrearFichaMaquina();
    }
  };
  const ocultarCrearFichaMaquina = () => {
    setDatosFichaMaquina({
      id: '',
      id_caracteristica: '',
      id_caracteristica_tipo: '',
      valor: ''
    });
    getFichaMaquina(datosInfoMaquina.id);
    setEstadoCrearFichaMaquina(false);
    setCreandoFichaMaquina(false);
  };

  const confirmarCrearFichaMaquina = () => {
    let id_caracteristica = datosFichaMaquina.id_caracteristica;

    if (id_caracteristica != '') {
      const fd = new FormData();
      fd.append("f", "maquinas.crearFichaMaquina");
      fd.append("token", localStorage.getItem('token'));

      let params = '';
      Object.keys(datosFichaMaquina).forEach((nombre, i) => {
        const valor = Object.values(datosFichaMaquina)[i];
        if (valor && valor !== '') {
          params += `${nombre}=${valor}|`;
        }

      });

      // console.log(params)
      fd.append("id_maquina", datosInfoMaquina.id);

      fd.append("params", params);

      const requestOptions = {
        method: 'POST',
        body: fd
      };

      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          if (error.id === 0) {
            getFichaMaquina(datosInfoMaquina.id);
            mostrarError("Caracteristica creada: " + result.id, "success");
            ocultarCrearFichaMaquina();
          } else {
            mostrarError(error.msg);
            console.log(error.msg);
          }
        })
        .catch(() => {
          mostrarError("No se ha podido crear la Caracteristica");
        })

    } else {
      mostrarError("Es necesario seleccionar una caracteristica");
    }
  };
  /**************************************************************************************************/
  //                        Fin Crear la caracteristica de la maquina
  /**************************************************************************************************/

  /**************************************************************************************************/
  //                        Editar la caracteristica de la maquina
  /**************************************************************************************************/

  const [datosFichaMaquinaCopia, setDatosFichaMaquinaCopia] = useState({
    id: '',
    id_caracteristica: '',
    id_caracteristica_tipo: '',
    valor: ''
  });

  const [estadoEditarFichaGrupo, setEstadoEditarFichaGrupo] = React.useState(false);
  const [editandoFichaGrupo, setEditandoFichaGrupo] = React.useState(false);

  const mostrarEditarFichaGrupo = (id, id_caracteristica, id_caracteristica_tipo, valor) => {
    // Obtenemos los datos de los select
    getMaquinasCaracteristicas(0, 0);
    getCaracteristicasTipos(id_caracteristica, 0, 1);

    setDatosFichaMaquina({
      id: id,
      id_caracteristica: id_caracteristica,
      id_caracteristica_tipo: id_caracteristica_tipo,
      valor: valor
    });
    setDatosFichaMaquinaCopia({
      id: id,
      id_caracteristica: id_caracteristica,
      id_caracteristica_tipo: id_caracteristica_tipo,
      valor: valor
    });
    setEditandoFichaGrupo(false);
    setEstadoEditarFichaGrupo(true);
  };

  const comprobarESCEditarFichaGrupo = (event) => {
    if (event.keyCode === 27) {
      ocultarEditarFichaGrupo();
    }
  };
  const ocultarEditarFichaGrupo = () => {
    setDatosFichaMaquina({
      id: '',
      id_caracteristica: '',
      id_caracteristica_tipo: '',
      valor: ''
    });
    setDatosFichaMaquinaCopia({
      id: '',
      id_caracteristica: '',
      id_caracteristica_tipo: '',
      valor: ''
    });
    getFichaMaquina(datosInfoMaquina.id);
    setEstadoEditarFichaGrupo(false);
    setEditandoFichaGrupo(false);
  };

  const confirmarEditarFichaGrupo = () => {
    if (JSON.stringify(datosFichaMaquina) === JSON.stringify(datosFichaMaquinaCopia)) {
      mostrarError("No hay cambios a guardar");
    } else {
      setEditandoFichaGrupo(true);

      let params = '';

      Object.keys(datosFichaMaquina).forEach((nombre) => {
        const valor = datosFichaMaquina[nombre];
        const valorCopia = datosFichaMaquinaCopia[nombre];

        if (valor !== valorCopia) { // Solo si hay diferencia
          if (nombre === "id_caracteristica_tipo") {
            // Intentamos convertir el valor a un entero
            const valorInt = parseInt(valor, 10);

            // Si el valor es vacío o NaN, asignamos NULL
            if (isNaN(valorInt)) {
              params += `${nombre}=NULL|`;
            } else {
              params += `${nombre}=${valorInt}|`;
            }
          } else {
            params += `${nombre}=${valor || ''}|`;
          }
        }
      });

      // console.log(params);

      const fd = new FormData();
      fd.append("f", "maquinas.updateFichaMaquina");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id_ficha", datosFichaMaquina.id);
      fd.append("params", params);

      const requestOptions = {
        method: 'POST',
        body: fd
      };

      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          // console.log(result)
          if (error.id === 0) {
            getFichaMaquina(datosInfoMaquina.id);
            mostrarError("Caracteristica actualizada", "success");
          } else {
            mostrarError(error.msg);
          }
          setEditandoFichaGrupo(false);
        })
        .catch(() => {
          mostrarError("No se ha podido actualizar la Caracteristica");
        })
      ocultarEditarFichaGrupo();
    }
  };


  /**************************************************************************************************/
  //                        Fin Editar la caracteristica de la maquina
  /**************************************************************************************************/
  /**************************************************************************************************/
  //                        Borrar la caracteristica de la maquina
  /**************************************************************************************************/
  const [estadoBorrarFichaMaquina, setEstadoBorrarFichaMaquina] = React.useState(false);
  const [borrandoFichaMaquina, setBorrandoFichaMaquina] = React.useState(false);

  const mostrarBorrarFichaMaquina = (id, caracteristica, tipo, valor) => {
    setDatosFichaMaquina({
      id: id,
      id_caracteristica: caracteristica,
      id_caracteristica_tipo: tipo,
      valor: valor
    });
    setBorrandoFichaMaquina(false);
    setEstadoBorrarFichaMaquina(true);
  };

  const comprobarESCBorrarFichaMaquina = (event) => {
    if (event.keyCode === 27) {
      cancelBorrarFichaMaquina();
    }
  };

  const cancelBorrarFichaMaquina = () => {
    setDatosFichaMaquina({
      id: '',
      id_caracteristica: '',
      id_caracteristica_tipo: '',
      valor: ''
    });
    getFichaMaquina(datosInfoMaquina.id);
    setBorrandoFichaMaquina(false);
    setEstadoBorrarFichaMaquina(false);
  }

  const confirmarBorrarFichaMaquina = () => {
    setBorrandoFichaMaquina(true);
    const fd = new FormData();
    fd.append("f", "maquinas.deleteFichaMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_ficha", datosFichaMaquina.id);

    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          getFichaMaquina(datosInfoMaquina.id);
          mostrarError("Borrada caracteristica: " + datosFichaMaquina.id + " " + datosFichaMaquina.id_caracteristica, "success");
        } else {
          mostrarError(error.msg);
        }
        setBorrandoFichaMaquina(false);
      })
      .catch(() => {
        mostrarError("No se ha podido Borrar la caracteristica " + datosFichaMaquina.id);
      })

    cancelBorrarFichaMaquina();
  }

  /**************************************************************************************************/
  //                        Fin Borrar la caracteristica de la maquina
  /**************************************************************************************************/

  /**************************************************************************************************/
  //                        Importar las caracteristicas del Grupo
  /**************************************************************************************************/

  // Comprobaremos si del hotel actual, el grupo de la maquina tiene caracteristicas asociadas.
  // Si es asi las mostramos y solicitamos confirmación para importar. Si ya las tiene se duplicaran.

  const [estadoListaCaracteristicasGrupo, setEstadoListaCaracteristicasGrupo] = React.useState(false);
  const [duplicandoListaCaracteristicasGrupo, setDuplicandoListaCaracteristicasGrupo] = React.useState(false);

  const [nombreGrupo, setNombreGrupo] = useState('');

  const mostrarCaracteristicasGrupo = () => {
    // console.log(datosInfoMaquina.id_hotel, " ", datosInfoMaquina.id_grupo, " ");
    // console.log(gruposLista);

    // Buscamos el label correspondiente al id_grupo
    const grupo = gruposLista.find(g => g.value == datosInfoMaquina.id_grupo);
    // Por si no tiene grupo
    setNombreGrupo(grupo ? grupo.label : '');

    if (datosInfoMaquina.id_grupo != '' && datosInfoMaquina.id_grupo != null) {
      getCaracteristicasGrupos(datosInfoMaquina.id_grupo);

      setDuplicandoListaCaracteristicasGrupo(false);
      setEstadoListaCaracteristicasGrupo(true);

    } else { // El botón esta escondido en este caso
      mostrarError("Primero en Información seleccione un Grupo .");
    }
  };

  const comprobarESCListaCaracteristicasGrupo = (event) => {
    if (event.keyCode === 27) {
      ocultarListaCaracteristicasGrupo();
    }
  };
  const ocultarListaCaracteristicasGrupo = () => {
    getFichaMaquina(datosInfoMaquina.id);
    setNombreGrupo('');
    setListaGruposCaracteristica([]);
    setEstadoListaCaracteristicasGrupo(false);
    setDuplicandoListaCaracteristicasGrupo(false);
  }
  const confirmarDuplicarListaCaracteristicasGrupo = () => {
    if (listaGruposCaracteristica.length > 0) {
      setDuplicandoListaCaracteristicasGrupo(true);
      // console.log(listaGruposCaracteristica);

      listaGruposCaracteristica.forEach((item) => {
        // Iniciamos el params
        let params = '';

        // Extraemos y modificamos los campos necesarios
        const { id_caracteristica, id_caracteristica_tipo, valor } = item;

        if (id_caracteristica && id_caracteristica !== '') {
          params += `id_caracteristica=${id_caracteristica}|`;
        }
        if (id_caracteristica_tipo !== null) { // Aquí permitimos null
          params += `id_caracteristica_tipo=${id_caracteristica_tipo || 'NULL'}|`;
        }
        if (valor && valor !== '') {
          params += `valor=${valor}|`;
        }

        crearFichaMaquina(params);
      });
      getFichaMaquina(datosInfoMaquina.id);

      setDuplicandoListaCaracteristicasGrupo(false);
      ocultarListaCaracteristicasGrupo();
    } else {
      mostrarError("El Grupo no tiene Caracteristicas asociadas");
    }
  }


  const crearFichaMaquina = (params) => {
    const fd = new FormData();
    fd.append("f", "maquinas.crearFichaMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", datosInfoMaquina.id);
    fd.append("params", params);

    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          mostrarError("Caracteristica creada: " + result.id, "success");
        } else {
          mostrarError(error.msg);
          console.log(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido crear la Caracteristica: " + params);
      });
  };


  const [listaGruposCaracteristica, setListaGruposCaracteristica] = useState([]);
  const [tablaGruposCaracteristica, setTablaGruposCaracteristica] = useState("");

  // Obtener las caracteristicas del Grupo seleccionado.
  const getCaracteristicasGrupos = (id) => {
    let params = '';

    const fd = new FormData();
    fd.append("f", "maquinas.getCaracteristicasGrupos");
    fd.append("token", localStorage.getItem('token'));

    if (hotelTrabajo != 0) {
      fd.append("id_hotel", hotelTrabajo);
    }


    params += `id_grupo=${id}|`;
    // console.log(params)

    fd.append("params", params);

    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        // console.log(result)
        if (error.id === 0) {
          if (result.gruposCaracteristicas.length > 0) {
            setListaGruposCaracteristica(result.gruposCaracteristicas);
            getFichaMaquina(datosInfoMaquina.id);
          } else {
            setListaGruposCaracteristica([]);
            mostrarError("El grupo no tiene Caracteristicas asociadas");
          }

        } else {
          setListaGruposCaracteristica([]);
          mostrarError(error.msg);
          console.log(error.msg);
        }
      })
      .catch(() => {
        setListaGruposCaracteristica([]);
        mostrarError("No se han podido obtener las caracteristicas asociadas al Grupo");
      })
  };

  const [listaCaracteristicaSelect, setListaCaracteristicaSelect] = useState([]);

  const getMaquinasCaracteristicas = (actualizado = 0) => {
    const fd = new FormData();
    fd.append("f", "maquinas.getMaquinasCaracteristicas");
    fd.append("token", localStorage.getItem('token'));
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        // console.log(result);
        if (error.id === 0) {

          const caracteristicas = Object.entries(result.maquinas_caracteristicas).map(([key, value]) => ({
            id: value.id,
            nombre: value.nombre
          }));

          setListaCaracteristicaSelect(caracteristicas.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1));

          if (actualizado == 1) {
            mostrarError("Caracteristicas actualizadas", "success");
          }
        } else {
          mostrarError(error.msg);
          console.log(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se han podido obtener las Unidades");
      })
  };

  const [listaTiposCaracteristicas, setListaTiposCaracteristicas] = useState([]);
  // Obtenemos los tipos de Caracteristicas.
  const getCaracteristicasTipos = (id, actualizo = 0, vacio = 0) => {
    const fd = new FormData();
    fd.append("f", "maquinas.getCaracteristicasTipos");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id", id);

    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        // console.log(result)
        if (error.id === 0) {
          if (result.tipos.length > 0) {
            if (vacio === 1) {
              // console.log(result.tipos)
              const tipos = Object.entries(result.tipos).map(([key, value]) => ({
                id: value.id,
                id_caracteristica: value.id_caracteristica,
                nombre: value.nombre
              }));
              tipos.unshift({ "id": "", "id_caracteristica": "", "nombre": "" })
              setListaTiposCaracteristicas(tipos);

            } else {
              setListaTiposCaracteristicas(result.tipos);
            }

            if (actualizo === 1) {
              mostrarError("Tipos obtenidos", "success");
            }

          } else {
            setListaTiposCaracteristicas([]);
            mostrarError("La caracteristica no tiene Tipos.");
          }

        } else {
          setListaTiposCaracteristicas([]);
          mostrarError(error.msg);
          console.log(error.msg);
        }
      })
      .catch(() => {
        setListaTiposCaracteristicas([]);
        mostrarError("No se han podido obtener las Tipos");
      })

  };

  ///////////////////////////////////////////////
  //   Fin  SUB Apartado Caracteristicas       //
  ///////////////////////////////////////////////

  ///////////////////////////////////////////////
  //  Inicio  SUB Apartado Imagenes / Fotos    //
  ///////////////////////////////////////////////

  //Obtener las fotos de la maquina: para conseguir mantener la ubicación en el tab sin recargar todo, no añado esta función al getInfoMaquina
  const getFotosMaquina = (id_maquina) => {
    const fd = new FormData();
    fd.append("f", "maquinas.getFotosMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id_maquina);
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          // console.log("Las imagenes son: ")
          // console.log(result.fotos);
          setListaImagenesMaquina(result.fotos);
        }
        // else {
        //   mostrarError(error.msg);
        // }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener las imagenes");
      })
  };

  const ventanaImagen = (ruta) => {
    const rutaMostrar = ruta.includes("min_") ? ruta.replace("min_", "") : ruta;
    window.open(rutaMostrar);
  };

  const selecImage = (event) => {
    const files = event.target.files;
    if (files.length > 0 && files.length <= 3) {
      // Creamos un array para almacenar las nuevas imágenes seleccionadas
      let nuevasImagenes = [];

      // Iteramos sobre los archivos seleccionados y los agregamos al array
      Array.from(files).forEach((file, index) => {
        nuevasImagenes.push({
          id: index,
          ruta: URL.createObjectURL(file),
          nombre: file.name,
          archivo: file
        });
      });

      // Actualizamos el estado con las nuevas imágenes seleccionadas
      setListaNuevasImagenMaquina(nuevasImagenes);
    } else if (files.length > 3) {
      mostrarError("Máximo 3 imagenes");
      console.log("Máximo 3 imagenes")
      setListaNuevasImagenMaquina([]);
      event.target.value = null;  // Limpiar el input

    } else {
      console.log("Máximo 3 imagenes")
      setListaNuevasImagenMaquina([]);
      event.target.value = null;  // Limpiar el input
    }
  };

  //Recibimos el id/ index realmente de la foto seleccionada.
  const borrarImagen = (id) => {
    setListaNuevasImagenMaquina(listaNuevasImagenMaquina.filter((foto) => foto.id !== id));
  };

  //Función para subir las imagenes de una maquina.
  const setImagenesMaquina = () => {

    if (listaNuevasImagenMaquina.length >= 1) {

      const fd = new FormData();
      fd.append("f", "maquinas.setImagenesMaquina");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id", datosInfoMaquina.id);

      //Recorremos las nuevas imagenes
      listaNuevasImagenMaquina.forEach((imagen, i) => {
        fd.append("imagen" + i, imagen.archivo);
      });
      const requestOptions = {
        method: 'POST',
        body: fd
      };

      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          if (error.id === 0) {
            setListaNuevasImagenMaquina([]);
            mostrarError("Maquina: " + datosInfoMaquina.id + " imagen actualizada", "success");
            setMostrarMaquina(false);
            actualizar();
          } else {
            mostrarError(error.msg);
          }
        })

        .catch(() => {
          mostrarError("No se ha podido actualizar las imagenes");
        })
    } else {
      setMostrarMaquina(false);
      actualizar();
    }
  }

  //Función para borrar una imagen de la maquina
  const delImgMaquina = () => {

    //Borrar la relación en la bbdd
    const fd = new FormData();
    fd.append("f", "maquinas.delFotoMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_foto", imagenMaquina);
    const requestOptions = {
      method: 'POST',
      body: fd
    };
    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          setImagenMaquina(0);
          setEstadoBorrarImagen(false);
          getFotosMaquina(datosInfoMaquina.id);
        } else {
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener los proveedores");
      })
  }

  //Función para mostrar el dialog, espera confirmación para borrar la imagen.
  const confirmDelImgMaquina = (id_foto) => {
    setImagenMaquina(id_foto);
    setEstadoBorrarImagen(true);
  }
  const cancelDelImgMaquina = () => {
    //En caso de cancelar y no querer borrar la imagen.
    setImagenMaquina(0);
    setEstadoBorrarImagen(false);
  }

  ///////////////////////////////////////////////
  //    Fin  SUB Apartado Imagenes / Fotos     //
  ///////////////////////////////////////////////

  ///////////////////////////////////////////////
  //    Inicio  SUB Apartado Archivos Maquina  //
  ///////////////////////////////////////////////

  const getArchivosMaquina = (id_maquina) => {

    const fd = new FormData();
    fd.append("f", "maquinas.getArchivosMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id_maquina);
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        // console.log(result)
        if (error.id === 0) {
          // console.log("Todo bien");
          setListaArchivosMaquina(result.pdf);
          setListaPdfMaquina(result.doc);
        }
        // else {
        //   mostrarError(error.msg);
        // }
      })
      .catch(() => {
        mostrarError("No se han podido obtener los documentos de la maquina " + id_maquina);
      })

  };

  const selecPdf = (event) => {
    const files = event.target.files;

    // Verificar si se seleccionó algún archivo
    if (files.length === 0) {
      setNuevaListaPdfMaquina([]);
      event.target.value = null;  // Limpiar el input
      return;
    }
    //Variable para saber el peso de los archivos.
    let pesoArchivos = 0;
    let nuevosPDFs = [];

    const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');

    // Montar el array de PDFs
    pdfs.forEach((file, index) => {
      let nombrePdf = "";
      if (file.name.length > 29) {
        nombrePdf = file.name.substring(0, 29) + "...";
      } else {
        nombrePdf = file.name;
      }
      pesoArchivos += file.size;
      nuevosPDFs.push({
        id: index,
        ruta: URL.createObjectURL(file),
        nombre: nombrePdf,
        archivo: file
      });
    });

    //Comprobación de si se supera el maximo de 32
    if (pesoArchivos >= 32000000) {
      console.log("El peso de los archivos es mayor a 32MB: " + pesoArchivos);
      mostrarError("Los archivos superan los 32 MB");
      setNuevaListaPdfMaquina([]);
      event.target.value = null;  // Limpiar el input
      return;
    }

    setNuevaListaPdfMaquina(nuevosPDFs);
  }

  const borrarNuevoPDFMaquina = (id) => {
    setNuevaListaPdfMaquina(nuevaListaPdfMaquina.filter((pdf) => pdf.id !== id));
  };

  const ventanaPdf = (rutaPdf) => {
    //Abrimos el pdf en una nueva ventana.
    window.open(rutaPdf);
  }

  const borrarPdfMaquina = () => {

    // Realizamos un update del pdf para desactivar.
    const fd = new FormData();
    fd.append("f", "maquinas.delArchivoMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_archivo", documentoMaquina);

    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          setDocumentoMaquina(0);
          setDialogConfirmDocumento(false);
          mostrarError("Maquina: " + datosInfoMaquina.id + " documentos borrado", "success");
          getArchivosMaquina(datosInfoMaquina.id);
        } else {
          mostrarError(error.msg);
        }
      })

      .catch(() => {
        mostrarError("No se ha podido borrar el documento.");
      })

  }
  const confirmarBorrarPdfMaquina = (id) => {
    // Mostramos Dialog de Confirmación para borrar el pdf.
    setDocumentoMaquina(id);
    setDialogConfirmDocumento(true);
  }
  const cancelBorrarPdfMaquina = () => {
    //En caso de cancelar y no querer borrar la imagen.
    setDocumentoMaquina(0);
    setDialogConfirmDocumento(false);
  }

  const setDocumentosMaquina = () => {

    if (nuevaListaPdfMaquina.length >= 1) {
      const fd = new FormData();
      fd.append("f", "maquinas.setArchivosMaquina");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id_maquina", datosInfoMaquina.id);
      fd.append("id_hotel", datosInfoMaquina.id_hotel);

      //Recorremos las nuevas imagenes
      nuevaListaPdfMaquina.forEach((pdf, i) => {
        fd.append("pdf" + i, pdf.archivo);
      });
      const requestOptions = {
        method: 'POST',
        body: fd
      };

      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          if (error.id === 0) {
            setNuevaListaPdfMaquina([]);
            mostrarError("Maquina: " + datosInfoMaquina.id + " documentos actualizados", "success");
            getArchivosMaquina(datosInfoMaquina.id);
          } else {
            mostrarError(error.msg);
          }
        })

        .catch(() => {
          mostrarError("No se ha podido actualizar las imagenes");
        })
    }
  }


  ///////////////////////////////////////////////
  //    Fin  SUB Apartado Archivos Maquina     //
  ///////////////////////////////////////////////

  ///////////////////////////////////////////////
  //    Inicio  SUB Apartado Partes Maquina    //
  ///////////////////////////////////////////////

  //Obtención de Los Partes de la Maquina.
  const listarTodosPartesMaquina = (id_maquina) => {

    //Peticion para obtener todos los partes de la habitacion 
    fd.append("f", "partes.getByMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id_maquina);
    fd.append("id_hotel", hotelTrabajo);

    fetch(API_URL, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((result) => {

        let error = result.error;
        if (error.id === 0) {
          delete result.error;
          setListaPartesMaquina(result.partes);
          // console.log(result.partes);

        } else {
          setListaPartesMaquina('');
          // console.log(error.msg); //Si la maquina no tiene partes
          //mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("Error Api. Obtener los partes de una habitacion.");
      })

  };

  //Boolean para mostrar los historicos del parte que le indiquemos.
  const [isOpenCollapse, setIsOpenCollapse] = useState(null);
  const abrirHistorial = (clickedIndex) => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  //Parte seleccionado
  const [idParte, setIdParte] = useState('');
  //Boolean para mostrar o cultar el dialog que contendrá las imagenes del parte seleccionado.
  const [galeriaImagenes, setGaleriaImagenes] = React.useState(false);
  //Posicion en la lista de Imagenes
  const [posImage, setPosImage] = useState(0);

  //Boolean para mostrar o ocultar el dialog de los PDF.
  const [galeriaPdf, setGaleriaPdf] = React.useState(false);

  const verInforme = (id_parte) => {
    let url = "https://api.grupotel.com/workers/index.php?token=workers2&action=getPartePdf&id=";
    let final_url = url + id_parte;
    window.open(final_url, '_blank', 'noreferrer');
  }

  const abrirImagen = (fotos, id_parte) => {
    setListaFotosParte(fotos);
    setIdParte(id_parte);
    setGaleriaImagenes(true);
  }

  const ocultarGaleria = () => {
    setGaleriaImagenes(false);
    setPosImage(0);
  };
  const abrirPdf = (pdf, id_parte) => {
    //Guardamos el Listado de los Pdf
    setListaPdfParte(pdf);
    setIdParte(id_parte);
    setGaleriaPdf(true);
  }
  const ocultarPdf = () => {
    setGaleriaPdf(false);
  }
  const mostrarPdf = (pdf) => {
    //Abrimos la imagen en una nueva ventana.
    window.open("https://api.grupotel.com/workers.2/imagenes/parte/" + idParte + "/" + pdf);
  }
  //Funcion para pasar a la imagen anterior
  const prevImagen = () => {
    if (posImage - 1 === -1) {
      setPosImage(listaFotosParte.length - 1);
    } else {
      setPosImage(posImage - 1);
    }
  };

  //Funcion para pasar a la siguiente imagen
  const nextImagen = () => {
    if (posImage + 1 === listaFotosParte.length) {
      setPosImage(0);
    } else {
      setPosImage(posImage + 1);
    }
  };
  const abrirVentanaImagen = () => {
    //Obtenemos la información de la imagen que estamos mostrando. Utilizando el ID.
    var imagen = document.getElementById('imagenGaleria');
    //Abrimos la imagen en una nueva ventana.
    window.open(imagen.src);
  };

  const listaHistoricos = (fotos, parteId) => {
    let pdfHistorico = [];
    let fotoHistorico = [];

    if (fotos) {
      fotos.map((foto, j) => {
        let extension = foto.archivo.split(".");
        if (extension[extension.length - 1] === "pdf") {//Caso de que la extension es un pdf
          pdfHistorico.push(foto);
        } else {//Si no es un pdf entonces sera una imagen.
          fotoHistorico.push(foto);
        }
        return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
      })
      return <><div className=' divImagen'>
        {pdfHistorico.length >= 1 ? <IconButton className='boton ' onClick={(e) => { e.stopPropagation(); abrirPdf(pdfHistorico, parteId) }} >
          <PictureAsPdfIcon className='icon-button ' />
        </IconButton> : ""}
      </div>
        <div className=' divImagen'>
          {fotoHistorico.length >= 1 ? <IconButton className='boton ' onClick={(e) => { e.stopPropagation(); abrirImagen(fotoHistorico, parteId) }} >
            <ImageSearchIcon className='icon-button ' />
          </IconButton> : ""}
        </div>
      </>
    }
  }

  ///////////////////////////////////////////////
  //     Fin  SUB Apartado Partes Maquina      //
  ///////////////////////////////////////////////

  //Obtenemos los proveedores y montamos la estructura
  const getInfoProveedores = () => {
    const fd = new FormData();
    fd.append("f", "proveedores.getCifList");
    fd.append("token", localStorage.getItem('token'));
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          const proveedores = Object.entries(result.proveedores).map(([key, value]) => ({
            cif: value.cif,
            nombre_comercial: value.nombre_comercial,
            label: `${value.cif} - ${value.nombre_comercial}`
          }));

          // Ordenar por nombre_comercial y luego por cif
          proveedores.sort((a, b) => {
            if (a.nombre_comercial < b.nombre_comercial) return -1;
            if (a.nombre_comercial > b.nombre_comercial) return 1;
            if (a.cif < b.cif) return -1;
            if (a.cif > b.cif) return 1;
            return 0;
          });

          setProveedoresLista(proveedores);
        } else {
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener los proveedores");
      })
  };

  const getDepartamentos = () => {
    const fd = new FormData();
    fd.append("f", "usuarios.getDepartamentos");
    fd.append("token", localStorage.getItem('token'));
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          // console.log("Informacion de Departamentos");
          // console.log(result);
          const departamentos = Object.entries(result.departamentos).map(([id, nombre]) => ({
            id: id,
            nombre: nombre
          }));
          departamentos.unshift({ "id": " ", "nombre": " " })
          setDepartamentosLista(departamentos);
        } else {
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener la informacion de los Departamentos ");
      })
  };

  //Funcion para obtener todos los proveedores, departamentos, grupos maquinas
  const obtenerInfoSelect = () => {
    //Obtenemos los proveedores.
    getInfoProveedores();
    //Obtenemos los departamentos
    getDepartamentos();
    //Obtenemos los grupos
    gruposMaquinas();
  };

  const manejarKeyDown = (event) => {
    // console.log("Actualmente mover")
    // console.log(filaSeleccionada);
    // console.log(event);

    if (event.key === 'ArrowUp') {
      // Mover el foco hacia arriba
      event.preventDefault(); // Previene el comportamiento predeterminado del navegador
      if (filaSeleccionada > 0) {
        setFilaSeleccionada(filaSeleccionada - 1);
      }
    } else if (event.key === 'ArrowDown') {
      // Mover el foco hacia arriba
      event.preventDefault(); // Previene el comportamiento predeterminado del navegador
      if (filaSeleccionada > 0) {
        setFilaSeleccionada(filaSeleccionada + 1);
      }
    }
  };

  const clickInfoMaquina = (params, event) => {

    obtenerInfoSelect(); // Obtenermos la información de los select, grupos, proveedor, departamento
    listarTodosPartesMaquina(params.id);// Obtenemos los partes de las maquinas
    getInfoMaquina(params.id); //Obtenemos toda la información de caracteristicas de la maquina
    getFichaMaquina(params.id); // Obtener las caracteristiacas asociadas a la maquina
    getFotosMaquina(params.id); // Obtenemos las imagenes de la maquina
    getArchivosMaquina(params.id);

    setListaNuevasImagenMaquina([]);
    setNuevaListaPdfMaquina([]);


    setMostrarMaquina(true); // Mostramos el Dialog
  };
  const ocultarInfoMaquina = () => {
    setMostrarMaquina(false); //Ocultamos el Dialog
    setListaNuevasImagenMaquina([]); // Galeria de la maquina
    setListaPartesMaquina(''); //Lista de los partes de la maquina

  };
  const comprobarESCInfoMaquina = (event) => {
    if (event.keyCode === 27) {
      ocultarInfoMaquina();
    }
  };
  const limpiarInfoMaquina = () => {
    limpiarDatosRuta();
    //Vaciar los campos de infoMaquina
    setMostrarMaquina(false);
    //Vaciamos posible foto seleccionada
    setListaNuevasImagenMaquina([]);
    //Quiza sea necesario
    //setListaPartesMaquina('');

  };

  //Para controlar que acción hacer dependiendo del Tab activo
  const confirmTabsMaquina = () => {
    switch (tabValue) {
      case 0:
        // Actualizar los datos de la maquina
        guardarInfoMaquina();
        break;
      case 1:
        break;
      case 2:
        // Subir imagenes
        setImagenesMaquina();
        break;
      case 3:
        //Guardar los pdf
        setDocumentosMaquina();
        break;
      case 4:
        break;
    }
  };

  const guardarInfoMaquina = () => {
    //Convertimos las array a string y comprobamos si son iguales.
    if (JSON.stringify(copiaInfoMaquina) === JSON.stringify(datosInfoMaquina)) {
      if (rutaActual.idDepartamento != rutaActualCopia.idDepartamento && rutaActual.idDepartamento != '') {
        let idDepartamento = rutaActual.idDepartamento;
        let idsArray = idDepartamento.split(',');
        let ultimoId = idsArray[idsArray.length - 1];
        actualizarLugarMaquina(datosInfoMaquina.id, ultimoId, datosInfoMaquina.id_hotel);
        setMostrarMaquina(false);
        actualizar();
      } else {
        mostrarError("No hay cambios en la maquina");
      }
    } else {
      var str = '';

      Object.keys(datosInfoMaquina).forEach((nombre, i) => {
        //Solo guardaremos los cambios y el id de la maquina
        if (Object.values(datosInfoMaquina)[i] != Object.values(copiaInfoMaquina)[i] || nombre === "id") {
          if (nombre != "nombre_desc") {
            //Comprobamos que el campo no sea la Fecha 
            if (nombre != 'fecha_instalacion') {
              if (Object.values(datosInfoMaquina)[i] != '' && Object.values(datosInfoMaquina)[i] != undefined) {
                if (nombre === 'comentarios') {
                  const observacionesValue = JSON.stringify(Object.values(datosInfoMaquina)[i]);
                  str += nombre + "=" + observacionesValue + "|";
                } else {
                  const paramValue = Object.values(datosInfoMaquina)[i];
                  str += nombre + "=" + paramValue + "|";
                }
              }
            } else {
              let soloFecha = Object.values(datosInfoMaquina)[i].split(" ");
              if (Object.values(datosInfoMaquina)[i] != "" && soloFecha[0] != "0000-00-00") {
                str += nombre + "=" + Object.values(datosInfoMaquina)[i] + " 08:00:00|";
              } else {
                str += nombre + "=NULL|";
              }
            }
          }
        }
      });

      // console.log(str)
      const fd = new FormData();
      fd.append("f", "maquinas.setMaquina");
      fd.append("token", localStorage.getItem('token'));
      fd.append("params", str);
      const requestOptions = {
        method: 'POST',
        body: fd
      };

      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          //console.log(result)
          if (error.id === 0) {
            mostrarError("Maquina: " + datosInfoMaquina.id + " Actualizada", "success");
            // Comprobamos si hay que actualizar el lugar.
            if (rutaActual.idDepartamento != rutaActualCopia.idDepartamento && rutaActual.idDepartamento != '') {
              let idDepartamento = rutaActual.idDepartamento;
              let idsArray = idDepartamento.split(',');
              let ultimoId = idsArray[idsArray.length - 1];
              actualizarLugarMaquina(datosInfoMaquina.id, ultimoId, datosInfoMaquina.id_hotel);
            }
            setMostrarMaquina(false);
            actualizar();
          } else {
            mostrarError(error.msg);
          }
        })
        .catch(() => {
          mostrarError("Fallo actualizando la maquina " + datosInfoMaquina.id);
        })

      //setMostrarMaquina(false);
    }
  };

  const actualizarLugarMaquina = (id, id_padre, id_hotel) => {

    const fd = new FormData();
    fd.append("f", "maquinas.setLugarMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id);
    fd.append("id_padre", id_padre);
    fd.append("id_hotel", id_hotel);

    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        // console.log(result)
        if (error.id === 0) {
          mostrarError("El Lugar ha sido actualizado", "success");
        } else {
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("Fallo actualizando el lugar");
      })
  };

  /******************************************************************/
  //                   Fin  Dialog Info Maquina                     //
  /******************************************************************/

  /******************************************************************/
  //                   Inicio  Dialog crear Maquina                 //
  /******************************************************************/

  const mostrarNuevaMaquina = () => {
    SetHotelesLista(JSON.parse(hoteles));
    document.querySelector('.nuevoParte').classList.add('pintado');

    setNuevaMaquina(true);
  };
  const ocultarNuevaMaquina = () => {
    setNuevaMaquina(false);
  };
  const comprobarESCNuevaMaquina = (event) => {
    if (event.keyCode === 27) {
      ocultarNuevaMaquina();
    }
  };
  const limpiarNuevaMaquina = () => {
    document.querySelector('.nuevoParte').classList.remove('pintado');
    setNuevaMaquina(false);
  };

  const crearMaquina = () => {
    console.log('Peticion crear Maquina');
    document.querySelector('.nuevoParte').classList.remove('pintado');
    setNuevaMaquina(false);
  };

  /******************************************************************/
  //                Fin Dialog crear Maquinas                       //
  /******************************************************************/

  /******************************************************************/
  //                    Inicio  Dialog Filtros                      //
  /******************************************************************/

  const estadoMaquina = (event) => {
    if (btnActivo === false) {
      document.querySelector('.abierto').classList.add('pintado');
      setBtnActivo(true);
    } else {
      document.querySelector('.abierto').classList.remove('pintado');
      setBtnActivo(false);
    }
    actualizar();
  };

  //Obtenemos los grupos y departamentos.
  const gruposMaquinas = () => {
    let grupos = [];
    grupos.push({ value: -1, label: '' });
    const fd = new FormData();
    fd.append("f", "maquinas.getGruposMaquinas");
    fd.append("token", localStorage.getItem('token'));
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        delete result.error;
        //Recorremos el array de grupos para obtener su id y nombre.
        Object.keys(result.maquinas_grupos).map((id, i) => {
          var nombreGrupo = Object.values(result.maquinas_grupos)[i].nombre;
          var idGrupo = Object.values(result.maquinas_grupos)[i].id;
          grupos.push({ value: idGrupo, label: nombreGrupo });
          return null;
        })
        // console.log(grupos)

        setGruposLista(grupos.sort((a, b) => (a.label > b.label) ? 1 : -1));
        //.sort((a, b) => (a.label > b.label) ? 1 : -1)
      })
      .catch(() => {
        mostrarError("No se ha podido obtener los grupos");
      })
  }


  const mostrarFiltros = () => {
    gruposMaquinas();
    setActivoFiltro(true);
    SetActualizo(!actualizo);
    setEstadoFiltros(true);
  };
  const comprobarESC = (event) => {
    if (event.keyCode === 27) {
      ocultarFiltros();
    }
  };
  const ocultarFiltros = () => {
    setEstadoFiltros(false);
    actualizar();
  };
  const eliminarFiltros = () => {
    setDatosFiltroMaquina({
      id: '',
      nombre: '',
      n_serie: '',
      grupo: -1, // Puede llegar
      activo: false,
      actualizado: 0,
      noactualizado: 0
    });
    setActivoFiltro(false);
    setEstadoFiltros(false);
    actualizar();
  };

  const actualizarFiltros = (event) => {
    if (event.target.name == "grupo") {
      setDatosFiltroMaquina({
        ...datosFiltroMaquina,
        [event.target.name]: parseInt(event.target.value)
      });
    } else {
      setDatosFiltroMaquina({
        ...datosFiltroMaquina,
        [event.target.name]: event.target.value
      });
    }

  };

  const guardarDatos = () => {
    const datos = datosFiltroMaquina;
    var str = '';
    Object.keys(datos).forEach((nombre, i) => {
      if (Object.values(datos)[i] !== '' && Object.values(datos)[i] !== undefined) {
        str += nombre + "=" + Object.values(datos)[i] + '|'
      }
    });

    setEstadoFiltros(false);
    actualizar();
    setActivoFiltro(true);
  };

  const comprobarEnter = (e) => {
    if (e.key === 'Enter') {
      guardarDatos();
    }
  }
  /******************************************************************/
  //                    Fin  Dialog Filtros                         //
  /******************************************************************/
  /**************************************************************/
  //  		Inicio Departamentos
  /**************************************************************/
  //Array que contiene el resultado de getLocationsList. Obtiene un listado de todos los departamentos.
  const [todosDepartamentos] = useState([]);
  //Array temporal para comprobar si hay departamentos con el mismo idPadre.
  const [departamentosListado] = useState([]);
  //Array utilizada para mostrar los departamentos, en Crear Parte.
  const [departamentosNuevoParte] = useState([]);


  //Conjunto de listas donde guardamos un string de los id de Departamentos a los que damos click. Y lista de los nombres de departamentos.
  const [rutaActual, setRutaActual] = useState({ idDepartamento: '', nombre: '' });
  const [rutaActualCopia, setRutaActualCopia] = useState({ idDepartamento: '', nombre: '' });

  //Variable que utilizamos como contador. Para saber en que posición estamos de los departamentos.
  const [posicionActual, setPosicionActual] = useState(0);
  //Boolean utilizado para habilitar o deshabilitar el boton de atras en Nuevo Parte. Hace referencia a los departamentos.
  const [activarAtras, setActivarAtras] = useState(true);

  const [errorObservacionesPisos, setErrorObservacionesPisos] = React.useState(false);
  const [errorDepartamentosPisos, setErrorDepartamentosPisos] = React.useState(false);

  //Funcion onChange del Dialog. Busca todos los departamentos de un hotel.
  const buscarDepartamentos = (id_hotel) => {
    if (id_hotel !== 0) {

      fd.append("f", "partes.getLocationsList");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id_hotel", id_hotel);
      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          if (error.id === 0) {
            //console.log(result)
            let departamentosLista = result;
            //Funcion para limpiar las listas.
            vaciarDepartamentos();
            //Guardamos todos los departamentos.
            todosDepartamentos.push(departamentosLista);
            //LLamamos a la funcion para mostrar los departamentos padre con idPadre 0
            obtenerDepartamento(0);
          } else {
            mostrarError(error.msg);
            console.log(error.msg);
          }
        })
        .catch(() => {
          setMensajeError("No se ha podido obtener departamentos.");
          setContadorError(contadorError + 1);
          setTipoError("error");
          setEstadoAlert(true);
          const timer2 = setTimeout(() => {
            setEstadoAlert(false);
          }, 1000);
          return () => clearTimeout(timer2);
        })
    }
  };

  const buscarDepartamentosMaquina = (id_hotel) => {
    if (id_hotel !== 0) {

      fd.append("f", "partes.getLocationsList");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id_hotel", id_hotel);
      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          if (error.id === 0) {
            //Funcion para limpiar las listas.
            vaciarDepartamentosMaquina();
            //console.log(result)
            let departamentosLista = result;
            //Guardamos todos los departamentos.
            todosDepartamentos.push(departamentosLista);
            //LLamamos a la funcion para mostrar los departamentos padre con idPadre 0
            obtenerDepartamento(0);
          } else {
            mostrarError(error.msg);
            console.log(error.msg);
          }
        })
        .catch(() => {
          setMensajeError("No se ha podido obtener departamentos.");
          setContadorError(contadorError + 1);
          setTipoError("error");
          setEstadoAlert(true);
          const timer2 = setTimeout(() => {
            setEstadoAlert(false);
          }, 1000);
          return () => clearTimeout(timer2);
        })
    }
  };


  //Obtiene el id del Padre y mapea todos los departamentos con ese mismo idPadre.
  const obtenerDepartamento = (idPadre, textoPadre, back) => {

    let departamentosLista = todosDepartamentos[0];
    departamentosListado.length = 0;

    //Comprobar y obtener los que tengan el idPadre
    Object.keys(departamentosLista).map((index, i) => {
      if (index !== 'error') {
        var departamento = Object.values(departamentosLista)[i];
        // if (departamento.id_padre === idPadre) { //No sirve hacer directamente el ===  
        if (parseInt(departamento.id_padre) === parseInt(idPadre)) { // Para realizar la comprobación de igualdad estricta. Tenemos que asegurarnos de que sean iguales.
          departamentosListado.push({ value: departamento.id_lugar, label: departamento.nombre, padre: departamento.id_padre });
        }
      }
      return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
    })

    if (departamentosListado.length !== 0) {
      // departamentosListado.sort((a, b) => (a.label > b.label) ? 1 : -1);
      departamentosListado.sort((a, b) => {
        // Manejar el caso en el que no se proporciona un índice
        if (a.indice === undefined && b.indice === undefined) {
          // Si ambos elementos no tienen índice, ordenar alfabéticamente por nombre
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        } else if (a.indice !== undefined && b.indice !== undefined) {
          // Si ambos elementos tienen índice definido, ordenar por índice
          return a.indice - b.indice;
        } else {
          // Si uno tiene índice definido y el otro no, el que tenga índice definido debe ir primero
          return a.indice !== undefined ? -1 : 1;
        }
      });

      departamentosNuevoParte.length = 0;
      departamentosNuevoParte.push(...departamentosListado);
      //Si es Departamento Padre inicializamos la posicion.
      if (idPadre === 0) {
        setPosicionActual(1);
      } else {
        //Si es un sub departamento guardamos la posicion.
        setPosicionActual(posicionActual + 1);
      }
    }
    //En el caso de no ser un back .
    if (back === undefined) {
      //Caso de un Sub departamento.
      //Si tenemos id del Departamento y la posicionActual no es 0
      if (rutaActual.idDepartamento !== '' && posicionActual >= 1) {
        var listaRutas = rutaActual.idDepartamento.split(",");
        setActivarAtras(false);
        //Funcion para cambiar el contenido del ultimo id y nombre.
        const actualizarUltimo = () => {
          let rutaId = rutaActual.idDepartamento.split(",");
          rutaId[listaRutas.length - 1] = idPadre;
          let rutaDepartamento = rutaActual.nombre.split("-");
          rutaDepartamento[listaRutas.length - 1] = textoPadre;
          setRutaActual({ idDepartamento: rutaId.toString(), nombre: rutaDepartamento.join("-") });
        }
        //Si el ultimo id es igual a idPadre. Y estamos en la misma posicion reemplazamos el contenido.
        if (listaRutas[listaRutas.length - 1] === idPadre && posicionActual === listaRutas.length) {
          actualizarUltimo();
        } else {
          //Caso de que en la misma posicion se ha introducido un id distinto.
          //Se compara que estemos en la misma posicion,que el tamaño del array. Y si la ultima posicion tiene un id diferente lo reemplazamos.
          if (posicionActual === listaRutas.length && listaRutas[listaRutas.length - 1] !== idPadre) {
            actualizarUltimo();
          } else {
            //Si la posicion es la misma, pero el id es distinto añdimos le nuevo valor.
            let rutaId = rutaActual.idDepartamento + "," + idPadre;
            let rutaDepartamento = rutaActual.nombre + "-" + textoPadre;
            setRutaActual({ idDepartamento: rutaId, nombre: rutaDepartamento });
          }
        }
      } else {
        //Caso inicial de idPadre 0
        if (textoPadre !== undefined) {
          setActivarAtras(false);
          setRutaActual({ idDepartamento: idPadre, nombre: textoPadre });
        }
      }
    }
  };

  //Obtenemos el id del padre que puede venir del div o el span.
  const obtenerSubDepartamento = (event) => {
    // Si nos llega un onclick del texto. span obtenemos el contenido
    let id = event.target.parentElement.id;
    let texto = event.target.innerText;
    //Si no era un span el id estara vacio. En este caso obtenemos el id del div
    if (id === '') {
      id = event.target.id;
    }
    //Mostramos la flecha para volver atras si es un sub departamento.
    document.querySelector('.botonAtras').classList.remove('ocultar');

    if (id !== undefined) {
      obtenerDepartamento(id, texto);
      setErrorDepartamentosPisos(false);
    }
  };

  const vaciarDepartamentos = () => {
    todosDepartamentos.length = 0;
    rutaActual.idDepartamento = "";
    rutaActual.nombre = 'Nuevo parte';
    setPosicionActual(0);
    setRutaActual({ idDepartamento: "", nombre: "" });
    departamentosNuevoParte.length = 0;
  };
  const vaciarDepartamentosMaquina = () => {
    todosDepartamentos.length = 0;
    rutaActual.idDepartamento = "";
    rutaActual.nombre = 'Seleccione lugar: ';
    setPosicionActual(0);
    setRutaActual({ idDepartamento: "", nombre: "" });
    departamentosNuevoParte.length = 0;
  };

  //Al realizar un cambio en un Textfield. Se guardara la información en un constructor.
  const cambioNuevoParte = (event) => {
    if (event.target.name === "observaciones" && event.target.value.trim() !== "") {
      setErrorObservacionesPisos(false);
    } else if (event.target.name === "observaciones") {
      setErrorObservacionesPisos(true);
    }
    setDatosNuevoParte({ ...datosNuevoParte, [event.target.name]: event.target.value });
  }

  //Funcion de flecha para volver al departamento anterior.
  const backDepartamento = () => {
    //Obtenemos el penultimo id y texto
    let rutaId = rutaActual.idDepartamento.split(",");

    let rutaDepartamento = rutaActual.nombre.split("-");
    rutaId.pop();
    rutaDepartamento.pop();
    if (rutaId[rutaId.length - 1] !== undefined) {
      //listaRutas[listaRutas.length - 1]
      obtenerDepartamento(rutaId[rutaId.length - 1], rutaDepartamento[rutaDepartamento.length - 1], 1);
      setPosicionActual(rutaId.length + 1);
      setRutaActual({ idDepartamento: rutaId.toString(), nombre: rutaDepartamento.join("-") });
    } else {
      rutaActual.idDepartamento = "";
      rutaActual.nombre = 'Nuevo parte';
      setRutaActual({ idDepartamento: "", nombre: "" });
      obtenerDepartamento(0);
      setActivarAtras(true);
      setErrorDepartamentosPisos(true);

    }
  }
  /******************************************************************/
  //                  Fin Departamentos                             //
  /******************************************************************/

  /******************************************************************/
  //                  Inicio  Dialog CrearParte                     //
  /******************************************************************/
  // Listas para previsualizar Imagenes o Pdf
  const [listaImagenesNuevoParte, setListaImagenesNuevoParte] = useState([]);
  const [listaPDFNuevoParte, setListaPDFNuevoParte] = useState([]);

  const [textoBusqueda, setTextoBusqueda] = useState('');
  const [departamentosFiltrados, setDepartamentosFiltrados] = useState([]);

  const [datosNuevoParte, setDatosNuevoParte] = useState({
    tipo: '',
    observaciones: ''
  });

  const parteInicial = [ //parteInicial
    { valor: '', nombre: '', },
    { valor: 'normal', nombre: 'Normal', },
    { valor: 'No conformidad', nombre: 'No conformidad', },
    { valor: 'objeto perdido', nombre: 'Objeto Perdido', },
    { valor: 'amenity', nombre: 'Amenity', } //,
    // { valor: 'bloqueo', nombre: 'Bloqueo', },
  ];
  const [tipoPartes, setTipoPartes] = useState(parteInicial);

  // Función para obtener los tipos de partes.
  const obtenerTiposParte = () => {
    fd.append("f", "partes.getTipoParte");
    fd.append("token", localStorage.getItem('token'));

    fetch('https://api.grupotel.com/workers.2/', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error.id === 0) {
          // console.log(result);
          // console.log(result.tipo);
          // Crear un array temporal y añadir el valor por defecto al principio
          const tipoConDefecto = [{ valor: '', nombre: '' }, ...result.tipo];

          // Actualizar el estado con el array modificado
          setTipoPartes(tipoConDefecto);

        } else {
          setTipoPartes(parteInicial);
          mostrarError(result.error.msg);
          console.log(result.error.msg)
        }
      })
      .catch(() => {
        mostrarError("No se ha podido actualizar los Tipos de Parte");
        console.log("No se ha podido actualizar los Tipos de Parte");
      })
  }

  // Quitar la imagen seleccionada
  const borrarImagenNuevoParte = (id) => {
    setListaImagenesNuevoParte(listaImagenesNuevoParte.filter((foto) => foto.id !== id));
  };
  // Quitar el pdf seleccionado
  const borrarPDFNuevoParte = (id) => {
    setListaPDFNuevoParte(listaPDFNuevoParte.filter((pdf) => pdf.id !== id));
  };

  //Función para controlar las imagenes y pdf añadidos
  const selecImageCrearParte = (event) => {
    const files = event.target.files;

    // Verificar si se seleccionó algún archivo
    if (files.length === 0) {
      // Vaciar las listas de imágenes y PDFs, si no se seleccionó ningún archivo
      setListaImagenesNuevoParte([]);
      setListaPDFNuevoParte([]);
      event.target.value = null;  // Limpiar el input
      return;
    }
    //console.log(files)

    let nuevasImagenes = [];
    let nuevosPDFs = [];

    // Filtrar los archivos de imagen y PDF
    const imagenes = Array.from(files).filter(file => file.type.startsWith('image/'));
    const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');

    //Variable para saber el peso de los archivos.
    let pesoArchivos = 0;

    // Montar el array de imágenes
    imagenes.forEach((file, index) => {
      pesoArchivos += file.size;
      nuevasImagenes.push({
        id: index,
        ruta: URL.createObjectURL(file),
        nombre: file.name,
        archivo: file
      });
    });

    // Montar el array de PDFs
    pdfs.forEach((file, index) => {
      let nombrePdf = "";
      if (file.name.length > 29) {
        nombrePdf = file.name.substring(0, 29) + "...";
      } else {
        nombrePdf = file.name;
      }
      pesoArchivos += file.size;
      nuevosPDFs.push({
        id: index,
        ruta: URL.createObjectURL(file),
        nombre: nombrePdf,
        archivo: file
      });
    });

    //Comprobación de si se supera el maximo de 32
    if (pesoArchivos >= 32000000) {
      console.log("El peso de los archivos es mayor a 32MB: " + pesoArchivos);
      mostrarError("Los archivos superan los 32 MB");
      setListaPDFNuevoParte([]);
      setListaImagenesNuevoParte([]);
      event.target.value = null;  // Limpiar el input
      return;
    }

    setListaImagenesNuevoParte(nuevasImagenes);
    setListaPDFNuevoParte(nuevosPDFs);
  };
  const handleSearchText = (e) => {
    const searchText = e.target.value.toLowerCase();
    setTextoBusqueda(searchText);
    const departamentosEncontrados = departamentosNuevoParte.filter(departamento =>
      departamento.label.toLowerCase().includes(searchText)
    );
    setDepartamentosFiltrados(departamentosEncontrados);
  }

  const estructuraNuevoParte = <div>{
    <>
      <div className='nuevoParteHab'>
        <div className='contenedorNuevoParteHab'>
          <h3 className='sinMargen'>{rutaActual.nombre ? rutaActual.nombre : 'Seleccione el Departamento'}</h3>
          <TextField
            className='obsNuevoParteHab'
            label="Observaciones"
            multiline
            name="observaciones"
            value={datosNuevoParte.observaciones ? datosNuevoParte.observaciones : ''}
            onChange={cambioNuevoParte}
            error={errorObservacionesPisos}
          />
          <div className='divImgPdf'>
            <div >
              <div >
                <label htmlFor="upload-files" className='nuevoParteBotonAdjuntar'>
                  <input
                    className='ocultar'
                    accept=".pdf, .jpg, .jpeg, .png"
                    id="upload-files"
                    type="file"
                    onChange={selecImageCrearParte}
                    multiple
                  />
                  <Button component='span' className='colorGrupo' variant="contained">Adjuntar archivo</Button>
                </label>
              </div>
            </div>
            <div className='anchoMitad'>
              <div className='bloqueTipoParte'//className='divPdfTipoParte'
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Tipo parte"
                  name="tipo"
                  size="small"
                  value={datosNuevoParte.tipo ? datosNuevoParte.tipo : ''}
                  onChange={cambioNuevoParte}
                  select
                >
                  {tipoPartes
                    .sort((a, b) => a.orden - b.orden) // Ordenar por el campo 'orden' de forma ascendente
                    .map((tipoParte) => (
                      <MenuItem key={`nuevoTipo-${tipoParte.valor}`} value={tipoParte.valor}>
                        <Tooltip
                          title={tipoParte.descripcion && tipoParte.descripcion.trim() ? tipoParte.descripcion : ''} // Mostrar solo si hay descripción
                          placement="right"
                        >
                          <span>{tipoParte.nombre}</span> {/* Envolver el texto del menú, no el componente entero */}
                        </Tooltip>
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            </div>
          </div>
          <div>
            <div className='divPdf'>
              {listaPDFNuevoParte.length > 0 ?
                <div>
                  {listaPDFNuevoParte.map((pdf, j) => {
                    return (
                      <div className='' key={pdf.id}>
                        <Button className='nuevoParteNombrePdf' variant="text" onClick={() => ventanaImagen(pdf.ruta)} >{pdf.nombre ? pdf.nombre : ''}</Button>
                        <IconButton className='iconX' onClick={() => borrarPDFNuevoParte(pdf.id)}>x</IconButton>
                      </div>
                    );
                  })
                  }
                </div>
                : ''
              }
            </div>

            <div className='containerDiv'>
              {listaImagenesNuevoParte.length > 0 ?
                <div className='divImgMaquinasNuevas'>
                  {listaImagenesNuevoParte.map((foto, j) => {
                    return (
                      <div className='divCentrarImagen' key={foto.id}>
                        <IconButton className='iconoBorrarImg' onClick={() => borrarImagenNuevoParte(foto.id)}>
                          <CancelIcon className=' ' />
                        </IconButton>
                        <img className="nuevoPartePreviewImagen" src={foto.ruta} onClick={() => ventanaImagen(foto.ruta)} />
                      </div>
                    );
                  })
                  }
                </div>
                : ''
              }
            </div>
          </div>
        </div>
        <Box sx={{ width: '40%', height: '370px' }} className='departamento'  >

          <List
            className='botonLista'
          >
            <ListItem className={'divisor'}>
              <div className='itemBoton'>
                <TextField InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FilterAltIcon color='#00a2dc' />
                    </InputAdornment>
                  ),
                }} value={textoBusqueda} onChange={(ev) => { handleSearchText(ev) }} id="search" fullWidth></TextField>
              </div>
            </ListItem>
            <ListItem divider className='divisor botonAtras'>
              <ListItemButton className='itemBoton'
                disabled={activarAtras}
                onClick={backDepartamento}
              >
                <ListItemText className='center'>
                  <ArrowBackIcon className='icon-button ' />
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).map((departamento, i) =>
              <>
                <ListItem divider className={(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).length - 1 === i ? 'divisor ultimoDiv' : 'divisor'} >
                  <ListItemButton className='itemBoton' value={departamento.label} id={departamento.value} onClick={(ev) => { obtenerSubDepartamento(ev) }}>
                    <ListItemText primary={departamento.label} id={departamento.value} className='center' />
                  </ListItemButton>
                </ListItem>
              </>
            )
            }
          </List>
        </Box>
      </div>
    </>
  }</div>

  const mostrarCrearParte = () => {
    // Obtenemos los datos necesarios
    buscarDepartamentos(datosInfoMaquina.id_hotel);
    obtenerTiposParte();
    setEstadoNuevoParte(true);
  }

  const comprobarESCParte = (event) => {
    if (event.keyCode === 27) {
      ocultarCrearParte();
    }
  };

  const ocultarCrearParte = () => {
    setEstadoNuevoParte(false);
    // Actualizamos la lista de Partes.
    listarTodosPartesMaquina(datosInfoMaquina.id);// Obtenemos los partes de las maquinas

    // Limpiar los datos necesarios.
    setDatosNuevoParte({
      tipo: '',
      observaciones: ''
    });
    setListaPDFNuevoParte([]);
    setListaImagenesNuevoParte([]);
    setTextoBusqueda('');
    setDepartamentosFiltrados([]);

  };
  const [creandoParte, setCreandoParte] = useState(false);

  const confirmarCrearParte = () => {
    setCreandoParte(true);

    // Petición para crear el parte
    if (rutaActual.idDepartamento !== '') {
      if (datosNuevoParte.observaciones) {
        var str = '';
        var id = localStorage.getItem('id');

        str += "id_usuario=" + id + "|id_creador=" + id + "|";
        str += "id_hotel=" + datosInfoMaquina.id_hotel + "|hab=0|";
        str += "rama=" + rutaActual.idDepartamento + "|titulo=" + rutaActual.nombre + "|";
        str += "id_maquina=" + datosInfoMaquina.id + "|";

        Object.keys(datosNuevoParte).forEach((nombre, i) => {
          if (nombre === 'tipo' && Object.values(datosNuevoParte)[i] === '') {
            str += nombre + "=normal|"; // En caso de no recibir un tipo
          } else if (nombre === 'observaciones') {
            let observacionesValue = Object.values(datosNuevoParte)[i].replace(/\|/g, ' ');
            str += nombre + "=" + observacionesValue + "|";
          } else {
            if (Object.values(datosNuevoParte)[i] !== '' && Object.values(datosNuevoParte)[i] !== undefined) {
              str += nombre + "=" + Object.values(datosNuevoParte)[i] + '|';
            }
          }
        });

        fd.append("f", "partes.set");
        fd.append("token", localStorage.getItem('token'));
        fd.append("params", str);

        //Recorremos el listado de Imagenes añadidas al parte.
        Object.values(listaImagenesNuevoParte).forEach((imagen, i) => {
          fd.append("imagen" + i, imagen.archivo);
        });

        //Recorremos los archivos pdf
        listaPDFNuevoParte.forEach((pdf, i) => {
          fd.append("pdf" + i, pdf.archivo);
        });

        fetch(API_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error.id === 0) {
              ocultarCrearParte();
              setCreandoParte(false);
            } else {
              mostrarError(result.error.msg);
              console.log(result.error.msg);
              setCreandoParte(false);
            }
          })

      } else {
        mostrarError("Escriba una observacion.");
        console.log("Escriba una observacion.");
        setErrorObservacionesPisos(true);
        setErrorDepartamentosPisos(false);
        setCreandoParte(false);
      }
    } else {
      if (datosNuevoParte.observaciones) {
        mostrarError("Seleccione un Departamento.");
        console.log("Seleccione un Departamento.");
        setErrorDepartamentosPisos(true);
        setCreandoParte(false);
      } else {
        mostrarError("Seleccione un Departamento. Y escriba la observacion.");
        console.log("Seleccione un Departamento. Y escriba la observacion.");
        //Añadir elemento error en obs.
        setErrorObservacionesPisos(true);
        setErrorDepartamentosPisos(true);
        setCreandoParte(false);
      }
    }

  }

  /******************************************************************/
  //                  Fin  Dialog CrearParte                        //
  /******************************************************************/

  /******************************************************************/
  //                  Editar Parte en Maquinas                      //
  /******************************************************************/
  // Variables para la Edición de Parte
  const [parte, setParte] = React.useState([]);

  // Mostrar el Dialog de Editar Parte
  const [modoLectura, setModoLectura] = React.useState(false);

  //Variable para mostrar la ruta de Lugares/Departamentos del Parte. O contendra un "Seleccionar Lugar/Departamento".
  const [departamentoInicial, setDepartamentoInicial] = useState("");
  //Variable para controlar si se esta mostrando el Listado de Departamentos en Editar Parte. 0 no se muestra. 1 se muestra.  
  const [estadoListadoDepartamentos, setEstadoListadoDepartamentos] = useState(0);

  //Guardar en personName el string ,  de Usuario Asignado.
  const [personName, setPersonName] = useState([]);

  //Listado de los Usuarios con el mismo id Departamento.
  const [usuariosLista, setUsuariosLista] = useState([]);
  //Lista de los proveedores
  const [terceros, setTercerosLista] = useState([]);
  const [mostrarTerceros, setMostrarTerceros] = useState(false);
  //Lista del Tipo de Terceros.
  const [tipoTerceros, setTipoTercerosLista] = useState([]);

  const estadoInicialParte = [{
    id: '',
    fecha_creacion: '',
    creador: '',
    id_usuario: '',
    usuario_asignado: '',
    fecha_aplazado: '',
    id_hotel: '',
    hotel: '',
    bloque: '',
    planta: '',
    hab: '',
    operacion: '',
    prioridad: '',
    tipo: '',
    departamento: '',
    id_departamento: '',
    titulo: '',
    observaciones: '',
  }]
  const [datosEditarParte, setDatosEditarParte] = useState(estadoInicialParte);

  //Funcion para obtener los campos del Parte seleccionado.
  const editarTemporal = (idUsuario, datos, historico) => {//Recibimos el IdUsuario conectado. Y el tipo de operacion a realizar en el parte.
    let operacion = "";

    vaciarDepartamentos();
    datosEditarParte.length = 0;
    //Obtenemos la ultima fecha de Aplazado si existe     11-02-2023 08:00:00
    let fecha = "";
    let asignado = "";
    if (historico != undefined && historico != []) {
      fecha = historico[0].fecha_aplazado;
      asignado = historico[0].usuario_asignado;
    }

    //Creamos un array para separar la fecha de la hora   ['11-02-2023', '08:00:00']
    let soloFecha = fecha.split(" ");
    let fechaAplazada;
    //Obtenemos unicamente la fecha y le damos la vuelta para poder imprimir en el Textfield tipo Date. 2023-02-11
    fechaAplazada = soloFecha[0].split("-").reverse().join("-");
    //Guardamos en el temporal la ruta de Departamentos/Lugares.
    setDepartamentoInicial(datos.titulo);
    //Obtenemos todos los datos del parte actual.Para mostrar en el Dialog Editar Parte.
    setDatosEditarParte({
      id: datos.id_parte,
      fecha_creacion: datos.fecha_creacion,
      creador: datos.creador,
      id_usuario: idUsuario,
      usuario_asignado: asignado,
      fecha_aplazado: fechaAplazada,
      id_hotel: datos.id_hotel,
      hotel: datos.hotel,
      bloque: datos.bloque,
      planta: datos.planta,
      hab: datos.habitacion,
      operacion: operacion,
      prioridad: datos.ultima_prioridad,
      tipo: datos.tipo,
      departamento: datos.departamento_creador,
      id_departamento: datos.id_departamento,
      titulo: datos.titulo,
      observaciones: ''
    })
  }

  //Funcion para Ocultar el Dialog de edicion del Parte.
  const finEdicion = () => {
    listarTodosPartesMaquina(datosInfoMaquina.id);
    //Dejamos el datosEditarParte vacio.
    setDatosEditarParte([estadoInicial]);
    setMostrarTerceros(false);
    setListaImagenEditarParte([]);
    setListaPDFEditarParte([]);
    setModoLectura(false);
    document.querySelector('.editar').classList.remove('pintado');
    setEstadoListadoDepartamentos(0);

    //actualizarListaPartes(); // Solicitar de nuevo los partes de la habitación.
  };

  //Funcion para detectar que la tecla pulsada es ESC y ocultar el Dialog de EditarParte.
  const comprobarESCEditarParte = (event) => {
    if (event.keyCode == 27) {
      finEdicion();
    }
  }

  const obtenerParte = (id_parte) => {
    obtenerDatosSelect();
    setPersonName([]);
    setDatosEditarParte([estadoInicialParte]);

    // Obtenemos toda la información del Parte.
    fd.append("f", "partes.get");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id", id_parte);
    // Para poder ver la información de partes de Otros departamentos.
    fd.append("salidas", 1); // En el caso de ser el creador del parte, pero no pertenecer al departamento Destino

    const requestOptions = {
      method: 'POST',
      body: fd
    };
    let datosParte = [];
    let historicosParte = [];
    let fotosParte = [];
    let temp = [];

    fetch(API_URL, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        let error = result.error;
        if (error.id === 0) {
          // console.log(" Resultado: ")
          // console.log(result)
          datosParte = result.parte.datos;
          historicosParte = result.parte.historico;
          fotosParte = result.parte.fotos;
          temp.push(datosParte);
          temp.push(historicosParte);
          temp.push(fotosParte);

          setParte(temp);
          let idUsuario = localStorage.getItem('id');
          editarTemporal(idUsuario, datosParte, historicosParte);

          obtenerUsuariosDepartamento(datosParte.id_hotel);

          obtenerListaTerceros();
          setModoLectura(true);
          document.querySelector('.editar').classList.add('pintado');
        } else {
          mostrarError(error.msg);
          console.log(error.msg);
        }
      })
      .catch(() => {
        mostrarError("Error Api. Obteniendo los datos del Parte");
        console.log("Error Api. Obteniendo los datos del Parte");
      })
  }

  const [controlError, setControlError] = useState(false);

  //Variable donde se guardara la imagen seleccionada en Editar Parte.
  const [listaImagenEditarParte, setListaImagenEditarParte] = useState([]);
  const [listaPDFEditarParte, setListaPDFEditarParte] = useState([]);

  //Cada vez que se realiza un cambio en el Dialog de Editar Parte guardamos la nueva información. onChange
  const editarParte = (event) => {

    setDatosEditarParte({
      ...datosEditarParte,
      [event.target.name]: event.target.value
    })
    if (event.target.name == "operacion" && (event.target.value == "terceros" || event.target.value == "cerrado")) {//Si es operacion. Comprobar si el Estado del parte es Terceros. Si es asi mostrar los select de Proveedores.
      setMostrarTerceros(true);
    } else if (event.target.name == "operacion") {//Si se ha realizado otro tipo de cambio en operacion ocultamos. Los proveedores
      if (event.target.value != "") {
        setControlError(false);
      } else {
        setControlError(true);
      }
      setMostrarTerceros(false);
    }

    //Petición de Cristian, si se aplaza modificar el estado del parte a Nuevo.
    if (event.target.name == "fecha_aplazado" && datosEditarParte.operacion != "nuevo") {
      setDatosEditarParte({
        ...datosEditarParte,
        [event.target.name]: event.target.value,
        ['operacion']: 'nuevo'
      })
    }
  }

  //Funcion para editar la información del Parte.
  const confirmarEdicion = () => {
    if (datosEditarParte.operacion != "" && datosEditarParte.operacion != " ") {//Comprobamos que hayan seleccionado una Operacion/Estado del Parte. "Nuevo/Asignado,etc..."
      setControlError(false);
      modificarParte();
    } else {
      setControlError(true);
      mostrarError("Seleccione un Estado al Parte.");
      console.log("Seleccione un Estado al Parte.");
    }
  };

  //Función para realizar la modificación del parte con la información obtenida del formulario Editar Parte
  const modificarParte = () => {
    var str = '';

    //Comprobar los Usuarios asignados del Chip.
    //Si no tiene datos tenemos que introducir el usuario actual.
    if (personName.length >= 1) {//Comprobamos si hay asignado 1 usuario.
      //Creamos un array de los id de usuarios asignados en el chip.
      let usuarios = personName.map(function (usuario) {
        return usuario.value;
      });

      //Creamos un string con los id de usuarios seleccionados en el chip. Si solo hay uno obtenemos su id.
      let asignados = "";

      //? para verificar si historico[0] existe y tiene la propiedad usuario_asignado
      let id_asignado = ""//Si el ultimo historico tenia a una persona asignada lo obtenemos.
      if (parte.historicosParte != undefined && parte.historicosParte != []) {
        id_asignado = parte.historicosParte[0]?.id_asignado;
      }

      //Comprobamos si el primer usuario asignado sigue estando en el array del chip.
      if (usuarios.includes(id_asignado)) {
        //Obtenemos el indice del usuario asignado.
        const index = usuarios.findIndex(persona => persona === id_asignado);

        //Si no esta en la primera posición.
        if (index !== 0) {//Ponemos al usuario asignado el primero de la lista.
          usuarios.splice(index, 1); // Elimina el elemento encontrado
          usuarios.unshift(datosEditarParte.id_usuario); // Añade el elemento al inicio del array
        }
        asignados = usuarios.join(",");
        str += "id_usuario=" + usuarios[0] + "|";
        str += "id_usuarios=" + asignados + "|";
      } else {
        asignados = usuarios.join(",");

        str += "id_usuario=" + usuarios[0] + "|";
        str += "id_usuarios=" + asignados + "|";
      }
    } else {//Añadimos el usuario actual a los usuarios asignados.
      str += "id_usuarios=" + localStorage.getItem('id') + "|";
      str += "id_usuario=" + localStorage.getItem('id') + "|";
    }
    let operacion = '';
    //Creamos y establecemos el formato de los parametros que vamos a enviar.
    Object.keys(datosEditarParte).forEach((nombre, i) => {
      let paramValue = Object.values(datosEditarParte)[i];
      if (nombre != 'id_usuario') {
        //Comprobamos que el campo no sea la Fecha para aplazar el parte.
        if (nombre != 'fecha_aplazado') {
          if (Object.values(datosEditarParte)[i] != '' && Object.values(datosEditarParte)[i] != undefined) {
            if (nombre === 'observaciones') {
              let observacionesValue = Object.values(datosEditarParte)[i].replace(/\|/g, ' ');
              str += nombre + "=" + observacionesValue + "|";
            } else if (nombre === 'operacion') {
              operacion = paramValue
              str += nombre + "=" + paramValue + "|";
            } else {
              str += nombre + "=" + paramValue + "|";
            }
          }
        } else {
          //En el caso de ser Fecha Aplazada creamos un array para obtener la fecha
          let soloFecha = Object.values(datosEditarParte)[i].split(" ");
          //Comprobamos que la fecha no este vacia o venga con el valor 0000
          if (Object.values(datosEditarParte)[i] != "" && soloFecha[0] != "0000-00-00") {
            //Si la fecha viene con datos, le añadimos la hora 08:00
            str += nombre + "=" + Object.values(datosEditarParte)[i] + " 08:00:00|";
          } else {
            //Si la fecha vuelve vacia, le indicamos que su valor sera NULL
            str += nombre + "=NULL|";
          }
        }
      }
    });

    //En el caso de modificar la ruta Lugar/Departamento 
    if (rutaActual.idDepartamento !== 0 && rutaActual.idDepartamento !== null && rutaActual.idDepartamento !== "") {
      modificarTituloParte();
    }

    const fd = new FormData();
    const requestOptions = {
      method: 'POST',
      body: fd
    };
    fd.append("f", "partes.set");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_hotel", datosEditarParte.id_hotel);
    fd.append("params", str); //Parametros Obligatorios: 'id_usuario', 'operacion', 'prioridad'. Tambien el id del Parte.
    //Recorremos el listado de Imagenes para añadirlas
    Object.values(listaImagenEditarParte).forEach((imagen, i) => {
      fd.append("imagen" + i, imagen.archivo);
    });

    //Recorremos los archivos pdf
    listaPDFEditarParte.forEach((pdf, i) => {
      fd.append("pdf" + i, pdf.archivo);
    });

    // let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        if (result.error.id == 0) {
          mostrarError("Se ha actualizado el parte.", "success");
          listarTodosPartesMaquina(datosInfoMaquina.id);
        } else {
          mostrarError(result.error.msg);
          console.log(result.error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha actualizado el parte." + parte);
        console.log("No se ha actualizado el parte." + parte);
      })
    finEdicion();
  }

  //Funcion para realizar el cambio de Titulo del Parte seleccionado.
  const modificarTituloParte = () => {
    const fd = new FormData();
    const requestOptions = {
      method: 'POST',
      body: fd
    };
    fd.append("f", "partes.setNewLugar");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id", datosEditarParte.id);//Id del Parte
    fd.append("rama", rutaActual.idDepartamento);//Ruta del nuevo Lugar del Parte   ej:4,107,109
    // let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error.id !== 0) { //En caso de error.
          mostrarError(result.error.msg);
          console.log(result.error.msg);
        }
        //console.log("Se ha modificado el Titulo del Parte.");
      })
      .catch(() => {
        mostrarError("Error Api. No se ha podido modificar el Titulo del Parte");
        console.log("Error Api. No se ha podido modificar el Titulo del Parte");
      })
  }

  //Funcion para inicializar y mostrar los departamentos o Ocultarlos.
  const mostrarDepartamentos = () => {
    if (estadoListadoDepartamentos == 0) {
      setActivarAtras(true);
      //Buscamos todos los departamentos relacionados con el Hotel.
      buscarDepartamentos(datosEditarParte.id_hotel);
      setDepartamentoInicial("Seleccione un Lugar/Departamento");
      //Mostrar el boton para volver atras en un departamento
      document.querySelector('.botonAtras').classList.remove('ocultar');
      //Mostar el listado de Departamentos.
      document.querySelector('.departamentoEditar').classList.remove('ocultar');
      //Guardamos el 1 para indicar que se esta mostrando el Listado de Departamentos en Editar Parte.
      setEstadoListadoDepartamentos(1);
    } else {
      //Guardamos en el temporal la ruta de Departamentos/Lugares.
      setDepartamentoInicial(datosEditarParte.titulo);
      //Mostrar el boton para volver atras en un departamento
      document.querySelector('.botonAtras').classList.add('ocultar');
      //Mostar el listado de Departamentos.
      document.querySelector('.departamentoEditar').classList.add('ocultar');
      //Guardamos el 0 para indicar que se esta ocultando el Listado de Departamentos en Editar Parte.
      setEstadoListadoDepartamentos(0);
    }
  };
  //Funcion para inicializar y mostrar los departamentos o Ocultarlos.
  const mostrarDepartamentosMaquina = () => {
    if (estadoListadoDepartamentos === 0) {
      setActivarAtras(true);

      //Buscamos todos los departamentos relacionados con el Hotel.
      buscarDepartamentosMaquina(datosInfoMaquina.id_hotel);
      // setDepartamentoInicial("Seleccione un Lugar/Departamento");
      //Mostrar el boton para volver atras en un departamento
      document.querySelector('.botonAtras').classList.remove('ocultar');
      //Mostar el listado de Departamentos.
      document.querySelector('.departamentoEditar').classList.remove('ocultar');
      //Guardamos el 1 para indicar que se esta mostrando el Listado de Departamentos en Editar Parte.
      setEstadoListadoDepartamentos(1);
    } else {
      setRutaActual({ ...rutaActualCopia });
      // console.log('Copia')
      // console.log(rutaActualCopia);
      // console.log('Actual')
      // console.log(rutaActual)

      //Guardamos en el temporal la ruta de Departamentos/Lugares.
      //setDepartamentoInicial(datosInfoMaquina.id_hotel);
      //Mostrar el boton para volver atras en un departamento
      document.querySelector('.botonAtras').classList.add('ocultar');
      //Mostar el listado de Departamentos.
      document.querySelector('.departamentoEditar').classList.add('ocultar');
      //Guardamos el 0 para indicar que se esta ocultando el Listado de Departamentos en Editar Parte.
      setEstadoListadoDepartamentos(0);
    }
  };

  const obtenerUsuariosDepartamento = (hotel_id) => {
    let temp = [];
    fd.append("f", "usuarios.getList");
    fd.append("token", localStorage.getItem('token'));
    let str = "";
    str = "id_hotel=" + hotel_id + "|id_departamento=" + localStorage.getItem('id_departamento') + '|';
    fd.append("params", str);

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        delete result.error;

        //Recorremos el array de Usuarios para obtener sus id de Usuario y nombres.
        Object.keys(result.usuarios).map((id, i) => {
          var nombreUsuario = Object.values(result.usuarios)[i].nombre;
          //temp.push([id,nombreUsuario]);
          temp.push({ value: id, label: nombreUsuario });
        })
        //Guardamos el listado de Usuarios para el Select de Editar Parte.
        setUsuariosLista(temp);
      })
      .catch(() => {
        mostrarError("No se ha podido listar los Usuarios.");
        console.log("No se ha podido listar los Usuarios.");
      })
  }

  //Obtener el listado de Terceros. Los Proveedores.
  const obtenerListaTerceros = () => {
    fd.append("f", "proveedores.getList");
    fd.append("token", localStorage.getItem('token'));
    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id == 0) {
          let listaTerceros = [];
          let tipoTerceros = [];
          listaTerceros.push({ value: "", label: "" });
          Object.values(result.proveedores).map((index, i) => {
            listaTerceros.push({ value: index['cif'], label: index['nombre_comercial'] });
          })
          setTercerosLista(listaTerceros);

          tipoTerceros.push({ value: "", label: "" });
          Object.values(result.tipo_terceros).map((tipo, i) => {
            tipoTerceros.push({ value: Object.keys(result.tipo_terceros)[i], label: tipo });
          })

          setTipoTercerosLista(tipoTerceros);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener los Terceros.");
        console.log("No se ha podido obtener los Terceros.")
      })
  }

  const cambioProveedor = (event) => {
    //Si se ha realizado un cambio en el autocomplete de Proveedores. 
    //Primero comprobamos si podemos obtener un valor del index.
    if (event.target.dataset.optionIndex) {
      //console.log(event.target.innerText);//Obtenemos el Nombre del Proveedor
      //Obtenemos el valor del cif seleccionado usando la posicion del index.
      let cif = "";
      Object.values(terceros).map((index, i) => {
        if (index.label == event.target.innerText) {
          cif = index.value;
        }
      })
      setDatosEditarParte({
        ...datosEditarParte,
        "cif_proveedor": cif, "nombre_comercial": event.target.innerText
      })
    } else if (event.target.dataset.testid == "CloseIcon") {
      //En caso contrario se habra realizado click en la X para borrar el contenido de Proveedores
      setDatosEditarParte({
        ...datosEditarParte,
        "cif_proveedor": "", "nombre_comercial": ""
      })
    }
  };

  //Listas utilizadas
  const prioridadInicial = [
    { valor: 'baja', nombre: 'Baja', },
    { valor: 'media', nombre: 'Media', },
    { valor: 'alta', nombre: 'Alta', },
  ];
  const [tipoPrioridad, setTipoPrioridad] = useState(prioridadInicial);

  const estadosInicial = [
    { valor: ' ', nombre: ' ' },
    { valor: 'nuevo', nombre: 'Nuevo' },
    { valor: 'asignado', nombre: 'Asignado' },
    { valor: 'en ejecucion', nombre: 'En ejecución' },
    { valor: 'terceros', nombre: 'Terceros' },
    { valor: 'cerrado', nombre: 'Cerrado' },
    { valor: 'anulado', nombre: 'Anulado' },
  ];
  const [tipoEstadosDefault, setTipoEstadosDefault] = useState(estadosInicial);

  const tipoEstadosAnulado = [
    { valor: ' ', nombre: ' ' },
    { valor: 'anulado', nombre: 'Anulado' },
  ];

  const obtenerDatosSelect = () => {
    fd.append("f", "partes.getDatosSelect");
    fd.append("token", localStorage.getItem('token'));

    fetch('https://api.grupotel.com/workers.2/', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error.id === 0) {
          // console.log(result);
          const tipoConDefecto = [{ valor: '', nombre: '' }, ...result.tipo];
          setTipoPartes(tipoConDefecto);

          const estadoConDefecto = [{ valor: '', nombre: '' }, ...result.operacion];
          setTipoEstadosDefault(estadoConDefecto);

          setTipoPrioridad(result.prioridad);

        } else {
          setTipoPartes(parteInicial);
          setTipoEstadosDefault(estadosInicial);
          setTipoPrioridad(prioridadInicial);

          mostrarError(result.error.msg);
          console.log(result.error.msg)
        }
      })
      .catch(() => {
        mostrarError("No se ha podido actualizar los Tipos de Parte");
        console.log("No se ha podido actualizar los Tipos de Parte");
      })
  }

  const obtenerSelectEstados = () => {
    let listaEstados = tipoEstadosDefault;
    let departamento = localStorage.getItem('id_departamento');
    if (departamento != datosEditarParte.id_departamento) {
      listaEstados = tipoEstadosAnulado;
    }

    return (
      <TextField
        className='proveedoresTextfield'
        variant="outlined"
        margin="normal"
        label="Estado"
        name="operacion"
        id="tipoOperacion"
        error={controlError}
        onChange={editarParte}
        size="small"
        value={datosEditarParte.operacion ? datosEditarParte.operacion : ''}
        select
      >
        {listaEstados.map((tipoEstado) => (
          <MenuItem key={`editEstadoDatosP-${tipoEstado.valor}`} value={tipoEstado.valor}>
            {tipoEstado.nombre}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  //Funcion para el select chip multiple
  const chipMultipleAsignados = (idChip, lectura) => {

    const modificarAsignados = (id, nombre) => {
      // Comprobar si ya existe el usuario.
      const index = personName.findIndex(persona => persona.value === id);
      if (index !== -1) {
        // El id ya está. Procedemos a borrarlo.
        //Primero creamos una copia de el array actual.
        const copiaPersonName = JSON.parse(JSON.stringify(personName));
        copiaPersonName.splice(index, 1);//Quitamos el indice de la array para borrar el usuario.
        setPersonName(copiaPersonName);
      } else {
        //El id no esta en nuestra array de Usuarios.
        const nuevoAsignado = { value: id, label: nombre };
        setPersonName([...personName, nuevoAsignado]);
      }
    }

    return (
      <FormControl fullWidth id={"select-chip-asignados-control-datosParte" + idChip}>
        <InputLabel id={"select-chip-asignados-label-datosParte" + idChip} >Asignados</InputLabel>

        <Select
          labelId={"select-chip-asignados-label-datosParte" + idChip}
          //id={"select-chip-asignados" + idChip}
          multiple
          //label="Asignados"
          //value={personName}
          value={(personName != [] && personName.length >= 1) ? personName : ['']}
          size="small"
          inputProps={lectura == true ? { readOnly: true, } : { readOnly: false, }}
          input={<OutlinedInput id={"select-multiple-chip-datosParte" + idChip} label="Asignados" />}
          renderValue={() => (
            <Box
              className="chipNombres"
              size="small"
            >
              {personName != [] && personName.length >= 1 ?
                personName.map((persona) => (
                  <Chip key={`asignado-datosParte-${persona.value}`} label={persona.label} className={'grupotel-blue'} />
                ))
                : <Chip key={`asignado-datosParte-0`} label={' '} />}
            </Box>
          )}
        >
          {usuariosLista != "" ?
            usuariosLista.map((usuario) => (
              <MenuItem key={`mostrarUsuarios-${usuario.value}`} value={usuario.value} onClick={() => modificarAsignados(usuario.value, usuario.label)} className={personName.find(persona => persona.value === usuario.value) ? 'grupotel-blue' : ''} >
                {usuario.label}
              </MenuItem>
            ))
            : null
          }
        </Select>
      </FormControl>
    );
  };
  //Funcion para obtener toda la información de una habitacion.
  const buscarHabitacion = () => {
    // console.log(datosEditarParte.hab);
    if (datosEditarParte.hab !== "") {
      obtenerDatosSelect();
      comprobarHabitacion();
      listarTodosPartesMaquina(datosInfoMaquina.id);
    }

  };
  /* Comprobar Habitacion*/
  //Funcion para detectar si se pulsa el Enter en el Textfield de Habitacion.
  const comprobarEnterHab = (e) => {
    if (e.key == 'Enter') {
      comprobarHabitacion();
    }
  };

  const comprobarHabitacion = () => {
    const datos = datosEditarParte;
    if (datos.id_hotel != '' && datos.hab != '' && datos.hab != undefined && datos.id_hotel != undefined) {
      fd.append("f", "habitaciones.get");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id_hab", datos.hab);
      fd.append("id_hotel", datos.id_hotel);
      // let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result) {
            let infoPartes = result;
            setDatosEditarParte({ ...datosEditarParte, "bloque": infoPartes.bloque, "planta": infoPartes.planta })
          }
        })//En caso de error mostramos nuestro mensaje
        .catch(() => {
          mostrarError("No existe la habitación: " + datos.hab);
          console.log("No existe la habitación: " + datos.hab);
        })
    } else if (datos.hab == '' && datos.hab != undefined) {
      mostrarError("No ha seleccionado la habitación. ");
      console.log("No ha seleccionado la habitación. ");
    }
  };


  /******************************************************************/
  //               Fin Editar Parte en Maquinas                     //
  /******************************************************************/
  /**************************************************************/
  //  		Apartado imagenes Editar Parte
  /**************************************************************/
  //Función para obtener y actualizar la imagen del parte. Afecta a el Dialog de EditarParte.
  const selecImageEditarParte = (event) => {
    const files = event.target.files;

    // Verificar si se seleccionó algún archivo
    if (files.length === 0) {
      // Vaciar las listas de imágenes y PDFs, si no se seleccionó ningún archivo
      setListaImagenEditarParte([]);
      setListaPDFEditarParte([]);
      event.target.value = null;  // Limpiar el input
      return;
    }

    let nuevasImagenes = [];
    let nuevosPDFs = [];

    // Filtrar los archivos de imagen y PDF
    const imagenes = Array.from(files).filter(file => file.type.startsWith('image/'));
    const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');

    //Variable para saber el peso de los archivos.
    let pesoArchivos = 0;

    // Montar el array de imágenes
    imagenes.forEach((file, index) => {
      pesoArchivos += file.size;
      nuevasImagenes.push({
        id: index,
        ruta: URL.createObjectURL(file),
        nombre: file.name,
        archivo: file
      });
    });

    // Montar el array de PDFs
    pdfs.forEach((file, index) => {
      let nombrePdf = "";
      if (file.name.length > 29) {
        nombrePdf = file.name.substring(0, 29) + "...";
      } else {
        nombrePdf = file.name;
      }
      pesoArchivos += file.size;
      nuevosPDFs.push({
        id: index,
        ruta: URL.createObjectURL(file),
        nombre: nombrePdf,
        archivo: file
      });
    });

    //Comprobación de si se supera el maximo de 32
    if (pesoArchivos >= 32000000) {
      mostrarError("Los archivos superan los 32 MB");
      console.log("Los archivos superan los 32 MB");
      setListaPDFEditarParte([]);
      setListaImagenEditarParte([]);
      event.target.value = null;  // Limpiar el input
      return;
    }

    setListaImagenEditarParte(nuevasImagenes);
    setListaPDFEditarParte(nuevosPDFs);
  };

  // Abrir imagen o pdf  en ventanda nueva
  const ventanaImagenEditarParte = (ruta) => {
    const rutaMostrar = ruta.includes("min_") ? ruta.replace("min_", "") : ruta;
    window.open(rutaMostrar);
  };

  const borrarImagenEditarParte = (id) => {
    setListaImagenEditarParte(listaImagenEditarParte.filter((foto) => foto.id !== id));
  }
  const borrarPdfEditarParte = (id) => {
    setListaPDFEditarParte(listaPDFEditarParte.filter((pdf) => pdf.id !== id));
  }

  useEffect(() => {
    setTablaMaquinaFicha(<DataGrid
      className='grid '
      rowHeight={48}//63
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
      }
      // onRowClick={(params, event) => {
      //   if (!event.target.closest('button')) {
      //     console.log(params.id);
      //   }
      // }}

      getRowId={(listaMaquinaFicha) => listaMaquinaFicha.id}
      rows={listaMaquinaFicha}
      columns={columnasMaquinaFicha}
      maxColumns={7}
      pageSize={8}
      rowsPerPageOptions={[8]}
      initialState={{
        sorting: {
          sortModel: [{ field: 'nombre', sort: 'asc' }],
        },
      }}
      localeText={{
        noRowsLabel: "No se ha encontrado datos.",
        noResultsOverlayLabel: "No se ha encontrado ningún resultado",
      }}

    />)
  }, [listaMaquinaFicha]);

  useEffect(() => {
    setTablaGruposCaracteristica(<DataGrid
      className='grid dialogListaCaracteristicas'
      rowHeight={52}//63
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
      }
      // onRowClick={(params, event) => {
      //   if (!event.target.closest('button')) {
      //     console.log(params);
      //   }
      // }}

      getRowId={(listaGruposCaracteristica) => listaGruposCaracteristica.id}
      rows={listaGruposCaracteristica}
      columns={columnasGruposCaracteristicas}
      maxColumns={8}
      pageSize={12}
      rowsPerPageOptions={[12]}
      initialState={{
        sorting: {
          sortModel: [{ field: 'nombre', sort: 'asc' }],
        },
      }}
      localeText={{
        noRowsLabel: "No se ha encontrado datos.",
        noResultsOverlayLabel: "No se ha encontrado ningún resultado",
      }}

    />)
  }, [listaGruposCaracteristica]);


  useEffect(() => {

    let listaHoteles = JSON.parse(hoteles);
    SetHotelesLista(listaHoteles);

    //Si tenemos un hotel guardado en el localStorage utilizando Ajustes.
    let idHotel = localStorage.getItem('idHotelSeleccionado');
    if (estadoInicial) {
      obtenerInfoSelect();
      if (idHotel) {
        SetHotelTrabajo(idHotel);
        setEstadoInicial(false);
      } else {
        SetHotelTrabajo(listaHoteles[0].value);
        setEstadoInicial(false);
      }
    }
    getMaquinas();

  }, [hotelTrabajo, actualizo]);
  return (
    <>
      <div className='container'>
        <div className='content' style={{ "height": "70px" }}>
          <div className='article'>
            <div className='header'>
              <div className='containerAcciones contenidoIzquierda'>

                <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Actualizar"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className="boton"
                      onClick={actualizar}
                    >
                      <RotateLeftIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>

                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Crear Maquina"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className="boton nuevoParte"
                      onClick={mostrarNuevaMaquina}
                    >
                      <AddCircleOutlineIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Filtrar"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className={activoFiltro === true ? "boton filtro pintado" : "boton filtro"}
                      onClick={mostrarFiltros}
                    >
                      <FilterAltIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Maquinas Activas"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className="boton abierto"
                      onClick={estadoMaquina}
                    >
                      <AssignmentLateOutlinedIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className='aside' >
            <div className='header'>
              <TextField
                className='fifty'
                variant="outlined"
                margin="normal"
                label="Hotel"
                name="id_hotel"
                id="id_hotel"
                size="small"
                value={hotelTrabajo ? hotelTrabajo : ""}
                onChange={cambiaHotelTrabajo}
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { maxHeight: { sm: 36 * 8 + 8 } }
                    }
                  }
                }}
              >
                {hotelesLista.map((hotel) => (
                  <MenuItem key={hotel.value} value={hotel.value} >
                    {hotel.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

        </div>
        <div className='grid-container' onKeyDown={manejarKeyDown}>
          <DataGrid
            className='grid'
            rowHeight={56}//63
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
            }
            rowClassName={(params) =>
              params.rowIndex === filaSeleccionada ? 'fila-seleccionada' : ''
            }
            onRowClick={(params, event) => {
              if (!event.target.closest('button')) {
                clickInfoMaquina(params, event);
              }
            }}
            getRowId={(listaMaquinas) => listaMaquinas.id}
            rows={listaMaquinas}
            columns={columnas}
            maxColumns={8}
            pageSize={12}
            rowsPerPageOptions={[12]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'Nombre', sort: 'asc' }],
              },
            }}
            localeText={{
              noRowsLabel: "No se ha encontrado datos.",
              noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}
          // disableColumnMenu
          />
        </div>

        <CargaSpinner contadorSpinner={contadorSpinner} />
        <Snackbar open={openAlert} autoHideDuration={3000} onClose={ocultarAlerta}>
          <Alert onClose={ocultarAlerta} severity={severidad} sx={{ width: '100%' }}>
            {mensaje}
          </Alert>
        </Snackbar>

        <Dialog fullScreen={fullScreen}
          open={nuevaMaquina}
          onClose={comprobarESCNuevaMaquina}
          className='dialogCrearParte'
          aria-labelledby="responsive-dialog-title" id="dialogoNewMaquina">
          <DialogTitle>{'Nueva Maquina'}</DialogTitle>
          <DialogContent className='container-filtros'>
            <form className='nuevoParteForm'>
              <div className='nuevoParteBloque'>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={limpiarNuevaMaquina}>Cancelar</Button>
              <Button className="colorGrupo" onClick={crearMaquina}>Crear</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={mostrarMaquina}
          onClose={comprobarESCInfoMaquina}
          className='dialogCrearParte'
          aria-labelledby="responsive-dialog-title" id="dialogoInfoMaquina">
          <DialogTitle>{rutaActual.nombre ? rutaActual.nombre + '-' : 'Seleccione Lugar: '}   {datosInfoMaquina.nombre}</DialogTitle>
          <DialogContent className='container-filtros alturaDialog'>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Información" />
              <Tab label="Caracteristicas" />
              <Tab label="Imágenes" />
              <Tab label="Documentos" />
              <Tab label="Partes" />
            </Tabs>

            {tabValue === 0 && (
              <form className='containerInfoMaquina'>
                <div className='divFiltros '>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="ID"
                        name="infoID"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.id ? datosInfoMaquina.id : '') : ''}
                        inputProps={{ className: 'bold', readOnly: true }}
                        InputLabelProps={{ className: 'bold' }}
                      />
                      <TextField
                        label="Hotel"
                        name="infoHotel"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.nombre_desc ? datosInfoMaquina.nombre_desc : '') : ''}
                        inputProps={{ readOnly: true }}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Modelo"
                        name="infoModelo"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.modelo ? datosInfoMaquina.modelo : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, modelo: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="N_Serie"
                        name="infoN_Serie"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.n_serie ? datosInfoMaquina.n_serie : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, n_serie: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Nombre"
                        name="infoNombre"
                        className='observaciones'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.nombre ? datosInfoMaquina.nombre : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, nombre: e.target.value }))}
                        inputProps={{ className: 'bold' }}
                        InputLabelProps={{ className: 'bold', shrink: true }}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Fabricante"
                        name="infoFabricante"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.fabricante ? datosInfoMaquina.fabricante : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, fabricante: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Anyo_Fabricacion"
                        name="infoAnyo_Fabricacion"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        type="number"
                        value={datosInfoMaquina ? (datosInfoMaquina.anyo_fabricacion ? datosInfoMaquina.anyo_fabricacion : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, anyo_fabricacion: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Grupo"
                        name="infoGrupo"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.id_grupo ? datosInfoMaquina.id_grupo : -1) : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, id_grupo: e.target.value }))}
                        select
                      >
                        {gruposLista.map((grupo) => (
                          <MenuItem key={grupo.value} value={grupo.value}>
                            {grupo.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Autocomplete
                        name="infoMantenedor"
                        className="poperAutoCompletar titulo"
                        size="small"
                        options={proveedoresLista}
                        getOptionLabel={(option) => option.label}
                        value={proveedoresLista.find((option) => option.cif === datosInfoMaquina.cif_mantenedor) || null}
                        onChange={(event, value) => {
                          setDatosInfoMaquina(prevState => ({ ...prevState, cif_mantenedor: value ? value.cif : '' }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Mantenedor" />}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <Autocomplete
                        name="infoInstalador"
                        className="poperAutoCompletar titulo"
                        size="small"
                        options={proveedoresLista}
                        getOptionLabel={(option) => option.label}
                        value={proveedoresLista.find((option) => option.cif === datosInfoMaquina.cif_instalador) || null}
                        onChange={(event, value) => {
                          setDatosInfoMaquina(prevState => ({ ...prevState, cif_instalador: value ? value.cif : '' }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Instalador" />}
                      />

                      <TextField
                        name="infoDepartamento"
                        label="Departamento"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.id_departamento ? datosInfoMaquina.id_departamento : ' ') : ' '}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, id_departamento: e.target.value }))}
                        select
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {departamentosLista.map((departamento) => (
                          <MenuItem key={departamento.id} value={departamento.id}>
                            {departamento.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>

                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Alto"
                        name="infoAlto"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        type="number"
                        value={datosInfoMaquina ? (datosInfoMaquina.alto ? datosInfoMaquina.alto : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, alto: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Ancho"
                        className='titulo'
                        variant="outlined"
                        name="infoAncho"
                        margin="normal"
                        size="small"
                        type="number"
                        value={datosInfoMaquina ? (datosInfoMaquina.ancho ? datosInfoMaquina.ancho : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, ancho: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Largo"
                        className='titulo'
                        variant="outlined"
                        name="infoLargo"
                        margin="normal"
                        size="small"
                        type="number"
                        value={datosInfoMaquina ? (datosInfoMaquina.largo ? datosInfoMaquina.largo : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, largo: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Fecha_Instalacion"
                        type="date"
                        format={'DD/MM/YYYY'}
                        className='titulo'
                        name="infoF_instalacion"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.fecha_instalacion ? datosInfoMaquina.fecha_instalacion : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, fecha_instalacion: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Comentarios"
                        className='observaciones '
                        size="small"
                        multiline
                        rows={4}
                        id='infoTextoComentario'
                        name="infoComentario"
                        value={datosInfoMaquina ? (datosInfoMaquina.comentarios ? datosInfoMaquina.comentarios : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, comentarios: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <div className='divAplazado titulo'>
                        <Button
                          variant="outlined"
                          margin="normal"
                          className='colorGrupo '
                          onClick={mostrarDepartamentosMaquina}>
                          Cambiar Lugar Maquina
                        </Button>
                      </div>
                      <div className='divAplazado titulo'>
                        <FormControlLabel
                          control={<Checkbox checked={datosInfoMaquina.activo === '1' || datosInfoMaquina.activo === true ? true : false} color="secondary" name="infoActivo"
                            onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, activo: e.target.checked ? '1' : '0' }))}
                          />}
                          label="Maquina Activa"
                        />
                      </div>
                    </div>
                    <div className='divFiltrosContainer' >
                      <div className='divFiltrosTextField'>
                        <div className=' bloqueEdicion departamentoEditar ocultar anchoDepartamentos'>
                          <Box sx={{ width: '100%' }} className='departamento listaDepartamentos'>

                            <List
                              className='botonLista'
                              sx={{ width: '100% !important' }}
                            >
                              <ListItem className={'divisor'}>
                                <div className='itemBoton'>
                                  <TextField InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <FilterAltIcon color='#00a2dc' />
                                      </InputAdornment>
                                    ),
                                  }} value={textoBusqueda} onChange={(ev) => { handleSearchText(ev) }} id="search" fullWidth></TextField>
                                </div>
                              </ListItem>
                              <ListItem divider className='divisor botonAtras'>
                                <ListItemButton className='itemBoton'
                                  disabled={activarAtras}
                                  onClick={backDepartamento}
                                >
                                  <ListItemText className='center'>
                                    <ArrowBackIcon className='icon-button ' />
                                  </ListItemText>
                                </ListItemButton>
                              </ListItem>
                              {(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).map((departamento, i) =>
                                <>
                                  <ListItem divider className={(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).length - 1 === i ? 'divisor ultimoDiv' : 'divisor'} >
                                    <ListItemButton className='itemBoton' value={departamento.label} id={departamento.value} onClick={(ev) => { obtenerSubDepartamento(ev) }}>
                                      <ListItemText primary={departamento.label} id={departamento.value} className='center' />
                                    </ListItemButton>
                                  </ListItem>
                                </>
                              )
                              }
                            </List>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {tabValue === 1 && (
              <div className='containerInfoMaquina'>
                <div className=' containerAcciones sinMargen'>
                  <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                    <HtmlTooltip
                      placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">{"Crear Caracteristica"}</Typography>
                        </React.Fragment>
                      }
                    >
                      <Button
                        className="boton"
                        onClick={() => mostrarCrearFichaMaquina()}
                      >
                        <AddCircleOutlineIcon className='icon-button ' />
                      </Button>
                    </HtmlTooltip>
                    <HtmlTooltip
                      placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">{"Actualizar"}</Typography>
                        </React.Fragment>
                      }
                    >
                      <Button
                        className="boton"
                        onClick={() => getFichaMaquina(datosInfoMaquina.id, 1)}
                      >
                        <RotateLeftIcon className='icon-button ' />
                      </Button>
                    </HtmlTooltip>
                    {datosInfoMaquina.id_grupo != '' && datosInfoMaquina.id_grupo != null ?
                      <HtmlTooltip
                        placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{"Importar las caracteristicas del Grupo"}</Typography>
                          </React.Fragment>
                        }
                      >
                        <Button
                          className="boton"
                          onClick={() => mostrarCaracteristicasGrupo()}
                        >
                          <CloudDownloadOutlinedIcon className='icon-button ' />
                        </Button>
                      </HtmlTooltip>
                      : ''}
                  </ButtonGroup>
                </div>
                {tablaMaquinaFicha}
              </div>
            )}
            {tabValue === 2 && (
              <div className='containerInfoMaquina'>
                <div >
                  <div >
                    <div className=' sinMargen'>
                      <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">

                        <label htmlFor="upload-photo" >
                          <input
                            className='ocultar'
                            //accept="image/*"
                            multiple
                            accept=".jpg, .jpeg, .png"
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            onChange={selecImage}
                          />
                          <HtmlTooltip
                            placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                            title={
                              <React.Fragment>
                                <Typography color="inherit">{"Añadir Imagen"}</Typography>
                              </React.Fragment>
                            }
                          >
                            <IconButton component="span" className='boton buscador bordeCircular'>
                              <AddAPhotoIcon className='icon-button ' />
                            </IconButton>
                          </HtmlTooltip>
                        </label>
                      </ButtonGroup>
                    </div>
                  </div>
                  {listaNuevasImagenMaquina.length > 0 ?
                    <div className='divImgMaquinasNuevas'>
                      {listaNuevasImagenMaquina.map((foto, j) => {
                        return (
                          <div className='divCentrarImagen' key={foto.id}>
                            <IconButton className='iconoBorrarImg' onClick={() => borrarImagen(foto.id)}>
                              <CancelIcon className=' ' />
                            </IconButton>
                            <img className="img-preview-Editar" src={foto.ruta} onClick={() => ventanaImagen(foto.ruta)} />
                          </div>
                        );
                      })
                      }
                    </div>
                    : ''
                  }
                </div>
                <hr />
                <div className='divImgMaquinasGuardadas'>
                  {/*  Imagenes Guardadas de la maquina */}
                  {listaImagenesMaquina.length > 0 ?
                    listaImagenesMaquina.map((foto, j) => {
                      return <div className='divCentrarImagen'>
                        <IconButton className='iconoBorrarImg' onClick={() => confirmDelImgMaquina(foto.id_foto)}>
                          <CancelIcon className=' ' />
                        </IconButton>
                        <img key={foto.id} className="img-preview-Historico" src={Api_Maquinas_Img + datosInfoMaquina.id + "/min_" + foto.archivo}
                          style={{ "width": "auto", "max-width": "90vh" }}
                          onError={({ currentTarget }) => {
                            currentTarget.src = Api_Maquinas_Antiguo_Img + datosInfoMaquina.id + "/min_" + foto.archivo;
                            currentTarget.onerror = null; // prevents looping
                          }}
                          onClick={(event) => ventanaImagen(event.target.src)}
                        />
                      </div>
                    })
                    :
                    <div>
                      <p>No hay imagenes Guardadas</p>
                    </div>
                  }

                </div>
              </div>
            )}

            {tabValue === 3 && (
              <div className='containerInfoMaquina'>
                <div >
                  <div >
                    <div className=' sinMargen'>
                      <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">

                        <label htmlFor="upload-pdf" >
                          <input
                            className='ocultar'
                            //accept="image/*"
                            multiple
                            accept=".pdf"
                            id="upload-pdf"
                            name="upload-pdf"
                            type="file"
                            onChange={selecPdf}
                          />
                          <HtmlTooltip
                            placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                            title={
                              <React.Fragment>
                                <Typography color="inherit">{"Añadir PDF"}</Typography>
                              </React.Fragment>
                            }
                          >
                            <IconButton component="span" className="boton buscador bordeCircular" >
                              <PictureAsPdfIcon className="icon-button" />
                            </IconButton>
                          </HtmlTooltip>
                        </label>
                      </ButtonGroup>
                    </div>
                  </div>
                  {nuevaListaPdfMaquina.length > 0 ?
                    <div className='divImgMaquinasNuevas'>
                      {nuevaListaPdfMaquina.map((pdf, j) => {
                        return (
                          <div className='' key={pdf.id}>
                            <Button className='nuevoParteNombrePdf' variant="text" onClick={() => ventanaImagen(pdf.ruta)} >{pdf.nombre ? pdf.nombre : ''}</Button>
                            <IconButton className='iconX' onClick={() => borrarNuevoPDFMaquina(pdf.id)}>x</IconButton>
                          </div>
                        );
                      })
                      }
                    </div>
                    : ''
                  }
                </div>

                <hr />

                <div className='divImgMaquinasGuardadas'>

                  <div className='divDocumentos divTodosPdf'>
                    <div className='divBloquePdf'> { /* Documentos subidos a la maquina */}
                      <h3 className='centrar'>Documentos Maquina</h3>
                      <div>
                        {listaPdfMaquina.length > 0 ?
                          <div className='divListaPdf'>
                            {listaPdfMaquina.map((pdf, j) => {
                              return (
                                <div className='' key={pdf.id}>
                                  <Button className='botonPdf botonListaPdf' variant="text" onClick={() => ventanaPdf("https://api.grupotel.com/workers.2/documentos/maquinas/" + datosInfoMaquina.id_hotel + "/" + datosInfoMaquina.id + "/" + pdf.nombre)} >{pdf.nombre ? pdf.nombre : ''}</Button>
                                  <IconButton className='iconX' onClick={() => confirmarBorrarPdfMaquina(pdf.id)}>x</IconButton>
                                </div>
                              );
                            })
                            }
                          </div>
                          :
                          <div className='divBloquePdf '>
                            <p>No tiene Documentos asociados.</p>
                          </div>
                        }
                      </div>
                    </div>
                    {listaArchivosMaquina.length > 0 ?
                      <div className='divBloquePdf divRequisitos'>
                        <h3 className='centrar'>Documentos Requisitos Legales</h3>
                        <div className='containerPdf divListaPdf' >
                          {listaArchivosMaquina.map((pdf, j) => {
                            return (
                              <div className='' key={pdf.id_archivo}>
                                <Button className='botonPdf botonListaPdf' variant="text" onClick={() => ventanaPdf("https://api.grupotel.com/workers.2/documentos/" + pdf.id_hotel + "/" + pdf.tipo + "/" + datosInfoMaquina.id + "/" + pdf.archivo)} >{pdf.archivo ? pdf.archivo : ''}</Button>
                              </div>
                            );
                          })
                          }
                        </div>
                      </div>
                      :
                      <div className='divBloquePdf divRequisitos'>
                        <h3 className='centrar'>Documentos Requisitos Legales</h3>
                        <p>No hay Documentos de Requisitos Legales</p>
                      </div>
                    }
                  </div>
                </div>
              </div>

            )}
            {tabValue === 4 && (
              <div>
                <div className='infoPartesHab'>
                  <div className='sinMargen'>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">

                      <HtmlTooltip
                        placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{"Crear Parte"}</Typography>
                          </React.Fragment>
                        }
                      >
                        <Button
                          className="boton buscador bordeCircular "
                          onClick={() => mostrarCrearParte()}
                        >
                          <AddCircleOutlineIcon className='icon-button ' />
                        </Button>
                      </HtmlTooltip>
                    </ButtonGroup>
                  </div>

                  <List className='containerInfoMaquinaPartes'>
                    {listaPartesMaquina.length === 0 ? (
                      <div className="centrar"><p>No hay partes</p></div>
                    ) : Object.values(listaPartesMaquina).sort((a, b) => (a.index > b.index) ? 1 : -1).map((parte, i) =>
                      <>
                        <ListItemButton onClick={() => abrirHistorial(parte.id)} key={parte.id} id={parte.id} className={parte.operacion === 'en ejecucion' ? 'en-ejecucion bordeParte infoParte' : parte.operacion + "-gradient bordeParte infoParte"}>
                          <HtmlTooltip
                            placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                            title={
                              <React.Fragment>
                                <Typography color="inherit" onClick={(e) => { e.stopPropagation() }}>{parte.id}</Typography>
                              </React.Fragment>
                            }
                          >
                            <ListItemText className='infoParteId' >
                              |
                            </ListItemText>
                          </HtmlTooltip>
                          <ListItemText className='infoParteRoom infoTitulo' primary={parte.titulo} />
                          <ListItemText className='infoParteRoom infoHistorico' primary={parte.fecha} />
                          <div className='infoParteRoom centrarInfoParteRoom usuarioImagen' >

                            <ListItemText primary={parte.creador} onClick={() => abrirHistorial(parte.id)} />
                          </div>

                          <div className='infoParteRoom centrarInfoParteRoom usuarioImagenLeft' >
                            {
                              parte.id_departamento == localStorage.getItem('id_departamento') || parte.id_creador == localStorage.getItem('id') ?
                                <div className=' divImagen'>
                                  <IconButton className='boton boton-peque editar' onClick={(e) => { e.stopPropagation(); obtenerParte(parte.id) }} >
                                    <ModeEditIcon className='icon-button ' />
                                  </IconButton>
                                </div> : ""
                            }
                            {parte.fotos ?
                              listaHistoricos(parte.fotos, parte.id)
                              : ""
                            }
                            <div className=' divImagen'>
                              <IconButton className='boton ' onClick={(e) => { e.stopPropagation(); verInforme(parte.id) }} >
                                <PrintIcon className='icon-button ' />
                              </IconButton>
                            </div>
                          </div>



                        </ListItemButton>
                        <Collapse in={isOpenCollapse === parte.id} timeout="auto" unmountOnExit className='separarPalabras'>
                          {parte.historico ?
                            < div >
                              {parte.historico.map((historico, j) => {
                                return < div className={historico.estado === 'en ejecucion' ? 'en-ejecucion  infoParteIdHijo' : historico.estado + "-gradient infoParteIdHijo"}>
                                  <ListItem >
                                    <HtmlTooltip
                                      placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                                      title={
                                        <React.Fragment>
                                          <Typography color="inherit">{historico.estado}</Typography>
                                        </React.Fragment>
                                      }
                                    >
                                      <ListItemText className='infoParteId'>
                                        |
                                      </ListItemText>
                                    </HtmlTooltip>

                                    <ListItemText className='mostrar justificarTexto infoParteRoom infoTitulo' >
                                      {historico.fecha_aplazado !== '' ?
                                        historico.estado === 'terceros' ?
                                          '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
                                          : '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + historico.observaciones

                                        : historico.estado === 'terceros' ?
                                          '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
                                          : historico.observaciones
                                      }
                                    </ListItemText>
                                    <ListItemText className='mostrar justificarTexto infoParteRoom infoHistorico' >
                                      {historico.fecha_evento}
                                    </ListItemText>
                                    <div className='infoParteRoom usuarioHistorico' >
                                      {historico.usuario_asignado}
                                    </div>
                                  </ListItem>
                                </div>
                              })
                              }</div>
                            : ""
                          }
                        </Collapse>
                      </>)
                    }
                  </List>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={limpiarInfoMaquina}>Cancelar</Button>

              {tabValue != 4 && tabValue != 1 ? <Button className="colorGrupo" onClick={confirmTabsMaquina}>{tabValue === 2 || tabValue === 3 ? "Subir" : "Guardar"}</Button>
                : ''}

            </ButtonGroup>
          </DialogActions>
        </Dialog>

        {listaFotosParte.length > 0 ?
          <Dialog fullScreen={fullScreen}
            open={galeriaImagenes}
            onClose={ocultarGaleria}
            aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
          >
            <DialogTitle>Galeria fotos Parte {idParte}</DialogTitle>
            <DialogContent className='container-filtros '>
              {
                listaFotosParte ?
                  listaFotosParte[posImage].archivo !== undefined || listaFotosParte[posImage].archivo !== "" ?
                    <div>
                      <IconButton component="span" className='iconoFoto buscador' onClick={abrirVentanaImagen} >
                        <AddAPhotoIcon className='icon-button ' />
                      </IconButton>

                      <img id="imagenGaleria" className="mdc-image-list__image imagenGaleriaGrande"
                        alt={"Imagen del Parte " + idParte + " Nombre archivo: " + listaFotosParte[posImage].archivo}
                        src={"https://api.grupotel.com/workers.2/imagenes/parte/" + idParte + "/" + listaFotosParte[posImage].archivo}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "https://api.grupotel.com/workers/imagenes/partes/" + idParte + "/" + listaFotosParte[posImage].archivo;
                        }} />

                    </div>
                    : ""
                  : ""
              }

            </DialogContent>
            <DialogActions className='centrar'>
              {
                listaFotosParte.length > 1 ?
                  <div className='direccion ' id='direccionImagen'>
                    <ButtonGroup className=" colorGrupo" variant="contained" aria-label="outlined button group">
                      <IconButton component="span" className='boton direccionFlecha' onClick={prevImagen}>
                        <WestOutlinedIcon />
                      </IconButton>
                      <Button className="colorGrupo2" onClick={ocultarGaleria}>Cerrar</Button>
                      <IconButton component="span" className='boton direccionFlecha' onClick={nextImagen}>
                        <EastOutlinedIcon />
                      </IconButton>
                    </ButtonGroup>
                    <p>{posImage + 1} / {listaFotosParte.length}</p>
                  </div>
                  :
                  <ButtonGroup variant="contained" aria-label="outlined button group">
                    <Button className="colorGrupo" onClick={ocultarGaleria}>Cerrar</Button>
                  </ButtonGroup>
              }
            </DialogActions>
          </Dialog>
          : ""}
        {listaPdfParte.length > 0 ?
          <Dialog fullScreen={fullScreen}
            open={galeriaPdf}
            onClose={ocultarPdf}
            aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
          >
            <DialogTitle>Listado Pdf Parte: {idParte}</DialogTitle>
            <DialogContent className='container-filtros '>
              {
                listaPdfParte ?
                  listaPdfParte.map((pdf, i) => {
                    return <ListItemButton onClick={() => mostrarPdf(listaPdfParte[i].archivo)} >{listaPdfParte[i] ? listaPdfParte[i].archivo : ''}</ListItemButton>
                  })
                  : ""
              }
            </DialogContent>
            <DialogActions className='centrar'>
              <ButtonGroup variant="contained" aria-label="outlined button group">
                <Button className="colorGrupo" onClick={ocultarPdf}>Cerrar</Button>
              </ButtonGroup>
            </DialogActions>
          </Dialog>
          : ""
        }


        <Dialog fullScreen={fullScreen}
          open={estadoBorrarImagen}
          //disableEscapeKeyDown
          onClose={cancelDelImgMaquina}
          aria-labelledby="responsive-dialog-title" id="dialogoConfirmarDel">
          <DialogTitle>Borrar Imagen</DialogTitle>
          <DialogContent>
            <div className='bloque-inputs-filtros '>
              Seguro que desea borrar la imagen?
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={() => cancelDelImgMaquina()}>Cancelar</Button>
              <Button className="colorGrupo" onClick={() => delImgMaquina()}>Confirmar</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={dialogConfirmDocumento}
          //disableEscapeKeyDown
          onClose={cancelBorrarPdfMaquina}
          aria-labelledby="responsive-dialog-title" id="dialogoConfirmarDel">
          <DialogTitle>Borrar Documento</DialogTitle>
          <DialogContent>
            <div className='bloque-inputs-filtros '>
              Seguro que desea borrar el Documento?
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={() => cancelBorrarPdfMaquina()}>Cancelar</Button>
              <Button className="colorGrupo" onClick={() => borrarPdfMaquina()}>Confirmar</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={estadoFiltros}
          //disableEscapeKeyDown
          onClose={comprobarESC}
          aria-labelledby="responsive-dialog-title" id="dialogoFiltros" className='dialogMaquinaCrearParte'>
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>
            <form onSubmit={(e) => { e.preventDefault(); guardarDatos(); }}>
              <div className='bloque-inputs-filtros '>
                <TextField
                  label="ID"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  onKeyPress={(ev) => { comprobarEnter(ev) }}
                  name="id"
                  size="small"
                  value={datosFiltroMaquina.id ? datosFiltroMaquina.id : ''}
                  onChange={actualizarFiltros}
                />
                <TextField
                  label="Nombre"
                  variant="outlined"
                  margin="normal"
                  name="nombre"
                  onKeyPress={(ev) => { comprobarEnter(ev) }}
                  size="small"
                  value={datosFiltroMaquina.nombre ? datosFiltroMaquina.nombre : ''}
                  onChange={actualizarFiltros}
                />
                <TextField
                  label="N_Serie"
                  variant="outlined"
                  margin="normal"
                  name="n_serie"
                  onKeyPress={(ev) => { comprobarEnter(ev) }}
                  size="small"
                  value={datosFiltroMaquina.n_serie ? datosFiltroMaquina.n_serie : ''}
                  onChange={actualizarFiltros}
                />
                <TextField
                  label="Grupo"
                  variant="outlined"
                  margin="normal"
                  name="grupo"
                  size="small"
                  value={datosFiltroMaquina.grupo !== -1 ? datosFiltroMaquina.grupo : ''}
                  onChange={actualizarFiltros}
                  select
                >
                  {gruposLista.map((grupo) => (
                    <MenuItem key={grupo.value} value={grupo.value}>
                      {grupo.label}
                    </MenuItem>
                  ))}
                </TextField>

                <div className='divAplazado titulo'>
                  <FormControlLabel
                    control={<Checkbox checked={datosFiltroMaquina.actualizado === '1' || datosFiltroMaquina.actualizado === true ? true : false} color="secondary" name="actualizado"
                      onChange={(e) => setDatosFiltroMaquina(prevState => ({ ...prevState, noactualizado: e.target.checked ? '0' : '1', actualizado: e.target.checked ? '1' : '0' }))}
                    />}
                    label="Maquina Actualizada"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={datosFiltroMaquina.noactualizado === '1' || datosFiltroMaquina.noactualizado === true ? true : false} color="secondary" name="no_actualizado"
                      onChange={(e) => setDatosFiltroMaquina(prevState => ({ ...prevState, actualizado: e.target.checked ? '0' : '1', noactualizado: e.target.checked ? '1' : '0' }))}
                    />}
                    label="Maquina No Actualizada"
                  />
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={eliminarFiltros}>Cancelar</Button>
              <Button className="colorGrupo" onClick={guardarDatos}>Filtrar</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={estadoNuevoParte}
          //disableEscapeKeyDown
          onClose={comprobarESCParte}
          aria-labelledby="responsive-dialog-title" id="dialogCrearParte">
          <DialogTitle>Crear Parte</DialogTitle>
          <DialogContent>
            <div className='editarHab'>
              {estructuraNuevoParte}
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={ocultarCrearParte}>Cancelar</Button>
              {creandoParte ?
                <Button className="colorGrupo" >
                  <CircularProgress className="botonCarga" color="inherit" />
                </Button>
                : <Button className="colorGrupo" onClick={confirmarCrearParte}>Crear</Button>
              }
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={modoLectura}
          onClose={comprobarESCEditarParte}
          aria-labelledby="responsive-dialog-title" id="dialogDatosParteEditar" >
          <DialogTitle>{rutaActual.idDepartamento ? (datosEditarParte.id ? datosEditarParte.id + ' - ' : '') + rutaActual.nombre : (datosEditarParte.id ? datosEditarParte.id + ' - ' : '') + departamentoInicial}</DialogTitle>
          <DialogContent className='container-filtros alturaDialog'>
            <form>
              <div className='bloque-inputs'>
                <TextField
                  className='medio'
                  variant="outlined"
                  margin="normal"
                  label="Bloque"
                  name='bloque'
                  size="small"
                  value={datosEditarParte.bloque ? datosEditarParte.bloque : ''}
                  onChange={editarParte}
                  inputProps={
                    { readOnly: true, }
                  }
                />
                <TextField
                  className='numeros'
                  variant="outlined"
                  margin="normal"
                  label="Planta"
                  name='planta'
                  onChange={editarParte}
                  size="small"
                  value={datosEditarParte.planta ? (datosEditarParte.planta ? datosEditarParte.planta : ' ') : ' '}
                  //type="number"
                  inputProps={
                    { readOnly: true, }
                  }
                />
                <TextField
                  key="editarParteHab"
                  variant="outlined"
                  margin="normal"
                  label="Hab."
                  name='hab'
                  size="small"
                  className='habitacionBuscador'
                  onChange={editarParte}
                  onKeyPress={(ev) => { comprobarEnterHab(ev) }}
                  value={datosEditarParte.hab ? datosEditarParte.hab : ''}
                  InputProps={
                    {
                      endAdornment:
                        <IconButton
                          key="buscarHab"
                          className="boton buscador botonHabitacionBuscador"
                          // onClick={buscarHabitacion}
                          onClick={() => buscarHabitacion()}
                        >
                          <SearchIcon className='icon-button ' />
                        </IconButton>
                    }}
                />

                <TextField
                  className='medio'
                  variant="outlined"
                  margin="normal"
                  label="Tipo parte"
                  name="tipo"
                  size="small"
                  onChange={editarParte}
                  value={datosEditarParte.tipo ? datosEditarParte.tipo : 'normal'}
                  select
                >
                  {tipoPartes
                    .sort((a, b) => a.orden - b.orden) // Ordenar por el campo 'orden' de forma ascendente
                    .map((tipoParte) => (
                      <MenuItem key={`editTipoDatosP-${tipoParte.valor}`} value={tipoParte.valor}>
                        <Tooltip
                          title={tipoParte.descripcion && tipoParte.descripcion.trim() ? tipoParte.descripcion : ''} // Mostrar solo si hay descripción
                          placement="right"
                        >
                          <span>{tipoParte.nombre}</span> {/* Envolver el texto del menú, no el componente entero */}
                        </Tooltip>
                      </MenuItem>
                    ))}
                </TextField>

                <TextField
                  label="Aplazar"
                  type="date"
                  format={'DD/MM/YYYY'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name='fecha_aplazado'
                  size="small"
                  value={datosEditarParte.fecha_aplazado ? datosEditarParte.fecha_aplazado : ''}
                  onChange={editarParte}
                />
              </div>
              <div className='formChip' >
                {chipMultipleAsignados(2, false)}
              </div>
              <div className='bloque-inputs alinearIzquierda'>
                <div className='divEdicion'>
                  <div className='divFiltrosTerceros'>
                    {obtenerSelectEstados()}
                    <TextField
                      className='proveedoresTextfield'
                      variant="outlined"
                      margin="normal"
                      label="Prioridad"
                      name="prioridad"
                      onChange={editarParte}
                      size="small"
                      value={datosEditarParte.prioridad ? datosEditarParte.prioridad : 'baja'}
                      select
                    >
                      {tipoPrioridad.map((prioridad) => (
                        <MenuItem key={`editPrioridadDatosP-${prioridad.valor}`} value={prioridad.valor}>
                          {prioridad.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className='divFiltrosTerceros'>
                    {mostrarTerceros ?
                      <div className='divFiltrosTextField'>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          label="Tipo terceros"
                          name="id_tipo_terceros"
                          className='proveedoresTextfield'
                          size="small"
                          value={datosEditarParte.id_tipo_terceros ? datosEditarParte.id_tipo_terceros : ''}
                          onChange={editarParte}
                          select
                        >
                          {tipoTerceros.map((tipoTercero) => (
                            <MenuItem key={`editTerceroDatosP-${tipoTercero.value}`} value={tipoTercero.value}>
                              {tipoTercero.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Autocomplete
                          id="proveedorAuto"
                          className='proveedoresTextfield'
                          options={terceros}
                          onChange={cambioProveedor}
                          size="small"
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => <TextField {...params} label="Proveedor" />}
                        />
                      </div>
                      : ""
                    }
                  </div>
                  <TextField
                    className='observaciones observacionesEditarParte'
                    label="Observaciones"
                    name="observaciones"
                    size="small"
                    onChange={editarParte}
                    multiline
                    rows={12}
                    value={datosEditarParte.observaciones ? datosEditarParte.observaciones : ' '}
                  />
                </div>
                <div className='containerBotonesEditarDepartamento'>
                  <div className='containerPdf'>
                    <label htmlFor="upload-files" className=''>
                      <input
                        className='ocultar'
                        accept=".pdf, .jpg, .jpeg, .png"
                        id="upload-files"
                        type="file"
                        onChange={selecImageEditarParte}
                        multiple
                      />
                      <Button component='span' className='colorGrupo botonEditarDepartamento' variant="outlined">Añadir archivo</Button>
                    </label>
                    <Button
                      variant="outlined"
                      margin="normal"
                      className='colorGrupo botonEditarDepartamento'
                      onClick={mostrarDepartamentos}>
                      Cambiar Lugar
                    </Button>
                  </div>
                  <div className='css-1rv3ei8-MuiFormControl-root-MuiTextField-root'>


                    <div className='divPdf'>
                      {listaPDFEditarParte.length > 0 ?
                        <div className='containerPdf' >
                          {listaPDFEditarParte.map((pdf, j) => {
                            return (
                              <div className='' key={pdf.id}>
                                <Button className='nuevoParteNombrePdf' variant="text" onClick={() => ventanaImagenEditarParte(pdf.ruta)} >{pdf.nombre ? pdf.nombre : ''}</Button>
                                <IconButton className='iconX' onClick={() => borrarPdfEditarParte(pdf.id)}>x</IconButton>
                              </div>
                            );
                          })
                          }
                        </div>
                        : ''
                      }
                    </div>
                    <div className='containerDiv'>
                      {listaImagenEditarParte.length > 0 ?
                        <div className='divImgMaquinasNuevas'>
                          {listaImagenEditarParte.map((foto, j) => {
                            return (
                              <div className='divCentrarImagen' key={foto.id}>
                                <IconButton className='iconoBorrarImg' onClick={() => borrarImagenEditarParte(foto.id)}>
                                  <CancelIcon className=' ' />
                                </IconButton>
                                <img className="editarPartePreviewImagen" src={foto.ruta} onClick={() => ventanaImagenEditarParte(foto.ruta)} />
                              </div>
                            );
                          })
                          }
                        </div>
                        : ''
                      }
                    </div>

                  </div>
                </div>
                <div className='nuevoParteBloque  '>
                  <div className=' bloqueEdicion departamentoEditar ocultar anchoDepartamentos'>
                    <Box sx={{ width: '100%' }} className='departamento listaDepartamentos'>

                      <List
                        className='botonLista'
                        sx={{ width: '100% !important' }}
                      >
                        <ListItem className={'divisor'}>
                          <div className='itemBoton'>
                            <TextField InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FilterAltIcon color='#00a2dc' />
                                </InputAdornment>
                              ),
                            }} value={textoBusqueda} onChange={(ev) => { handleSearchText(ev) }} id="search" fullWidth></TextField>
                          </div>
                        </ListItem>
                        <ListItem divider className='divisor botonAtras'>
                          <ListItemButton className='itemBoton'
                            disabled={activarAtras}
                            onClick={backDepartamento}
                          >
                            <ListItemText className='center'>
                              <ArrowBackIcon className='icon-button ' />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                        {(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).map((departamento, i) =>
                          <>
                            <ListItem divider className={(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).length - 1 === i ? 'divisor ultimoDiv' : 'divisor'} >
                              <ListItemButton className='itemBoton' value={departamento.label} id={departamento.value} onClick={(ev) => { obtenerSubDepartamento(ev) }}>
                                <ListItemText primary={departamento.label} id={departamento.value} className='center' />
                              </ListItemButton>
                            </ListItem>
                          </>
                        )
                        }
                      </List>
                    </Box>
                  </div>
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <div className="botonera-separado" >
              <Button className="colorGrupo" onClick={finEdicion}>Cancelar</Button>
              <Button className="colorGrupo" onClick={confirmarEdicion}>Confirmar</Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={estadoCrearFichaMaquina}
          onClose={comprobarESCFichaMaquina}
          aria-labelledby="responsive-dialog-title" id="crearFichaGrupo" className='dialogMaquinaCrearParte'>
          <DialogTitle>Crear Caracteristica a la maquina: {datosInfoMaquina.id + " - " + datosInfoMaquina.nombre}</DialogTitle>
          <DialogContent>
            <div className='bloqueCaracteristicaGrupo'>
              <TextField
                label="Caracteristica"
                variant="outlined"
                margin="normal"
                name="id_caracteristica"
                size="small"
                value={datosFichaMaquina.id_caracteristica != '' ? datosFichaMaquina.id_caracteristica : ''}
                onChange={actualizarCrearFichaGrupo}
                select
              >
                {listaCaracteristicaSelect
                  .map((grupo) => (
                    <MenuItem key={grupo.id} value={grupo.id}>
                      {grupo.nombre}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                label="Tipo"
                variant="outlined"
                margin="normal"
                name="id_caracteristica_tipo"
                size="small"
                value={datosFichaMaquina.id_caracteristica_tipo !== -1 ? datosFichaMaquina.id_caracteristica_tipo : ''}
                onChange={actualizarCrearFichaGrupo}
                select
              >
                {listaTiposCaracteristicas
                  .sort((a, b) => (a.nombre > b.nombre) ? 1 : -1)
                  .map((grupo) => (
                    <MenuItem key={grupo.id} value={grupo.id}>
                      {grupo.nombre}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                label="Valor"
                variant="outlined"
                margin="normal"
                name="valor"
                size="small"
                value={datosFichaMaquina.valor ? datosFichaMaquina.valor : ''}
                onChange={actualizarCrearFichaGrupo}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={ocultarCrearFichaMaquina}>Cancelar</Button>
              {creandoFichaMaquina ?
                <Button className="colorGrupo" >
                  <CircularProgress className="botonCarga" color="inherit" />
                </Button>
                : <Button className="colorGrupo" onClick={confirmarCrearFichaMaquina}>Crear</Button>
              }
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={estadoEditarFichaGrupo}
          onClose={comprobarESCEditarFichaGrupo}
          aria-labelledby="responsive-dialog-title" id="editarFichaGrupo" className='dialogMaquinaCrearParte'>
          <DialogTitle>Editar Caracteristica de la Maquina: {datosInfoMaquina.id + " - " + datosInfoMaquina.nombre}</DialogTitle>
          <DialogContent>
            <div className='bloqueCaracteristicaGrupo'>
              <TextField
                label="Caracteristica"
                variant="outlined"
                margin="normal"
                name="id_caracteristica"
                size="small"
                value={datosFichaMaquina.id_caracteristica != '' ? datosFichaMaquina.id_caracteristica : ''}
                onChange={actualizarCrearFichaGrupo}
                select
              >
                {listaCaracteristicaSelect
                  .map((grupo) => (
                    <MenuItem key={grupo.id} value={grupo.id}>
                      {grupo.nombre}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                label="Tipo"
                variant="outlined"
                margin="normal"
                name="id_caracteristica_tipo"
                size="small"
                value={datosFichaMaquina.id_caracteristica_tipo !== -1 ? datosFichaMaquina.id_caracteristica_tipo : ''}
                onChange={actualizarCrearFichaGrupo}
                select
              >
                {listaTiposCaracteristicas
                  .sort((a, b) => (a.nombre > b.nombre) ? 1 : -1)
                  .map((grupo) => (
                    <MenuItem key={grupo.id} value={grupo.id}>
                      {grupo.nombre}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                label="Valor"
                variant="outlined"
                margin="normal"
                name="valor"
                size="small"
                value={datosFichaMaquina.valor ? datosFichaMaquina.valor : ''}
                onChange={actualizarCrearFichaGrupo}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={ocultarEditarFichaGrupo}>Cancelar</Button>
              {editandoFichaGrupo ?
                <Button className="colorGrupo" >
                  <CircularProgress className="botonCarga" color="inherit" />
                </Button>
                : <Button className="colorGrupo" onClick={confirmarEditarFichaGrupo}>Guardar</Button>
              }
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={estadoBorrarFichaMaquina}
          onClose={comprobarESCBorrarFichaMaquina}
          aria-labelledby="responsive-dialog-title" id="dialogBorrarFichaGrupo">
          <DialogTitle>Seguro que quiere borrar la Caracteristica de la Maquina?</DialogTitle>
          <DialogContent>
            <div className=''>
              <p className='center'>Información Caracteristica a borrar:</p>
              <div className='containerTablas'>
                <div className='infoBorrar infoBorraPrimero'>
                  <span>ID:</span>
                  <span>{datosFichaMaquina.id}</span>
                </div>
                <div className='infoBorrar'>
                  <span>Caracteristica:</span>
                  <span>{datosFichaMaquina.id_caracteristica}</span>
                </div>
                <div className='infoBorrar'>
                  <span>Tipo:</span>
                  <span>{datosFichaMaquina.id_caracteristica_tipo}</span>
                </div>
                <div className='infoBorrar'>
                  <span>Valor:</span>
                  <span>{datosFichaMaquina.valor}</span>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={cancelBorrarFichaMaquina}>Cancelar</Button>
              {borrandoFichaMaquina ?
                <Button className="colorGrupo" >
                  <CircularProgress className="botonCarga" color="inherit" />
                </Button>
                : <Button className=" colorGrupo borrarBoton" onClick={confirmarBorrarFichaMaquina}>Borrar</Button>
              }
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={estadoListaCaracteristicasGrupo}
          onClose={comprobarESCListaCaracteristicasGrupo}
          aria-labelledby="responsive-dialog-title" id="duplicarCaracteristicasGrupo">
          <DialogTitle>Seguro que quiere importar las siguientes caracteristicas a la maquina: {datosInfoMaquina.nombre}</DialogTitle>
          <DialogContent>
            <div>
              <p className='center'>Caracteristicas del Grupo: {datosInfoMaquina.id + " - " + nombreGrupo}</p>
              {tablaGruposCaracteristica}
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={ocultarListaCaracteristicasGrupo}>Cancelar</Button>
              {duplicandoListaCaracteristicasGrupo ?
                <Button className="colorGrupo" >
                  <CircularProgress className="botonCarga" color="inherit" />
                </Button>
                : <Button className=" colorGrupo" onClick={confirmarDuplicarListaCaracteristicasGrupo}>Importar</Button>
              }
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
      </div>
    </>
  );
}
export default Maquinas;
