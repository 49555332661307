import React, { useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import { TextField } from '@mui/material';

//Le pasamos un id para poder controlar el select
function TextFieldFecha({ idFormulario, nombreTextField, textoLabel, claseFormControl, claseTextField, actualizarFecha, valorInicial }) {

    const [selectedDate, setSelectedDate] = useState(valorInicial);

    let error = false;
    //Si le llega el id de Formulario
    const idForm = `fecha-${idFormulario}`;

    //Funcion para obtener la fecha de hoy. 
    function fechaActual() {
        let actual = new Date();
        let dia = actual.getDate();
        let n_mes = new Array("01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12");

        if (dia < 10) {
            dia = "0" + actual.getDate();
        }
        let mes = n_mes[actual.getMonth()];

        let ano = actual.getFullYear();

        let fecha = dia + "-" + mes + "-" + ano;
        return fecha;
    }

    // función para validar la fecha introducida
    function comprovar_fetxa(f) {
        var n_dias = new Array("31", "28", "31", "30", "31", "30", "31", "31", "30", "31", "30", "31");
        var n_mes = new Array("01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12");
        var actual = new Date();
        var dd_act = actual.getDate();
        var mm_act = n_mes[actual.getMonth()];
        var aa_act = actual.getFullYear();

        // Comprobar si el año actual es bisiesto
        if ((aa_act % 4 == 0 && aa_act % 100 != 0) || (aa_act % 400 == 0)) {
            n_dias[1] = "29"; // Si es bisiesto, febrero tiene 29 días
        }

        var l = f.length;
        switch (l) {
            case 0:
                f = "";
                error = false;
                break;
            case 1:
                if (f == "h" || f == "H") {
                    var dd = dd_act;
                    if (dd < 10) { dd = "0" + dd; }
                    var mm = mm_act;
                    var aa = aa_act;
                    f = dd + "-" + mm + "-" + aa;
                    error = false;
                } else if (f == "a" || f == "A") {
                    var dd = dd_act - 1;
                    if (dd < 10) { dd = "0" + dd; }
                    var mm = mm_act;
                    var aa = aa_act;
                    if (dd == 0) {
                        mm -= 1;
                        if (mm == 0) {
                            aa -= 1;
                            mm = 12;
                            if ((aa % 4 == 0) && (aa % 100 !== 0 || aa % 400 == 0)) {
                                n_dias[1] = "29";
                            }
                            dd = n_dias[mm - 1];
                        }
                    }
                    f = dd + "-" + mm + "-" + aa;
                    error = fetxaOk(dd, mm, aa);
                } else if (f == "p" || f == "P") {
                    // Primer día del año actual
                    var dd = "01";
                    var mm = "01";
                    var aa = aa_act;
                    f = dd + "-" + mm + "-" + aa;
                    error = false;
                } else {
                    error = true;
                }
                break;
            case 2:
                var dd = f;
                var mm = mm_act;
                var aa = aa_act;
                f = dd + "-" + mm + "-" + aa;
                error = fetxaOk(dd, mm, aa);
                break;
            case 4:
                var dd = f.substr(0, 2);
                var mm = f.substr(2, 2);
                var aa = aa_act;
                f = dd + "-" + mm + "-" + aa;
                error = fetxaOk(dd, mm, aa);
                break;
            case 5:
                if (f.substr(2, 1) == "-" || f.substr(2, 1) == "/") {
                    var dd = f.substr(0, 2);
                    var mm = f.substr(3, 2);
                    var aa = aa_act;
                    f = dd + "-" + mm + "-" + aa;
                } else {
                    error = true;
                }
                error = fetxaOk(dd, mm, aa);
                break;
            case 6:
                var dd = f.substr(0, 2);
                var mm = f.substr(2, 2);
                var aa = "20" + f.substr(4, 2);
                f = dd + "-" + mm + "-" + aa;
                error = fetxaOk(dd, mm, aa);
                break;
            case 8:
                var p1 = f.substr(2, 1);
                var p2 = f.substr(5, 1);
                if (((p1 == "-") || (p1 == "/")) && ((p2 == "-") || (p2 == "/"))) {
                    var dd = f.substr(0, 2);
                    var mm = f.substr(3, 2);
                    var aa = "20" + f.substr(6, 4);
                    f = dd + "-" + mm + "-" + aa;
                    error = fetxaOk(dd, mm, aa);
                } else {
                    var dd = f.substr(0, 2);
                    var mm = f.substr(2, 2);
                    var aa = f.substr(4, 4);
                    f = dd + "-" + mm + "-" + aa;
                    error = fetxaOk(dd, mm, aa);
                }
                break;
            case 10:
                var p1 = f.substr(2, 1);
                var p2 = f.substr(5, 1);
                if (((p1 == "-") || (p1 == "/")) && ((p2 == "-") || (p2 == "/"))) {
                    var dd = f.substr(0, 2);
                    var mm = f.substr(3, 2);
                    var aa = f.substr(6, 4);
                    f = dd + "-" + mm + "-" + aa;
                    error = fetxaOk(dd, mm, aa);
                } else {
                    error = true;
                }
                break;
            default:
                error = true;
        }
        if (error) {
            alert('Formato de fecha incorrecto');
            return '0';
        } else {
            return f;
        }
    }

    //Validacion de la fecha.
    function fetxaOk(dia, mes, anyo) {
        var error = false;
        var n_dias = new Array("31", "28", "31", "30", "31", "30", "31", "31", "30", "31", "30", "31");
        var n_mes = new Array("01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12");
        if ((anyo % 4 == 0) && (anyo % 100 !== 0 || anyo % 400 == 0)) {
            n_dias[1] = "29";
        }
        if (dia > n_dias[mes - 1]) {
            error = true;
        } else {
            for (var i = 0; i < 12; i++) {
                if (mes == n_mes[i]) { error = false; break; } else { error = true; }
            }
        }
        return error;
    }

    // función para manejar el cambio de fecha
    const handleDateChange = (e) => {

        const value = e.target.value;
        //Comprobar si se ha introducido una de las letras permitidas. H hoy A ayer.
        if (value.includes('h') || value.includes('H') || value.includes('a') || value.includes('A') || value.includes('p') || value.includes('P')) {
            setSelectedDate(comprovar_fetxa(value));
        } else if (value.includes('-')) {
            //Comprobacion de añadir un -
            setSelectedDate(value);
        } else if (!isNaN(value)) {
            //El valor introducido es un numero
            setSelectedDate(value);
        } else if (e.target.value === '') {
            setSelectedDate('');
        } else {
            //Se ha introducido cualquier otro campo.
            // console.log("valor no valido");
        }
    };

    // Comprobamos la tecla introducida en el TextField.
    const comprobarEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            comprobarYActualizarFecha(e.target.value);
        }
    };
    // Función para comprobar y ajustar la fecha
    const comprobarYActualizarFecha = (value) => {
        if (value.includes('h') || value.includes('H') || value.includes('a') || value.includes('A')|| value.includes('p') || value.includes('P')) {
            setSelectedDate(comprovar_fetxa(value));
        } else if (value.includes('-')) {
            if (value.charAt(value.length - 1) === "-") {
                setSelectedDate(comprovar_fetxa(value.substr(0, value.length - 1)));
            } else {
                let arrayNumeros = value.split("-");
                let stringFecha = "";
                if (arrayNumeros[0].length === 1) {
                    stringFecha = arrayNumeros[0] === "0" ? "01" : "0" + arrayNumeros[0];
                } else {
                    stringFecha = arrayNumeros[0];
                }
                if (arrayNumeros[1].length === 1) {
                    stringFecha += arrayNumeros[1] === "0" ? "-01" : "-0" + arrayNumeros[1];
                } else {
                    stringFecha += "-" + arrayNumeros[1];
                }
                if (arrayNumeros[2]) {
                    stringFecha += "-" + arrayNumeros[2];
                }
                setSelectedDate(comprovar_fetxa(stringFecha));
            }
        } else if (!isNaN(value)) {
            if (value.length % 2 === 0) {
                setSelectedDate(comprovar_fetxa(value));
            } else {
                if (value.length === 1) {
                    setSelectedDate(comprovar_fetxa(value === "0" ? "01" : "0" + value));
                } else {
                    setSelectedDate(comprovar_fetxa(value.substr(0, value.length - 1) + "0" + value.charAt(value.length - 1)));
                }
            }
        } else {
            // Valor no válido
        }
    };
    useEffect(() => {
        if (valorInicial == '') {
            setSelectedDate('');
        }
    }, [valorInicial]);

    useEffect(() => {
        //setSelectedDate(fechaActual);
        actualizarFecha(selectedDate);
    }, [selectedDate]);

    return (
        <>
            <FormControl className={claseFormControl}>
                <TextField
                    className={claseTextField}
                    id={idForm}
                    name={nombreTextField ? nombreTextField : "fetxa"}
                    type="text"
                    size="small"
                    label={textoLabel ? textoLabel : "Fecha"}
                    value={selectedDate}
                    onChange={handleDateChange}
                    // onBlur={handleDateChange}
                    onBlur={(e) => comprobarYActualizarFecha(e.target.value)}

                    onKeyPress={(ev) => { ev.stopPropagation(); comprobarEnter(ev) }}
                    placeholder="dd-mm-yyyy"
                />
            </FormControl>
        </>
    )
}
export default TextFieldFecha;