import React, { useState } from 'react';

import GridParte from '../../components/GridParte/GridParte';
import DatosParte from '../../components/DatosParte/DatosParte';
import './Partes.css';

function Salidas() {
  const [actuGridParte, setActuGridParte] = useState(false);

  const datosParteAParte = (datos) => {
    if (datos === true) {
      setActuGridParte(true);
      const timer2 = setTimeout(() => {
        setActuGridParte(false);
      }, 1000);
      return () => clearTimeout(timer2);
    }
  }
  return (
    <div className='container'>
      <div className='content'>
        <div className='article'>
          <GridParte tipoSalidas={1} actuGridParte={actuGridParte} />
        </div>
        <div className='aside' >
          <DatosParte tipoSalidas={1} datosParteAParte={datosParteAParte} />
        </div>
      </div>
    </div>
  );
}
export default Salidas;