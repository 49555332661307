import React, { useState, useEffect } from 'react';
import { Tooltip, styled, InputAdornment, tooltipClasses, Zoom, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, useMediaQuery, ButtonGroup, Divider, TextField, MenuItem, List, ListItemButton, ListItem, ListItemText, IconButton, Box, Collapse, Tab, Tabs, Checkbox, OutlinedInput, InputLabel, Chip, FormControl, Select, Autocomplete } from '@mui/material';
import { DataGrid, visibleGridColumnsSelector } from '@mui/x-data-grid';

import { useTheme } from '@mui/material/styles';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import MostrarError from '../../components/AccionesPartes/MostrarError';
import './ConfMaquinas.css';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(10),
        border: '1px solid #dadde9',

    },
}));

function ConfMaquinas() {
    const API_URL = 'https://api.grupotel.com/workers.2/';

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Lista de datos
    const [listaUnidades, setListaUnidades] = useState([]);
    const [tablaUnidades, setTablaUnidades] = useState("");

    const [listaCaracteristicas, setListaCaracteristicas] = useState([]);
    const [tablaCaracteristicas, setTablaCaracteristicas] = useState("");

    const [listaTiposCaracteristicas, setListaTiposCaracteristicas] = useState([]);
    const [tablaListaTiposCaracteristicas, setTablaListaTiposCaracteristicas] = useState("");

    const [listaPropiedades, setListaPropiedades] = useState([]);
    const [tablaPropiedades, setTablaPropiedades] = useState("");

    const [listaGrupos, setListaGrupos] = useState([]);
    const [tablaGrupos, setTablaGrupos] = useState("");

    const [listaGruposCaracteristica, setListaGruposCaracteristica] = useState([]);
    const [tablaGruposCaracteristica, setTablaGruposCaracteristica] = useState("");

    const columnas = [
        {
            headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
        },
        {
            headerClassName: 'header-grid', field: 'nombre', headerName: 'Nombre', width: 680,
        },
        {
            headerClassName: 'header-grid', field: 'unidad', headerName: 'Unidad', width: 160,
        },
        {
            headerClassName: 'header-grid',
            field: 'editar',
            headerName: 'Editar',
            width: 80,
            renderCell: (params) => {
                const onClickEditar = () => mostrarEditarUnidades(params.row.id, params.row.nombre, params.row.unidad);
                return (
                    <HtmlTooltip
                        placement="left" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Editar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte"
                            onClick={onClickEditar}
                        >
                            <ModeEditIcon className='icon-button' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }, {
            headerClassName: 'header-grid',
            field: 'borrar',
            headerName: 'Borrar',
            width: 80,
            renderCell: (params) => {
                const onClickBorrar = () => confirmarBorrarUnidad(params.row.id, params.row.nombre, params.row.unidad);
                return (
                    <HtmlTooltip
                        placement="right" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Borrar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte borrarIcono"
                            onClick={onClickBorrar}
                        >
                            <DeleteIcon className='icon-button ' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }
    ]
    const columnasCaracteristicas = [
        {
            headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
        },
        {
            headerClassName: 'header-grid', field: 'nombre', headerName: 'Caracteristica', width: 400,
        },
        {
            headerClassName: 'header-grid', field: 'unidad', headerName: 'Unidad', width: 160,
        },
        {
            headerClassName: 'header-grid',
            field: 'editar',
            headerName: 'Editar',
            width: 80,
            renderCell: (params) => {
                const onClickEditar = () => mostrarEditarCaracteristica(params.row.id, params.row.nombre, params.row.id_unidad_medida, params.row.unidad);
                return (
                    <HtmlTooltip
                        placement="left" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Editar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte"
                            onClick={onClickEditar}
                        >
                            <ModeEditIcon className='icon-button' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }, {
            headerClassName: 'header-grid',
            field: 'borrar',
            headerName: 'Borrar',
            width: 80,
            renderCell: (params) => {
                const onClickBorrar = () => confirmarBorrarCaracteristica(params.row.id, params.row.nombre, params.row.unidad);
                return (
                    <HtmlTooltip
                        placement="right" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Borrar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte borrarIcono"
                            onClick={onClickBorrar}
                        >
                            <DeleteIcon className='icon-button ' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }
    ]

    const columnasTiposCaracteristicas = [
        {
            headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
        },
        {
            headerClassName: 'header-grid', field: 'nombre', headerName: 'Tipo Caracteristica', width: 400,
        },
        {
            headerClassName: 'header-grid',
            field: 'editar',
            headerName: 'Editar',
            width: 80,
            renderCell: (params) => {
                const onClickEditarTipo = () => mostrarEditarTipo(params.row.id, params.row.nombre);
                return (
                    <HtmlTooltip
                        placement="left" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Editar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte"
                            onClick={onClickEditarTipo}
                        >
                            <ModeEditIcon className='icon-button' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }, {
            headerClassName: 'header-grid',
            field: 'borrar',
            headerName: 'Borrar',
            width: 80,
            renderCell: (params) => {
                const onClickBorrarTipo = () => confirmarBorrarTipo(params.row.id, params.row.nombre, params.row.id_caracteristica);
                return (
                    <HtmlTooltip
                        placement="right" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Borrar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte borrarIcono"
                            onClick={onClickBorrarTipo}
                        >
                            <DeleteIcon className='icon-button ' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }
    ]

    const columnasPropiedadesCaracteristicas = [
        {
            headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
        },
        {
            headerClassName: 'header-grid', field: 'nombre', headerName: 'Nombre Propiedad', width: 300,
        },
        {
            headerClassName: 'header-grid', field: 'valor', headerName: 'Valor', width: 200,
        },
        {
            headerClassName: 'header-grid',
            field: 'editar',
            headerName: 'Editar',
            width: 80,
            renderCell: (params) => {
                const onClickEditarPropiedad = () => mostrarEditarPropiedad(params.row.id, params.row.nombre, params.row.valor);
                return (
                    <HtmlTooltip
                        placement="left" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Editar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte"
                            onClick={onClickEditarPropiedad}
                        >
                            <ModeEditIcon className='icon-button' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }, {
            headerClassName: 'header-grid',
            field: 'borrar',
            headerName: 'Borrar',
            width: 80,
            renderCell: (params) => {
                const onClickBorrarPropiedad = () => confirmarBorrarPropiedad(params.row.id, params.row.nombre, params.row.valor);
                return (
                    <HtmlTooltip
                        placement="right" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Borrar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte borrarIcono"
                            onClick={onClickBorrarPropiedad}
                        >
                            <DeleteIcon className='icon-button ' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }
    ]

    const columnasGrupos = [
        {
            headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
        },
        {
            headerClassName: 'header-grid', field: 'nombre', headerName: 'Grupos Maquinas', width: 800,
        },
        {
            headerClassName: 'header-grid',
            field: 'editar',
            headerName: 'Editar',
            width: 80,
            renderCell: (params) => {
                const onClickEditar = () => mostrarEditarGrupo(params.row.id, params.row.nombre);
                return (
                    <HtmlTooltip
                        placement="left" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Editar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte"
                            onClick={onClickEditar}
                        >
                            <ModeEditIcon className='icon-button' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }, {
            headerClassName: 'header-grid',
            field: 'borrar',
            headerName: 'Borrar',
            width: 80,
            renderCell: (params) => {
                const onClickBorrar = () => mostrarBorrarGrupo(params.row.id, params.row.nombre);
                return (
                    <HtmlTooltip
                        placement="right" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Borrar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte borrarIcono"
                            onClick={onClickBorrar}
                        >
                            <DeleteIcon className='icon-button ' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }
    ]

    const columnasGruposCaracteristicas = [
        {
            headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
        },
        {
            headerClassName: 'header-grid', field: 'hotel', headerName: 'Hotel', width: 220,
        },
        {
            headerClassName: 'header-grid', field: 'caracteristica', headerName: 'Caracteristica', width: 400,
        },
        {
            headerClassName: 'header-grid', field: 'tipo', headerName: 'Tipo', width: 300,
        },
        {
            headerClassName: 'header-grid', field: 'valor', headerName: 'Valor', width: 120,
        },
        {
            headerClassName: 'header-grid', field: 'unidad', headerName: 'Unidad', width: 160,
        },
        {
            headerClassName: 'header-grid',
            field: 'editar',
            headerName: 'Editar',
            width: 80,
            renderCell: (params) => {
                const onClickEditar = () => mostrarEditarFichaGrupo(params.row.id, params.row.id_caracteristica, params.row.id_caracteristica_tipo, params.row.id_hotel, params.row.valor);
                return (
                    <HtmlTooltip
                        placement="left" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Editar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte"
                            onClick={onClickEditar}
                        >
                            <ModeEditIcon className='icon-button' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }, {
            headerClassName: 'header-grid',
            field: 'borrar',
            headerName: 'Borrar',
            width: 80,
            renderCell: (params) => {
                const onClickBorrar = () => mostrarBorrarFichaGrupo(params.row.id, params.row.caracteristica, params.row.tipo, params.row.hotel, params.row.valor);
                return (
                    <HtmlTooltip
                        placement="right" enterDelay={300} leaveDelay={200} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{"Borrar"}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Button
                            className="nuevoParte borrarIcono"
                            onClick={onClickBorrar}
                        >
                            <DeleteIcon className='icon-button ' />
                        </Button>
                    </HtmlTooltip>
                );
            },
        }
    ]

    //Conjunto para mostrar un Alert con el resultado de la petición
    const [mensajeError, setMensajeError] = useState('');
    const [tipoError, setTipoError] = useState('');
    const [contadorError, setContadorError] = useState(1);
    const [estadoAlert, setEstadoAlert] = React.useState(false);

    /******************************************************************/
    //                   Inicio Dialog Crear Unidad                   //
    /******************************************************************/
    const [estadoUnidad, setEstadoUnidad] = React.useState(false);
    const [creandoUnidad, setCreandoUnidad] = React.useState(false);
    const [errorNombreMedida, setErrorNombreMedida] = React.useState(false);
    const [errorUnidadMedida, setErrorUnidadMedida] = React.useState(false);

    const [datosUnidad, setDatosUnidad] = useState({
        id: '',
        nombre: '',
        unidad: ''
    });
    const mostrarCrearUnidades = () => {
        setEstadoUnidad(true);
    };
    const comprobarESCUnidad = (event) => {
        if (event.keyCode === 27) {
            ocultarUnidad();
        }
    };
    const ocultarUnidad = () => {
        setErrorUnidadMedida(false);
        setErrorNombreMedida(false);

        setDatosUnidad({
            id: '',
            nombre: '',
            unidad: ''
        });
        setEstadoUnidad(false);
        setCreandoUnidad(false);
        getUnidades();
    };
    const crearUnidad = () => {
        if (datosUnidad.nombre.trim() === '') {
            setErrorNombreMedida(true);
            mostrarError("El Nombre no puede estar vacio");
        } else {
            setErrorNombreMedida(false);
        }
        if (datosUnidad.unidad.trim() === '') {
            setErrorUnidadMedida(true);
            mostrarError("La Unidad no puede estar vacio");
        } else {
            setErrorUnidadMedida(false);
        }
        if (datosUnidad.nombre.trim() != '' && datosUnidad.unidad.trim() != '') {
            setCreandoUnidad(true);
            const fd = new FormData();
            fd.append("f", "maquinas.crearUnidadMedida");
            fd.append("token", localStorage.getItem('token'));
            fd.append("nombre", datosUnidad.nombre.trim());
            fd.append("unidad", datosUnidad.unidad.trim());

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        getUnidades();
                        mostrarError("Se ha creado Unidad: " + result.unidad, "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setCreandoUnidad(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido crear la unidad");
                })
            ocultarUnidad();
        }
    };

    /******************************************************************/
    //                   Fin Dialog Crear Unidad                      //
    /******************************************************************/
    /******************************************************************/
    //                   Inicio Dialog Editar Unidad                  //
    /******************************************************************/
    const [estadoEditarUnidad, setEstadoEditarUnidad] = React.useState(false);
    const [editarUnidad, setEditarUnidad] = React.useState(false);

    const [datosUnidadCopia, setDatosUnidadCopia] = useState([{
        id: '',
        nombre: '',
        unidad: ''
    }]);
    const mostrarEditarUnidades = (id, nombre, unidad) => {
        getUnidades();
        setEstadoEditarUnidad(true);
        setDatosUnidad({
            id: id,
            nombre: nombre,
            unidad: unidad
        });
        setDatosUnidadCopia({
            id: id,
            nombre: nombre,
            unidad: unidad
        });
    };
    const comprobarESCEditarUnidad = (event) => {
        if (event.keyCode === 27) {
            ocultarEditarUnidad();
        }
    };
    const ocultarEditarUnidad = () => {
        setDatosUnidad({
            id: '',
            nombre: '',
            unidad: ''
        });
        setEstadoEditarUnidad(false);
        setEditarUnidad(false);
        getUnidades();
    };
    const confirmarEditarUnidad = () => {

        if (datosUnidad.nombre.trim() === '' || datosUnidad.unidad.trim() === '') {
            mostrarError("No se admiten espacios vacios");
        } else if (datosUnidad.nombre.trim() === datosUnidadCopia.nombre.trim() && datosUnidad.unidad.trim() === datosUnidadCopia.unidad.trim()) {
            mostrarError("No hay cambios a guardar");
        } else {
            setEditarUnidad(true);

            // Realizamos un Update de la Unidad de Medida
            const fd = new FormData();
            fd.append("f", "maquinas.updateUnidadMedida");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_unidad", datosUnidad.id);

            if (datosUnidad.nombre != datosUnidadCopia.nombre) {
                fd.append("nombre", datosUnidad.nombre);
            }
            if (datosUnidad.unidad != datosUnidadCopia.unidad) {
                fd.append("unidad", datosUnidad.unidad);
            }

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        getUnidades();
                        mostrarError("Se ha actualizado Unidad: " + datosUnidad.id, "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setEditarUnidad(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido Actualizar Unidad: " + datosUnidad.id);
                })
            ocultarEditarUnidad();
        }
    };

    /******************************************************************/
    //                   Fin Dialog Editar Unidad                     //
    /******************************************************************/

    /******************************************************************/
    //                   Inicio Dialog Borrar Unidad                  //
    /******************************************************************/
    const [estadoBorrar, setEstadoBorrar] = React.useState(false);
    const [borrando, setBorrando] = React.useState(false);
    const [idUnidad, setIdUnidad] = useState(0);

    const confirmarBorrarUnidad = (id, nombre, unidad) => {
        setBorrando(false);
        setIdUnidad(id);
        setDatosUnidad({
            id: id,
            nombre: nombre,
            unidad: unidad
        });
        setEstadoBorrar(true);
    };
    const comprobarESCBorrarUnidad = (event) => {
        if (event.keyCode === 27) {
            cancelBorrarUnidad();
        }
    };
    const cancelBorrarUnidad = () => {
        setIdUnidad(0);
        setDatosUnidad({
            id: '',
            nombre: '',
            unidad: ''
        });
        setBorrando(false);
        setEstadoBorrar(false);
        getUnidades();
    }

    const borrarUnidad = () => {
        setBorrando(true);
        const fd = new FormData();
        fd.append("f", "maquinas.deleteUnidadMedida");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_unidad", idUnidad);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id === 0) {
                    getUnidades();
                    mostrarError("Borrada unidad: " + datosUnidad.id + " " + datosUnidad.nombre, "success");
                } else {
                    mostrarError(error.msg);
                }
                setBorrando(false);
            })
            .catch(() => {
                mostrarError("No se ha podido Borrar Unidad " + idUnidad);
            })

        cancelBorrarUnidad();
    };
    /******************************************************************/
    //                   Fin Dialog Borrar Unidad                     //
    /******************************************************************/
    /******************************************************************/
    //                   Dialog Crear Caracteristica                  //
    /******************************************************************/

    const [estadoCrearCaracteristica, setEstadoCrearCaracteristica] = React.useState(false);
    const [creandoCaracteristica, setCreandoCaracteristica] = React.useState(false);

    const [nuevaCaracteristica, setNuevaCaracteristica] = useState([{
        nombre: '',
        id_unidad: '',
    }]);
    const mostrarCrearCaracteristica = () => {
        getUnidades();
        setEstadoCrearCaracteristica(true);
        setNuevaCaracteristica({
            nombre: '',
            id_unidad: '',
        });

    };
    const comprobarESCCrearCaracteristica = (event) => {
        if (event.keyCode === 27) {
            ocultarCrearCaracteristica();
        }
    };
    const ocultarCrearCaracteristica = () => {
        setNuevaCaracteristica({
            nombre: '',
            id_unidad: '',
        });
        setEstadoCrearCaracteristica(false);
        setCreandoCaracteristica(false);
        getMaquinasCaracteristicas();
    };

    const confirmarCrearCaracteristica = () => {
        let id_unidad = nuevaCaracteristica.id_unidad;
        if (nuevaCaracteristica.nombre.trim() === '' || id_unidad === '') {
            mostrarError("No se admiten espacios vacios");
        } else {
            setCreandoCaracteristica(true);

            const fd = new FormData();
            fd.append("f", "maquinas.crearCaracteristica");
            fd.append("token", localStorage.getItem('token'));

            if (nuevaCaracteristica.nombre != datosCaracteristicaCopia.nombre) {
                fd.append("nombre", nuevaCaracteristica.nombre);
            }
            if (nuevaCaracteristica.id_unidad != datosCaracteristicaCopia.id_unidad) {
                fd.append("id_unidad", nuevaCaracteristica.id_unidad);
            }

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        getMaquinasCaracteristicas();
                        mostrarError("Se ha creado Caracteristica: " + result.caracteristica, "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setCreandoCaracteristica(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido Crear Caracteristica");
                })
            ocultarCrearCaracteristica();
        }
    };
    /******************************************************************/
    //                 Fin Dialog Crear Caracteristica                //
    /******************************************************************/
    /******************************************************************/
    //                   Dialog Editar Caracteristica                 //
    /******************************************************************/

    const [estadoEditarCaracteristica, setEstadoEditarCaracteristica] = React.useState(false);
    const [editarCaracteristica, setEditarCaracteristica] = React.useState(false);

    const [datosCaracteristica, setDatosCaracteristica] = useState([{
        id: '',
        nombre: '',
        id_unidad: '',
        unidad: ''
    }]);
    const [datosCaracteristicaCopia, setDatosCaracteristicaCopia] = useState([{
        id: '',
        nombre: '',
        id_unidad: '',
        unidad: ''
    }]);

    const mostrarEditarCaracteristica = (id, nombre, id_unidad, unidad) => {
        getUnidades();
        setEstadoEditarCaracteristica(true);
        setDatosCaracteristica({
            id: id,
            nombre: nombre,
            id_unidad: id_unidad,
            unidad: unidad
        });
        setDatosCaracteristicaCopia({
            id: id,
            nombre: nombre,
            id_unidad: id_unidad,
            unidad: unidad
        });
    };
    const comprobarESCEditarCaracteristica = (event) => {
        if (event.keyCode === 27) {
            ocultarEditarCaracteristica();
        }
    };
    const ocultarEditarCaracteristica = () => {
        setDatosCaracteristica({
            id: '',
            nombre: '',
            id_unidad: '',
            unidad: ''
        });
        setEstadoEditarCaracteristica(false);
        setEditarCaracteristica(false);
        getMaquinasCaracteristicas();
    };

    const confirmarEditarCaracteristica = () => {
        let id_unidad = datosCaracteristica.id_unidad;
        if (datosCaracteristica.nombre.trim() === '' || id_unidad === '') {
            mostrarError("No se admiten espacios vacios");
        } else if (datosCaracteristica.nombre.trim() === datosCaracteristicaCopia.nombre.trim() && id_unidad === datosCaracteristicaCopia.id_unidad) {
            mostrarError("No hay cambios a guardar");
        } else {
            setEditarCaracteristica(true);

            const fd = new FormData();
            fd.append("f", "maquinas.updateCaracteristica");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id", datosCaracteristica.id);

            if (datosCaracteristica.nombre != datosCaracteristicaCopia.nombre) {
                fd.append("nombre", datosCaracteristica.nombre);
            }
            if (datosCaracteristica.id_unidad != datosCaracteristicaCopia.id_unidad) {
                fd.append("id_unidad", datosCaracteristica.id_unidad);
            }

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        getMaquinasCaracteristicas();
                        mostrarError("Caracteristica: " + datosCaracteristica.id + " actualizada", "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setEditarCaracteristica(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido Actualizar Caracteristica: " + datosCaracteristica.id);
                })
            ocultarEditarCaracteristica();
        }
    };

    /******************************************************************/
    //                  Fin Dialog Editar Caracteristica              //
    /******************************************************************/
    /******************************************************************/
    //                  Dialog Borrar Caracteristica                   //
    /******************************************************************/
    const [estadoBorrarCaracteristica, setEstadoBorrarCaracteristica] = React.useState(false);
    const [borrandoCaracteristica, setBorrandoCaracteristica] = React.useState(false);
    const [idCaracteristica, setIdCaracteristica] = useState(0);

    const confirmarBorrarCaracteristica = (id, nombre, unidad) => {
        setBorrandoCaracteristica(false);
        setIdCaracteristica(id);
        setDatosUnidad({
            id: id,
            nombre: nombre,
            unidad: unidad
        });
        setEstadoBorrarCaracteristica(true);
    };
    const comprobarESCBorrarCaracteristica = (event) => {
        if (event.keyCode === 27) {
            cancelBorrarCaracteristica();
        }
    };
    const cancelBorrarCaracteristica = () => {
        setIdCaracteristica(0);
        setDatosUnidad({
            id: '',
            nombre: '',
            unidad: ''
        });
        setBorrandoCaracteristica(false);
        setEstadoBorrarCaracteristica(false);
        getMaquinasCaracteristicas();
    }

    const borrarCaracteristica = () => {
        setBorrandoCaracteristica(true);
        const fd = new FormData();
        fd.append("f", "maquinas.deleteCaracteristica");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_caracteristica", idCaracteristica);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id === 0) {
                    getMaquinasCaracteristicas();
                    mostrarError("Borrada Caracteristica: " + datosUnidad.id + " " + datosUnidad.nombre, "success");
                } else {
                    mostrarError(error.msg);
                }
                setBorrandoCaracteristica(false);
            })
            .catch(() => {
                mostrarError("No se ha podido Borrar la Caracteristica " + idCaracteristica);
            })

        cancelBorrarCaracteristica();
    };
    /******************************************************************/
    //                 Fin Dialog Borrar Caracteristica               //
    /******************************************************************/
    /******************************************************************/
    //                  Dialog Tipos Caracteristica                   //
    /******************************************************************/
    const [idCaracteristicaTipo, setIdCaracteristicaTipo] = useState(0);
    const [nombreTipoCaracteristica, setNombreTipoCaracteristica] = useState("");

    const [datosTipo, setDatosTipo] = useState([{
        id: '',
        nombre: ''
    }]);
    const [datosTipoCopia, setDatosTipoCopia] = useState([{
        id: '',
        nombre: ''
    }]);

    const [estadoEditarTipoCaracteristica, setEstadoEditarTipoCaracteristica] = React.useState(false);
    const [editandoTipoCaracteristica, setEditandoTipoCaracteristica] = React.useState(false);

    const mostrarEditarTipo = (id, nombre) => {
        getTiposPropiedades(id);
        setNombreTipoCaracteristica(nombre);
        setEstadoEditarTipoCaracteristica(true);
        setDatosTipo({
            id: id,
            nombre: nombre
        });
        setDatosTipoCopia({
            id: id,
            nombre: nombre
        });
    };

    const comprobarESCEditarTipoCaracteristica = (event) => {
        if (event.keyCode === 27) {
            ocultarEditarTipoCaracteristica();
        }
    };
    const ocultarEditarTipoCaracteristica = () => {
        setDatosTipo({
            nombre: '',
            id_unidad: ''
        });
        setDatosTipoCopia({
            nombre: '',
            id_unidad: ''
        });
        setListaPropiedades([]);
        setEstadoEditarTipoCaracteristica(false);
        setEditandoTipoCaracteristica(false);
    };

    const confirmarEditarTipoCaracteristica = () => {

        if (datosTipo.nombre.trim() === '') {
            mostrarError("No se admiten espacios vacios");
        } else if (datosTipo.nombre.trim() === datosTipoCopia.nombre.trim()) {
            mostrarError("No hay cambios a guardar");
        } else {
            setEditandoTipoCaracteristica(true);

            const fd = new FormData();
            fd.append("f", "maquinas.updateTipoCaracteristica");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_tipo", datosTipo.id);
            fd.append("nombre", datosTipo.nombre);

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        getCaracteristicasTipos(idCaracteristicaTipo);
                        mostrarError("Nombre del Tipo: " + datosTipo.id + " actualizado", "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setEditandoTipoCaracteristica(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido actualizar Tipo Caracteristica");
                })
            ocultarEditarTipoCaracteristica();
        }
    };


    /******************************************************************/
    //              Dialog Crear Tipos Caracteristica                 //
    /******************************************************************/

    const [estadoCrearTipoCaracteristica, setEstadoCrearTipoCaracteristica] = React.useState(false);
    const [creandoTipoCaracteristica, setCreandoTipoCaracteristica] = React.useState(false);

    const mostrarCrearCaracteristicasTipos = () => {
        setEstadoCrearTipoCaracteristica(true);
        setNuevaCaracteristica({
            nombre: '',
            id_unidad: '',
        });
    };
    const comprobarESCCrearTipoCaracteristica = (event) => {
        if (event.keyCode === 27) {
            ocultarCrearTipoCaracteristica();
        }
    };
    const ocultarCrearTipoCaracteristica = () => {
        setNuevaCaracteristica({
            nombre: '',
            id_unidad: '',
        });
        setEstadoCrearTipoCaracteristica(false);
        setCreandoTipoCaracteristica(false);
        getCaracteristicasTipos(idCaracteristicaTipo);
    };

    const confirmarCrearTipoCaracteristica = () => {

        if (nuevaCaracteristica.nombre.trim() === '' && idCaracteristicaTipo != 0) {
            mostrarError("No se admiten espacios vacios");
        } else {
            setCreandoTipoCaracteristica(true);

            const fd = new FormData();
            fd.append("f", "maquinas.crearTipoCaracteristica");
            fd.append("token", localStorage.getItem('token'));
            fd.append("nombre", nuevaCaracteristica.nombre);
            fd.append("id_caracteristica", idCaracteristicaTipo);

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        getCaracteristicasTipos(idCaracteristicaTipo);
                        mostrarError("Se ha creado Tipo Caracteristica: " + result.tipo, "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setCreandoTipoCaracteristica(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido Crear Tipo Caracteristica");
                })
            ocultarCrearTipoCaracteristica();
        }
    };

    /******************************************************************/
    //             Fin Dialog Crear Tipos Caracteristica              //
    /******************************************************************/
    /******************************************************************/
    //                  Dialog Borrar Tipos Caracteristica            //
    /******************************************************************/

    const [estadoBorrarTipo, setEstadoBorrarTipo] = React.useState(false);
    const [borrandoTipo, setBorrandoTipo] = React.useState(false);
    const [idTipo, setIdTipo] = useState(0);

    const confirmarBorrarTipo = (id, nombre, unidad) => {
        setBorrandoTipo(false);
        setIdTipo(id);
        setDatosUnidad({
            id: id,
            nombre: nombre,
            unidad: unidad
        });
        setEstadoBorrarTipo(true);
    };
    const comprobarESCBorrarTipo = (event) => {
        if (event.keyCode === 27) {
            cancelBorrarTipo();
        }
    };
    const cancelBorrarTipo = () => {
        setIdTipo(0);
        setDatosUnidad({
            id: '',
            nombre: '',
            unidad: ''
        });
        setBorrandoTipo(false);
        setEstadoBorrarTipo(false);
        getCaracteristicasTipos(idCaracteristicaTipo);
    };

    const deleteTipoCaracteristica = () => {
        setBorrandoTipo(true);
        const fd = new FormData();
        fd.append("f", "maquinas.deleteTipoCaracteristica");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_tipo", idTipo);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id === 0) {
                    getCaracteristicasTipos(idCaracteristicaTipo);
                    mostrarError("Borrado Tipo: " + datosUnidad.id + " " + datosUnidad.nombre, "success");
                } else {
                    mostrarError(error.msg);
                }
                setBorrandoTipo(false);
            })
            .catch(() => {
                mostrarError("No se ha podido Borrar el Tipo de Caracteristica " + idTipo);
            })

        cancelBorrarTipo();
    };

    /******************************************************************/
    //             Fin Dialog Borrar Tipos Caracteristica             //
    /******************************************************************/

    /******************************************************************/
    //              Dialog Crear Propiedad Tipos Caracteristica       //
    /******************************************************************/

    const [estadoCrearPropiedad, setEstadoCrearPropiedad] = React.useState(false);
    const [creandoPropiedad, setCreandoPropiedad] = React.useState(false);

    const [datosPropiedad, setDatosPropiedad] = useState([{
        nombre: '',
        valor: ''
    }]);
    const [datosPropiedadCopia, setDatosPropiedadCopia] = useState([{
        nombre: '',
        valor: ''
    }]);

    const mostrarCrearPropiedad = () => {
        setEstadoCrearPropiedad(true);
        setDatosPropiedad({
            nombre: '',
            valor: ''
        });
    };

    const comprobarESCCrearPropiedad = (event) => {
        if (event.keyCode === 27) {
            ocultarCrearPropiedad();
        }
    };

    const ocultarCrearPropiedad = () => {
        setDatosPropiedad({
            nombre: '',
            valor: ''
        });
        setEstadoCrearPropiedad(false);
        setCreandoPropiedad(false);
        getTiposPropiedades(datosTipo.id)
    };

    const confirmarCrearPropiedad = () => {
        if (datosPropiedad.nombre.trim() === '') {
            mostrarError("El nombre no puede estar vacio");
        } else {

            setCreandoPropiedad(true);

            const fd = new FormData();
            fd.append("f", "maquinas.crearPropiedad");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_tipo", datosTipo.id);
            fd.append("nombre", datosPropiedad.nombre.trim());

            if (datosPropiedad.valor.trim() != '') {
                fd.append("valor", datosPropiedad.valor.trim());
            }

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        getTiposPropiedades(datosTipo.id)
                        mostrarError("Se ha creado Propiedad: " + result.propiedad, "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setCreandoPropiedad(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido Crear Propiedad");
                })
            ocultarCrearPropiedad();
        }
    };

    /******************************************************************/
    //              Dialog Editar Propiedad Tipos Caracteristica       //
    /******************************************************************/
    const [idPropiedad, setIdPropiedad] = useState(0);
    const [estadoEditarPropiedad, setEstadoEditarPropiedad] = React.useState(false);
    const [editandoPropiedad, setEditandoPropiedad] = React.useState(false);

    const mostrarEditarPropiedad = (id, nombre, valor) => {
        setIdPropiedad(id);
        setEstadoEditarPropiedad(true);
        setDatosPropiedad({
            nombre: nombre,
            valor: valor
        });
        setDatosPropiedadCopia({
            nombre: nombre,
            valor: valor
        });
    };

    const comprobarESCEditarPropiedad = (event) => {
        if (event.keyCode === 27) {
            ocultarEditarPropiedad();
        }
    };
    const ocultarEditarPropiedad = () => {
        setDatosPropiedad({
            nombre: '',
            valor: ''
        });
        setDatosPropiedadCopia({
            nombre: '',
            valor: ''
        });
        setIdPropiedad(0);
        setEstadoEditarPropiedad(false);
        setEditandoPropiedad(false);
        getTiposPropiedades(datosTipo.id);
    };

    const confirmarEditarPropiedad = () => {

        if (datosPropiedad.nombre.trim() === '' && idPropiedad != 0) {
            mostrarError("No se admite el Nombre vacio");
        } else if (datosPropiedad.nombre.trim() === datosPropiedadCopia.nombre.trim() && datosPropiedad.valor.trim() === datosPropiedadCopia.valor.trim()) {
            mostrarError("No hay cambios ha realizar");
        } else {
            setEditandoPropiedad(true);

            const fd = new FormData();
            fd.append("f", "maquinas.updatePropiedad");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_propiedad", idPropiedad);
            fd.append("nombre", datosPropiedad.nombre.trim());

            if (datosPropiedad.valor.trim() != '') {
                fd.append("valor", datosPropiedad.valor.trim());
            }

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        mostrarError("Se ha actualizado propiedad: " + idPropiedad + " " + datosPropiedad.nombre, "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    getTiposPropiedades(datosTipo.id)
                    setEditandoPropiedad(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido actualizar la propiedad");
                })
            ocultarEditarPropiedad();
        }
    };


    /******************************************************************/
    //              Dialog Borrar Propiedad Tipos Caracteristica      //
    /******************************************************************/

    const [estadoBorrarPropiedad, setEstadoBorrarPropiedad] = React.useState(false);
    const [borrandoPropiedad, setBorrandoPropiedad] = React.useState(false);

    const confirmarBorrarPropiedad = (id, nombre, valor) => {
        setBorrandoPropiedad(false);
        setIdPropiedad(id);
        setDatosPropiedad({
            nombre: nombre,
            valor: valor
        });
        setEstadoBorrarPropiedad(true);
    }

    const comprobarESCBorrarPropiedad = (event) => {
        if (event.keyCode === 27) {
            cancelBorrarPropiedad();
        }
    };
    const cancelBorrarPropiedad = () => {
        setIdPropiedad(0);
        setDatosPropiedad({
            nombre: '',
            valor: ''
        });
        setBorrandoPropiedad(false);
        setEstadoBorrarPropiedad(false);
        getTiposPropiedades(datosTipo.id)
    }

    const deletePropiedad = () => {
        setBorrandoPropiedad(true);
        const fd = new FormData();
        fd.append("f", "maquinas.deletePropiedad");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_propiedad", idPropiedad);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id === 0) {
                    getTiposPropiedades(datosTipo.id)
                    mostrarError("Borrada Propiedad: " + idPropiedad + " " + datosPropiedad.nombre, "success");
                } else {
                    mostrarError(error.msg);
                }
                setBorrandoPropiedad(false);
            })
            .catch(() => {
                mostrarError("No se ha podido Borrar la Propiedad " + idPropiedad);
            })

        cancelBorrarPropiedad();
    };

    /******************************************************************/
    //                       Apartado de Grupos Maquinas              //
    /******************************************************************/

    const gruposMaquinas = (actualizado = 0) => {
        let grupos = [];
        const fd = new FormData();
        fd.append("f", "maquinas.getGruposMaquinas");
        fd.append("token", localStorage.getItem('token'));
        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id === 0) {
                    delete result.error;

                    //Recorremos el array de grupos para obtener su id y nombre.
                    Object.keys(result.maquinas_grupos).map((id, i) => {
                        var nombreGrupo = Object.values(result.maquinas_grupos)[i].nombre;
                        var idGrupo = Object.values(result.maquinas_grupos)[i].id;
                        grupos.push({ id: idGrupo, nombre: nombreGrupo });
                        return null;
                    })

                    setListaGrupos(grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1));
                    if (actualizado == 1) {
                        mostrarError("Grupos actualizados", "success");
                    }
                } else {
                    mostrarError(error.msg);
                }

            })
            .catch(() => {
                mostrarError("No se ha podido obtener los grupos");
            })
    }

    /******************************************************************/
    //                    Dialog Crear Grupos Maquinas                //
    /******************************************************************/

    const [estadoGrupo, setEstadoGrupo] = React.useState(false);
    const [creandoGrupo, setCreandoGrupo] = React.useState(false);
    const [errorNombreGrupo, setErrorNombreGrupo] = React.useState(false);

    const [nombreGrupo, setNombreGrupo] = useState("");

    const mostrarCrearGrupo = () => {
        setEstadoGrupo(true);
    };

    const comprobarESCGrupo = (event) => {
        if (event.keyCode === 27) {
            ocultarGrupo();
        }
    };

    const ocultarGrupo = () => {
        setErrorNombreGrupo(false);
        setNombreGrupo("");
        setEstadoGrupo(false);
        setCreandoGrupo(false);
        gruposMaquinas();
    };

    const confirmarCrearGrupo = () => {
        if (nombreGrupo.trim() === '') {
            setErrorNombreGrupo(true);
            mostrarError("El Nombre no puede estar vacio");
        } else {
            setErrorNombreGrupo(false);

            setCreandoGrupo(true);
            const fd = new FormData();
            fd.append("f", "maquinas.crearGrupo");
            fd.append("token", localStorage.getItem('token'));
            fd.append("nombre", nombreGrupo.trim());

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        mostrarError("Se ha creado el Grupo: " + result.grupo, "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setCreandoGrupo(false);
                    gruposMaquinas();
                })
                .catch(() => {
                    mostrarError("No se ha podido crear el Grupo");
                })
            ocultarGrupo();
        }
    };

    /******************************************************************/
    //                  Fin Dialog Crear Grupos Maquinas              //
    /******************************************************************/

    /******************************************************************/
    //                    Dialog Editar Grupos Maquinas               //
    /******************************************************************/
    const [idGrupo, setIdGrupo] = useState(0);
    const [nombreGrupoCopia, setNombreGrupoCopia] = useState("");

    const [estadoEditarGrupo, setEstadoEditarGrupo] = React.useState(false);
    const [editandoGrupo, setEditandoGrupo] = React.useState(false);

    const mostrarEditarGrupo = (id, nombre) => {
        setIdGrupo(id);
        setEstadoEditarGrupo(true);
        setEditandoGrupo(false);
        setNombreGrupo(nombre);
        setNombreGrupoCopia(nombre);
    };

    const comprobarESCEditarGrupo = (event) => {
        if (event.keyCode === 27) {
            ocultarEditarGrupo();
        }
    };
    const ocultarEditarGrupo = () => {
        setNombreGrupo("");
        setNombreGrupoCopia("");
        setIdGrupo(0);
        setEstadoEditarGrupo(false);
        setEditandoGrupo(false);
        gruposMaquinas();
    };

    const confirmarEditarGrupo = () => {
        if (nombreGrupo.trim() === '') {
            mostrarError("No se admiten espacios vacios");
        } else if (nombreGrupo.trim() === nombreGrupoCopia.trim()) {
            mostrarError("No hay cambios a guardar");
        } else {
            setEditandoGrupo(true);

            const fd = new FormData();
            fd.append("f", "maquinas.updateGrupo");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_grupo", idGrupo);
            fd.append("nombre", nombreGrupo);

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        gruposMaquinas();
                        mostrarError("Nombre del Grupo: " + idGrupo + " actualizado", "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setEditandoGrupo(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido actualizar el Grupo");
                })
            ocultarEditarGrupo();
        }
    };

    /******************************************************************/
    //                   Fin Dialog Editar Grupos Maquinas            //
    /******************************************************************/

    /******************************************************************/
    //                       Dialog Borrar Grupos Maquinas            //
    /******************************************************************/
    const [estadoBorrarGrupo, setEstadoBorrarGrupo] = React.useState(false);
    const [borrandoGrupo, setBorrandoGrupo] = React.useState(false);

    const mostrarBorrarGrupo = (id, nombre) => {
        setBorrandoGrupo(false);
        setIdGrupo(id);
        setNombreGrupo(nombre);
        setEstadoBorrarGrupo(true);
    }

    const comprobarESCBorrarGrupo = (event) => {
        if (event.keyCode === 27) {
            cancelBorrarGrupo();
        }
    };
    const cancelBorrarGrupo = () => {
        setIdGrupo(0);
        setNombreGrupo('');
        setBorrandoGrupo(false);
        setEstadoBorrarGrupo(false);
        gruposMaquinas()
    }

    const deleteGrupo = () => {
        setBorrandoGrupo(true);
        const fd = new FormData();
        fd.append("f", "maquinas.deleteGrupo");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_grupo", idGrupo);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id === 0) {
                    gruposMaquinas();
                    mostrarError("Borrado Grupo: " + idGrupo + " " + nombreGrupo, "success");
                } else {
                    mostrarError(error.msg);
                }
                setBorrandoGrupo(false);
            })
            .catch(() => {
                mostrarError("No se ha podido Borrar el Grupo" + idGrupo);
            })

        cancelBorrarGrupo();
    };


    /******************************************************************/
    //                   Fin Dialog Borrar Grupos Maquinas            //
    /******************************************************************/

    /******************************************************************/
    //                Dialog Caracteristicas Grupos Maquinas          //
    /******************************************************************/
    const [estadoListaCaracteristicasGrupo, setEstadoListaCaracteristicasGrupo] = React.useState(false);
    const [datosFiltroGrupo, setDatosFiltroGrupo] = useState({
        id: '',
        id_caracteristica: '',
        id_caracteristica_tipo: ''
    });

    const mostrarListaCaracteristicasGrupo = (id, nombre) => {
        setNombreGrupo(nombre);
        // console.log("Mostrar info: " + id, nombre);
        getCaracteristicasGrupos(id, 1);
        setEstadoListaCaracteristicasGrupo(true);
    }

    const comprobarESCListaCaracteristicasGrupo = (event) => {
        if (event.keyCode === 27) {
            ocultarListaCaracteristicasGrupo();
        }
    };
    const ocultarListaCaracteristicasGrupo = () => {
        setIdGrupo(0);
        setHotelTrabajo(0);
        setNombreGrupo('');
        setListaGruposCaracteristica([]);
        setDatosFiltroGrupo({
            id: '',
            id_caracteristica: '',
            id_caracteristica_tipo: ''
        });
        setEstadoListaCaracteristicasGrupo(false);
    }

    // Obtener las caracteristicas del Grupo seleccionado.
    const getCaracteristicasGrupos = (id, actualizo = 0, limpiarFiltro = 0) => {
        const datos = datosFiltroGrupo;
        let params = '';

        const fd = new FormData();
        fd.append("f", "maquinas.getCaracteristicasGrupos");
        fd.append("token", localStorage.getItem('token'));

        if (limpiarFiltro != 1) {
            Object.keys(datos).forEach((nombre, i) => {
                const valor = Object.values(datos)[i];
                if (valor && valor !== '') {
                    params += `${nombre}=${valor}|`;
                }
            });
            if (hotelTrabajo != 0) {
                fd.append("id_hotel", hotelTrabajo);
            }
        }

        params += `id_grupo=${id}|`;
        // console.log(params)

        fd.append("params", params);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                // console.log(result)
                if (error.id === 0) {
                    if (result.gruposCaracteristicas.length > 0) {
                        setListaGruposCaracteristica(result.gruposCaracteristicas);
                        if (actualizo == 1) {
                            mostrarError("Caracteristicas del Grupo obtenidas", "success");
                        }

                    } else {
                        setListaGruposCaracteristica([]);
                        mostrarError("El grupo no tiene Caracteristicas asociadas");
                    }

                } else {
                    setListaGruposCaracteristica([]);
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                setListaGruposCaracteristica([]);
                mostrarError("No se han podido obtener las caracteristicas asociadas al Grupo");
            })
    };

    /******************************************************************/
    //              Filtros Caracteristicas Grupos Maquinas           //
    /******************************************************************/
    const [activoFiltro, setActivoFiltro] = useState(false);
    const [estadoFiltros, setEstadoFiltros] = React.useState(false);

    let hoteles = localStorage.getItem('hoteles');
    const [hotelesLista, setHotelesLista] = useState([]);
    const [hotelTrabajo, setHotelTrabajo] = useState(0);

    const [listaCaracteristicaSelect, setListaCaracteristicaSelect] = useState([]);

    const mostrarFiltros = () => {
        setHotelesLista(JSON.parse(hoteles));
        getMaquinasCaracteristicas(0, 1);
        setListaTiposCaracteristicas([]);
        gruposMaquinas();
        setActivoFiltro(true);
        setEstadoFiltros(true);
    };

    const comprobarESC = (event) => {
        if (event.keyCode === 27) {
            ocultarFiltros();
        }
    };
    const comprobarEnter = (e) => {
        if (e.key === 'Enter') {
            ocultarFiltros();
        }
    }

    const ocultarFiltros = () => {
        setEstadoFiltros(false);
        setActivoFiltro(true);
        getCaracteristicasGrupos(idGrupo, 1);
    };

    const eliminarFiltros = () => {
        setDatosFiltroGrupo({
            id: '',
            id_caracteristica: '',
            id_caracteristica_tipo: ''
        });
        setActivoFiltro(false);
        setEstadoFiltros(false);
        setHotelTrabajo(0);
        getCaracteristicasGrupos(idGrupo, 1, 1);
    };

    const actualizarFiltros = (event) => {
        setDatosFiltroGrupo({
            ...datosFiltroGrupo,
            [event.target.name]: parseInt(event.target.value)
        });
        if (event.target.name === "id_caracteristica") {
            getCaracteristicasTipos(event.target.value);
        }
    };

    /******************************************************************/
    //            Dialog Crear Caracteristicas Grupos Maquinas        //
    /******************************************************************/
    const [estadoCrearFichaGrupo, setEstadoCrearFichaGrupo] = React.useState(false);
    const [creandoFichaGrupo, setCreandoFichaGrupo] = React.useState(false);

    const [datosFichaGrupo, setDatosFichaGrupo] = useState({
        id: '',
        id_caracteristica: '',
        id_caracteristica_tipo: '',
        id_hotel: '',
        valor: ''
    });

    const mostrarCrearFichaGrupo = () => {
        setHotelesLista(JSON.parse(hoteles));
        getMaquinasCaracteristicas(0, 1);
        setDatosFichaGrupo({
            id: '',
            id_caracteristica: '',
            id_caracteristica_tipo: '',
            id_hotel: '',
            valor: ''
        });
        setCreandoFichaGrupo(false);
        setEstadoCrearFichaGrupo(true);
    };

    const comprobarESCFichaGrupo = (event) => {
        if (event.keyCode === 27) {
            ocultarCrearFichaGrupo();
        }
    };
    const ocultarCrearFichaGrupo = () => {
        setDatosFichaGrupo({
            id: '',
            id_caracteristica: '',
            id_caracteristica_tipo: '',
            id_hotel: '',
            valor: ''
        });
        getCaracteristicasGrupos(idGrupo, 1, 1);
        setEstadoCrearFichaGrupo(false);
        setCreandoFichaGrupo(false);
    };

    const confirmarCrearFichaGrupo = () => {
        let id_caracteristica = datosFichaGrupo.id_caracteristica;
        let id_hotel = datosFichaGrupo.id_hotel;

        if (id_caracteristica != '' && id_hotel != '') {
            const fd = new FormData();
            fd.append("f", "maquinas.crearFichaGrupo");
            fd.append("token", localStorage.getItem('token'));

            let params = '';
            let id_hotel = '';
            Object.keys(datosFichaGrupo).forEach((nombre, i) => {
                const valor = Object.values(datosFichaGrupo)[i];
                if (nombre === "id_hotel") {
                    id_hotel = valor;
                } else if (valor && valor !== '') {
                    params += `${nombre}=${valor}|`;
                }

            });

            params += `id_grupo=${idGrupo}|`;
            // console.log(params)

            fd.append("params", params);
            fd.append("id_hotel", id_hotel);

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        getCaracteristicasGrupos(idGrupo, 1, 1);
                        mostrarError("Caracteristica creada: " + result.id, "success");
                        ocultarCrearFichaGrupo();
                    } else {
                        mostrarError(error.msg);
                        console.log(error.msg);
                    }
                })
                .catch(() => {
                    mostrarError("No se ha podido crear la Caracteristica");
                })

        } else {
            mostrarError("Es necesario seleccionar hotel y caracteristica validos");
        }
    };

    const actualizarCrearFichaGrupo = (event) => {
        setDatosFichaGrupo({
            ...datosFichaGrupo,
            [event.target.name]: parseInt(event.target.value)
        });
        if (event.target.name === "id_caracteristica") {
            getCaracteristicasTipos(event.target.value);
        }
    };
    /******************************************************************/
    //            Dialog Editar Caracteristicas Grupos Maquinas       //
    /******************************************************************/
    const [datosFichaGrupoCopia, setDatosFichaGrupoCopia] = useState({
        id: '',
        id_caracteristica: '',
        id_caracteristica_tipo: '',
        id_hotel: '',
        valor: ''
    });
    const [estadoEditarFichaGrupo, setEstadoEditarFichaGrupo] = React.useState(false);
    const [editandoFichaGrupo, setEditandoFichaGrupo] = React.useState(false);

    const mostrarEditarFichaGrupo = (id, id_caracteristica, id_caracteristica_tipo, id_hotel, valor) => {
        setHotelesLista(JSON.parse(hoteles));
        getMaquinasCaracteristicas(0, 0);
        getCaracteristicasTipos(id_caracteristica, 0, 1);

        setDatosFichaGrupo({
            id: id,
            id_caracteristica: id_caracteristica,
            id_caracteristica_tipo: id_caracteristica_tipo,
            id_hotel: id_hotel,
            valor: valor
        });
        setDatosFichaGrupoCopia({
            id: id,
            id_caracteristica: id_caracteristica,
            id_caracteristica_tipo: id_caracteristica_tipo,
            id_hotel: id_hotel,
            valor: valor
        });
        setEstadoEditarFichaGrupo(true);
        setEditandoFichaGrupo(false);
    };


    const comprobarESCEditarFichaGrupo = (event) => {
        if (event.keyCode === 27) {
            ocultarEditarFichaGrupo();
        }
    };
    const ocultarEditarFichaGrupo = () => {

        setEstadoEditarFichaGrupo(false);
        setEditandoFichaGrupo(false);
        getCaracteristicasGrupos(idGrupo, 0, 0);
    };

    const confirmarEditarFichaGrupo = () => {

        if (JSON.stringify(datosFichaGrupo) === JSON.stringify(datosFichaGrupoCopia)) {
            mostrarError("No hay cambios a guardar");
        } else {
            setEditandoFichaGrupo(true);

            let params = '';
            let id_hotel = '';

            Object.keys(datosFichaGrupo).forEach((nombre) => {
                const valor = datosFichaGrupo[nombre];
                const valorCopia = datosFichaGrupoCopia[nombre];

                if (valor !== valorCopia) { // Solo si hay diferencia
                    // console.log("El nombre es: ", nombre)
                    // console.log("El valor es: ", valor)
                    if (nombre === "id_hotel") {
                        id_hotel = valor; // Asignamos id_hotel fuera de params
                    } else if (nombre === "id_caracteristica_tipo") {
                        // Intentamos convertir el valor a un entero
                        const valorInt = parseInt(valor, 10);

                        // Si el valor es vacío o NaN, asignamos NULL
                        if (isNaN(valorInt)) {
                            params += `${nombre}=NULL|`;
                        } else {
                            params += `${nombre}=${valorInt}|`;
                        }
                    } else {
                        // Añadimos el nombre y valor (incluso si el valor está vacío o es null)
                        params += `${nombre}=${valor || ''}|`;
                    }
                }
            });
            // console.log(params)
            const fd = new FormData();
            fd.append("f", "maquinas.updateFichaGrupo");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_ficha", datosFichaGrupo.id);
            fd.append("params", params);
            if (id_hotel != '') {
                fd.append("id_hotel", id_hotel);
            }

            const requestOptions = {
                method: 'POST',
                body: fd
            };

            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    // console.log(result)
                    if (error.id === 0) {
                        getCaracteristicasGrupos(idGrupo, 0, 0);
                        mostrarError("Caracteristica actualizada", "success");
                    } else {
                        mostrarError(error.msg);
                    }
                    setEditandoFichaGrupo(false);
                })
                .catch(() => {
                    mostrarError("No se ha podido actualizar el Grupo");
                })
            ocultarEditarFichaGrupo();

        }
    };




    /******************************************************************/
    //            Dialog Borrar Caracteristicas Grupos Maquinas       //
    /******************************************************************/
    const [estadoBorrarFichaGrupo, setEstadoBorrarFichaGrupo] = React.useState(false);
    const [borrandoFichaGrupo, setBorrandoFichaGrupo] = React.useState(false);

    const mostrarBorrarFichaGrupo = (id, caracteristica, tipo, hotel, valor) => {
        setDatosFichaGrupo({
            id: id,
            id_caracteristica: caracteristica,
            id_caracteristica_tipo: tipo,
            id_hotel: hotel,
            valor: valor
        });
        setBorrandoFichaGrupo(false);
        setEstadoBorrarFichaGrupo(true);
    };

    const comprobarESCBorrarFichaGrupo = (event) => {
        if (event.keyCode === 27) {
            cancelBorrarFichaGrupo();
        }
    };

    const cancelBorrarFichaGrupo = () => {
        setDatosFichaGrupo({
            id: '',
            id_caracteristica: '',
            id_caracteristica_tipo: '',
            id_hotel: '',
            valor: ''
        });
        getCaracteristicasGrupos(idGrupo, 0, 0);
        setBorrandoFichaGrupo(false);
        setEstadoBorrarFichaGrupo(false);
    }

    const confirmarBorrarFichaGrupo = () => {
        setBorrandoFichaGrupo(true);
        const fd = new FormData();
        fd.append("f", "maquinas.deleteFichaGrupo");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_ficha", datosFichaGrupo.id);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id === 0) {
                    getCaracteristicasGrupos(idGrupo, 0, 0);
                    mostrarError("Borrada caracteristica: " + datosFichaGrupo.id + " " + datosFichaGrupo.id_caracteristica, "success");
                } else {
                    mostrarError(error.msg);
                }
                setBorrandoFichaGrupo(false);
            })
            .catch(() => {
                mostrarError("No se ha podido Borrar la caracteristica " + datosFichaGrupo.id);
            })

        cancelBorrarFichaGrupo();
    }
    /******************************************************************/
    //            Fin Dialog Caracteristicas Grupos Maquinas          //
    /******************************************************************/

    const mostrarError = (mensajeError, tipoError) => {
        setMensajeError(mensajeError);
        if (tipoError) {
            setTipoError(tipoError);
        } else {
            setTipoError("error");
        }
        setContadorError(contadorError + 1);
        setEstadoAlert(true);
        const timer2 = setTimeout(() => {
            setEstadoAlert(false);
        }, 3000);
        return () => clearTimeout(timer2);
    };

    const [tabValue, setTabValue] = useState(0); // Estado para la pestaña activa

    const getUnidades = () => {
        const fd = new FormData();
        fd.append("f", "maquinas.getUnidadMedida");
        fd.append("token", localStorage.getItem('token'));
        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id === 0) {
                    // console.log(result)
                    setListaUnidades(result.unidades);
                } else {
                    //vaciar datosMaquina
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                mostrarError("No se han podido obtener las Unidades");
            })
    };

    const getMaquinasCaracteristicas = (actualizado = 0, filtros = 0) => {
        const fd = new FormData();
        fd.append("f", "maquinas.getMaquinasCaracteristicas");
        fd.append("token", localStorage.getItem('token'));
        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                // console.log("getMaquinasCaracteristicas")
                // console.log(result);
                if (error.id === 0) {

                    const caracteristicas = Object.entries(result.maquinas_caracteristicas).map(([key, value]) => ({
                        id: value.id,
                        nombre: value.nombre
                    }));

                    if (filtros === 0) {
                        setListaCaracteristicas(result.maquinas_caracteristicas);
                    } else {
                        // console.log(result.maquinas_caracteristicas)
                        caracteristicas.unshift({ "id": " ", "nombre": " " })
                    }
                    setListaCaracteristicaSelect(caracteristicas.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1));

                    if (actualizado == 1) {
                        mostrarError("Caracteristicas actualizadas", "success");
                    }
                } else {
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                mostrarError("No se han podido obtener las Unidades");
            })
    };

    // Obtenemos los tipos de Caracteristicas.
    const getCaracteristicasTipos = (id, actualizo = 0, vacio = 0) => {
        const fd = new FormData();
        fd.append("f", "maquinas.getCaracteristicasTipos");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id", id);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                // console.log("getCaracteristicasTipos")
                // console.log(result)
                if (error.id === 0) {
                    if (result.tipos.length > 0) {
                        if (vacio === 1) {
                            // console.log(result.tipos)
                            const tipos = Object.entries(result.tipos).map(([key, value]) => ({
                                id: value.id,
                                id_caracteristica: value.id_caracteristica,
                                nombre: value.nombre
                            }));
                            tipos.unshift({ "id": "", "id_caracteristica": "", "nombre": "" })
                            setListaTiposCaracteristicas(tipos);

                        } else {
                            setListaTiposCaracteristicas(result.tipos);
                        }

                        if (actualizo === 1) {
                            mostrarError("Tipos obtenidos", "success");
                        }

                    } else {
                        setListaTiposCaracteristicas([]);
                        mostrarError("La caracteristica no tiene Tipos.");
                    }

                } else {
                    setListaTiposCaracteristicas([]);
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                setListaTiposCaracteristicas([]);
                mostrarError("No se han podido obtener las Tipos");
            })

    };

    // Obtenemos las Propiedades del Tipo, de una Caracteristica
    const getTiposPropiedades = (id, actualizo = 0) => {
        const fd = new FormData();
        fd.append("f", "maquinas.getTiposPropiedad");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id", id);

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);

                let error = result.error;
                if (error.id === 0) {
                    if (result.propiedades.length > 0) {
                        setListaPropiedades(result.propiedades);
                        if (actualizo == 1) {
                            mostrarError("Propiedades obtenidas", "success");
                        }
                    } else {
                        setListaPropiedades([]);
                        mostrarError("El tipo no tiene propiedades asignadas.");
                    }
                } else {
                    setListaPropiedades([]);
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                mostrarError("No se han podido obtener las Propiedades");
            })
    };


    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                gruposMaquinas();
                break;
            case 1:
                // Vaciamos posible contenido
                setIdCaracteristicaTipo(0);
                setListaTiposCaracteristicas([]);
                // Obtenemos las Caracteristicas
                getMaquinasCaracteristicas();
                break;
            case 2:
                getUnidades();
                break;

        }
    };

    const crearConfiguracion = () => {
        switch (tabValue) {
            case 0:
                mostrarCrearGrupo();
                break;
            case 1:
                mostrarCrearCaracteristica();
                break;
            case 2:
                mostrarCrearUnidades();
                break;
        }
    };
    const actualizarConfiguracion = () => {
        switch (tabValue) {
            case 0:
                gruposMaquinas(1);
                break;
            case 1:
                getMaquinasCaracteristicas(1);
                break;
            case 2:
                getUnidades();
                break;
        }
    };

    useEffect(() => {
        setTablaUnidades(<DataGrid
            className='grid listaUnidades'
            rowHeight={52}//63
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
            }
            onRowClick={(params, event) => {
                if (!event.target.closest('button')) {
                    // clickInfoMaquina(params, event);
                    // console.log(params, event);
                }
            }}
            getRowId={(listaUnidades) => listaUnidades.id}
            rows={listaUnidades}
            columns={columnas}
            maxColumns={6}
            pageSize={12}
            rowsPerPageOptions={[12]}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'nombre', sort: 'asc' }],
                },
            }}
            localeText={{
                noRowsLabel: "No se ha encontrado datos.",
                noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}

        />)
    }, [listaUnidades]);

    useEffect(() => {
        setTablaCaracteristicas(<DataGrid
            className='grid listaUnidades'
            rowHeight={52}//63
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
            }
            onRowClick={(params, event) => {
                if (!event.target.closest('button')) {
                    setIdCaracteristicaTipo(params.id);
                    getCaracteristicasTipos(params.id, 0);
                    // console.log(params, event);
                }
            }}
            getRowId={(listaCaracteristicas) => listaCaracteristicas.id}
            rows={listaCaracteristicas}
            columns={columnasCaracteristicas}
            maxColumns={6}
            pageSize={12}
            rowsPerPageOptions={[12]}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'nombre', sort: 'asc' }],
                },
            }}
            localeText={{
                noRowsLabel: "No se ha encontrado datos.",
                noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}

        />)
    }, [listaCaracteristicas]);

    useEffect(() => {
        setTablaListaTiposCaracteristicas(<DataGrid
            className='grid listaUnidades'
            rowHeight={52}//63
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
            }

            getRowId={(listaTiposCaracteristicas) => listaTiposCaracteristicas.id}
            rows={listaTiposCaracteristicas}
            columns={columnasTiposCaracteristicas}
            maxColumns={6}
            pageSize={12}
            rowsPerPageOptions={[12]}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'nombre', sort: 'asc' }],
                },
            }}
            localeText={{
                noRowsLabel: "No se ha encontrado datos.",
                noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}

        />)
    }, [listaTiposCaracteristicas]);

    useEffect(() => {
        setTablaPropiedades(<DataGrid
            className='grid alturaTipos'
            rowHeight={56}//63
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
            }
            getRowId={(listaPropiedades) => listaPropiedades.id}
            rows={listaPropiedades}
            columns={columnasPropiedadesCaracteristicas}
            maxColumns={5}
            pageSize={10}
            rowsPerPageOptions={[10]}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'nombre', sort: 'asc' }],
                },
            }}
            localeText={{
                noRowsLabel: "No se ha encontrado datos.",
                noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}

        />)
    }, [listaPropiedades]);

    useEffect(() => {
        setTablaGrupos(<DataGrid
            className='grid listaUnidades'
            rowHeight={52}//63
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
            }
            onRowClick={(params, event) => {
                if (!event.target.closest('button')) {
                    setIdGrupo(params.id);
                    mostrarListaCaracteristicasGrupo(params.id, params.row.nombre);
                }
            }}

            getRowId={(listaGrupos) => listaGrupos.id}
            rows={listaGrupos}
            columns={columnasGrupos}
            maxColumns={4}
            pageSize={12}
            rowsPerPageOptions={[12]}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'nombre', sort: 'asc' }],
                },
            }}
            localeText={{
                noRowsLabel: "No se ha encontrado datos.",
                noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}

        />)
    }, [listaGrupos]);

    useEffect(() => {
        setTablaGruposCaracteristica(<DataGrid
            className='grid dialogListaCaracteristicas'
            rowHeight={52}//63
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
            }
            // onRowClick={(params, event) => {
            //     if (!event.target.closest('button')) {
            //         console.log(params.id);
            //     }
            // }}

            getRowId={(listaGruposCaracteristica) => listaGruposCaracteristica.id}
            rows={listaGruposCaracteristica}
            columns={columnasGruposCaracteristicas}
            maxColumns={8}
            pageSize={12}
            rowsPerPageOptions={[12]}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'nombre', sort: 'asc' }],
                },
            }}
            localeText={{
                noRowsLabel: "No se ha encontrado datos.",
                noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}

        />)
    }, [listaGruposCaracteristica]);

    useEffect(() => {
        gruposMaquinas();
    }, []);


    return (
        <>
            <div className='divConfiguracion'>
                <div className='full-width'>
                    <Tabs value={tabValue} onChange={handleChangeTab}>
                        <Tab label="Grupos" />
                        <Tab label="Caracteristicas" />
                        <Tab label="Unidades de medida" />
                    </Tabs>
                    <Divider></Divider>

                    <div className='container-filtros alturaDivHab alturaDivLista'>
                        <div className='dispFlex'>
                            <div className=' containerAcciones'>
                                <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                                    <HtmlTooltip
                                        placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">
                                                    {tabValue == 0 ? 'Crear Grupo' : tabValue == 1 ? 'Crear Caracteristica' : 'Crear Unidades Medidas'}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    >
                                        <Button
                                            className="boton"
                                            onClick={() => crearConfiguracion()}
                                        >
                                            <AddCircleOutlineIcon className='icon-button ' />
                                        </Button>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">
                                                    {tabValue == 0 ? 'Actualizar Grupo' : tabValue == 1 ? 'Actualizar Caracteristica' : 'Actualizar Unidades Medidas'}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    >
                                        <Button
                                            className="boton"
                                            onClick={() => actualizarConfiguracion()}
                                        >
                                            <RotateLeftIcon className='icon-button ' />
                                        </Button>
                                    </HtmlTooltip>
                                </ButtonGroup>
                            </div>
                            {tabValue === 1 && idCaracteristicaTipo != 0 && (
                                <div className=' containerAcciones'>
                                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                                        <HtmlTooltip
                                            placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">
                                                        Crear Tipo Caracteristica
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <Button
                                                className="boton"
                                                onClick={() => mostrarCrearCaracteristicasTipos()}
                                            >
                                                <AddCircleOutlineIcon className='icon-button ' />
                                            </Button>
                                        </HtmlTooltip>
                                        <HtmlTooltip
                                            placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">
                                                        Actualizar Tipo Caracteristica
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <Button
                                                className="boton"
                                                onClick={() => getCaracteristicasTipos(idCaracteristicaTipo, 1)}
                                            >
                                                <RotateLeftIcon className='icon-button ' />
                                            </Button>
                                        </HtmlTooltip>
                                    </ButtonGroup>
                                </div>
                            )}

                        </div>
                        {tabValue === 0 && (
                            <div className='containerInfoConf alturaMinimaDiv'>
                                <div className='infoPartesHab alturaMinima'>
                                    {tablaGrupos}
                                </div>
                            </div>
                        )}
                        {tabValue === 1 && (
                            <div className='containerInfoConf alturaMinimaDiv containerTablas'>
                                <div className='infoPartesHab alturaMinima minimoAncho'>
                                    {tablaCaracteristicas}
                                </div>
                                <div className='infoPartesHab alturaMinima minimoAncho'>
                                    {tablaListaTiposCaracteristicas}
                                </div>
                            </div>
                        )}
                        {tabValue === 2 && (
                            <div className='containerInfoConf alturaMinimaDiv'>
                                <div className='infoPartesHab alturaMinima'>
                                    {tablaUnidades}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {
                estadoAlert ?
                    <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
                    : ""
            }

            {/*Incicio Dialogs Unidades Medida*/}
            <Dialog fullScreen={fullScreen}
                open={estadoUnidad}
                onClose={comprobarESCUnidad}
                aria-labelledby="responsive-dialog-title" id="dialogCrearUnidad">
                <DialogTitle>Crear Unidad de Medida</DialogTitle>
                <DialogContent>
                    <div className='editarHab'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosUnidad ? (datosUnidad.nombre ? datosUnidad.nombre : '') : ''}
                            onChange={(e) => setDatosUnidad(prevState => ({ ...prevState, nombre: e.target.value }))}
                            inputProps={{ className: 'bold' }}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                            error={errorNombreMedida}
                            helperText={errorNombreMedida ? "Campo requerido" : ""}
                        />
                        <TextField
                            label="Unidad"
                            name="unidad"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosUnidad ? (datosUnidad.unidad ? datosUnidad.unidad : '') : ''}
                            onChange={(e) => setDatosUnidad(prevState => ({ ...prevState, unidad: e.target.value }))}
                            inputProps={{ className: 'bold' }}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                            error={errorUnidadMedida}
                            helperText={errorUnidadMedida ? "Campo requerido" : ""}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarUnidad}>Cancelar</Button>
                        {creandoUnidad ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={crearUnidad}>Crear</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoEditarUnidad}
                onClose={comprobarESCEditarUnidad}
                aria-labelledby="responsive-dialog-title" id="dialogEditarUnidad">
                <DialogTitle>Editar Unidad de Medida</DialogTitle>
                <DialogContent>
                    <div className='editarHab'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosUnidad ? (datosUnidad.nombre ? datosUnidad.nombre : '') : ''}
                            onChange={(e) => setDatosUnidad(prevState => ({ ...prevState, nombre: e.target.value }))}
                            // inputProps={{ className: 'bold' }}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                        <TextField
                            label="Unidad"
                            name="unidad"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosUnidad ? (datosUnidad.unidad ? datosUnidad.unidad : '') : ''}
                            onChange={(e) => setDatosUnidad(prevState => ({ ...prevState, unidad: e.target.value }))}
                            // inputProps={{ className: 'bold' }}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarEditarUnidad}>Cancelar</Button>
                        {editarUnidad ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarEditarUnidad}>Guardar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoBorrar}
                onClose={comprobarESCBorrarUnidad}
                aria-labelledby="responsive-dialog-title" id="dialogBorrarUnidad">
                <DialogTitle>Seguro que quiere borrar la Unidad?</DialogTitle>
                <DialogContent>
                    <div className=''>
                        {datosUnidad.id + " - " + datosUnidad.nombre + " - " + datosUnidad.unidad}
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={cancelBorrarUnidad}>Cancelar</Button>
                        {borrando ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className=" colorGrupo borrarBoton" onClick={borrarUnidad}>Borrar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            {/*Incicio Dialogs Caracteristicas*/}
            {/*Create, Update, Delete*/}
            <Dialog fullScreen={fullScreen}
                open={estadoCrearCaracteristica}
                onClose={comprobarESCCrearCaracteristica}
                aria-labelledby="responsive-dialog-title" id="crearCaracteristica">
                <DialogTitle>Crear Caracteristica</DialogTitle>
                <DialogContent>
                    <div className='editarHab minimoAnchoContenido'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={nuevaCaracteristica ? (nuevaCaracteristica.nombre ? nuevaCaracteristica.nombre : '') : ''}
                            onChange={(e) => setNuevaCaracteristica(prevState => ({ ...prevState, nombre: e.target.value }))}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            label="Unidad"
                            name="unidad"
                            className='observaciones'
                            size="small"
                            value={nuevaCaracteristica ? (nuevaCaracteristica.id_unidad ? nuevaCaracteristica.id_unidad : '') : ''}
                            onChange={(e) => setNuevaCaracteristica(prevState => ({ ...prevState, id_unidad: e.target.value }))}
                            select
                        >
                            {listaUnidades.map((unidad) => (
                                <MenuItem key={`unidadMedida-${unidad.id}`} value={unidad.id}>
                                    {unidad.nombre} - {unidad.unidad}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarCrearCaracteristica}>Cancelar</Button>
                        {creandoCaracteristica ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarCrearCaracteristica}>Crear</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoEditarCaracteristica}
                onClose={comprobarESCEditarCaracteristica}
                aria-labelledby="responsive-dialog-title" id="dialogEditarCaracteristica">
                <DialogTitle>Editar Caracteristica</DialogTitle>
                <DialogContent>
                    <div className='editarHab minimoAnchoContenido'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosCaracteristica ? (datosCaracteristica.nombre ? datosCaracteristica.nombre : '') : ''}
                            onChange={(e) => setDatosCaracteristica(prevState => ({ ...prevState, nombre: e.target.value }))}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            label="Unidad"
                            name="unidad"
                            className='observaciones'
                            size="small"
                            value={datosCaracteristica ? (datosCaracteristica.id_unidad ? datosCaracteristica.id_unidad : '') : ''}
                            onChange={(e) => setDatosCaracteristica(prevState => ({ ...prevState, id_unidad: e.target.value }))}
                            select
                        >
                            {listaUnidades.map((unidad) => (
                                <MenuItem key={`unidadMedida-${unidad.id}`} value={unidad.id}>
                                    {unidad.nombre} - {unidad.unidad}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarEditarCaracteristica}>Cancelar</Button>
                        {editarCaracteristica ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarEditarCaracteristica}>Guardar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoBorrarCaracteristica}
                onClose={comprobarESCBorrarCaracteristica}
                aria-labelledby="responsive-dialog-title" id="dialogBorrarCaracteristica">
                <DialogTitle>Seguro que quiere borrar la Caracteristica?</DialogTitle>
                <DialogContent>
                    <div className=''>
                        {datosUnidad.id + " - " + datosUnidad.nombre + " - " + datosUnidad.unidad}
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={cancelBorrarCaracteristica}>Cancelar</Button>
                        {borrandoCaracteristica ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className=" colorGrupo borrarBoton" onClick={borrarCaracteristica}>Borrar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            {/*Incicio Dialogs Tipo Caracteristicas*/}
            {/*Create, Update, Delete*/}
            <Dialog fullScreen={fullScreen}
                open={estadoCrearTipoCaracteristica}
                onClose={comprobarESCCrearTipoCaracteristica}
                aria-labelledby="responsive-dialog-title" id="crearTipoCaracteristica">
                <DialogTitle>Crear Tipo Caracteristica</DialogTitle>
                <DialogContent>
                    <div className='editarHab minimoAnchoContenido'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={nuevaCaracteristica ? (nuevaCaracteristica.nombre ? nuevaCaracteristica.nombre : '') : ''}
                            onChange={(e) => setNuevaCaracteristica(prevState => ({ ...prevState, nombre: e.target.value }))}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarCrearTipoCaracteristica}>Cancelar</Button>
                        {creandoTipoCaracteristica ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarCrearTipoCaracteristica}>Crear</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoEditarTipoCaracteristica}
                onClose={comprobarESCEditarTipoCaracteristica}
                aria-labelledby="responsive-dialog-title" id="editarTipoCaracteristica">
                <DialogTitle>Editar Tipo Caracteristica: {nombreTipoCaracteristica}</DialogTitle>
                <DialogContent>

                    <div className='editarHab '>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='minimoAncho'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosTipo ? (datosTipo.nombre ? datosTipo.nombre : '') : ''}
                            onChange={(e) => setDatosTipo(prevState => ({ ...prevState, nombre: e.target.value }))}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                    </div>
                    <div className=' containerAcciones sinMargen'>
                        <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                            <HtmlTooltip
                                placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">
                                            Crear Propiedad
                                        </Typography>
                                    </React.Fragment>
                                }
                            >
                                <Button
                                    className="boton"
                                    onClick={() => mostrarCrearPropiedad()}
                                >
                                    <AddCircleOutlineIcon className='icon-button ' />
                                </Button>
                            </HtmlTooltip>
                            <HtmlTooltip
                                placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">
                                            Actualizar Propiedades
                                        </Typography>
                                    </React.Fragment>
                                }
                            >
                                <Button
                                    className="boton"
                                    onClick={() => getTiposPropiedades(datosTipo.id, 1)}
                                >
                                    <RotateLeftIcon className='icon-button ' />
                                </Button>
                            </HtmlTooltip>
                        </ButtonGroup>
                    </div>
                    <div>
                        {tablaPropiedades}
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarEditarTipoCaracteristica}>Cancelar</Button>
                        {editandoTipoCaracteristica ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarEditarTipoCaracteristica}>Guardar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoBorrarTipo}
                onClose={comprobarESCBorrarTipo}
                aria-labelledby="responsive-dialog-title" id="dialogBorrarTipo">
                <DialogTitle>Seguro que quiere borrar el Tipo de Caracteristica?</DialogTitle>
                <DialogContent>
                    <div className=''>
                        {datosUnidad.id + " - " + datosUnidad.nombre}
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={cancelBorrarTipo}>Cancelar</Button>
                        {borrandoTipo ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className=" colorGrupo borrarBoton" onClick={deleteTipoCaracteristica}>Borrar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
            {/*Incicio Dialogs Tipo Propiedades*/}
            {/*Create, Update, Delete*/}
            <Dialog fullScreen={fullScreen}
                open={estadoCrearPropiedad}
                onClose={comprobarESCCrearPropiedad}
                aria-labelledby="responsive-dialog-title" id="crearPropiedad">
                <DialogTitle>Crear Propiedad</DialogTitle>
                <DialogContent>
                    <div className='editarHab minimoAnchoContenido'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosPropiedad ? (datosPropiedad.nombre ? datosPropiedad.nombre : '') : ''}
                            onChange={(e) => setDatosPropiedad(prevState => ({ ...prevState, nombre: e.target.value }))}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                        <TextField
                            label="Valor"
                            name="valor"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosPropiedad ? (datosPropiedad.valor ? datosPropiedad.valor : '') : ''}
                            onChange={(e) => setDatosPropiedad(prevState => ({ ...prevState, valor: e.target.value }))}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarCrearPropiedad}>Cancelar</Button>
                        {creandoPropiedad ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarCrearPropiedad}>Crear</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoEditarPropiedad}
                onClose={comprobarESCEditarPropiedad}
                aria-labelledby="responsive-dialog-title" id="dialogEditarPropiedad">
                <DialogTitle>Editar Propiedad</DialogTitle>
                <DialogContent>
                    <div className='editarHab minimoAnchoContenido'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosPropiedad ? (datosPropiedad.nombre ? datosPropiedad.nombre : '') : ''}
                            onChange={(e) => setDatosPropiedad(prevState => ({ ...prevState, nombre: e.target.value }))}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                        <TextField
                            label="Valor"
                            name="valor"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={datosPropiedad ? (datosPropiedad.valor ? datosPropiedad.valor : '') : ''}
                            onChange={(e) => setDatosPropiedad(prevState => ({ ...prevState, valor: e.target.value }))}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarEditarPropiedad}>Cancelar</Button>
                        {editandoPropiedad ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarEditarPropiedad}>Guardar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoBorrarPropiedad}
                onClose={comprobarESCBorrarPropiedad}
                aria-labelledby="responsive-dialog-title" id="dialogBorrarPropiedad">
                <DialogTitle>Seguro que quiere borrar la Propiedad?</DialogTitle>
                <DialogContent>
                    <div className=''>
                        {idPropiedad + " - " + datosPropiedad.nombre}
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={cancelBorrarPropiedad}>Cancelar</Button>
                        {borrandoPropiedad ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className=" colorGrupo borrarBoton" onClick={deletePropiedad}>Borrar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoGrupo}
                onClose={comprobarESCGrupo}
                aria-labelledby="responsive-dialog-title" id="dialogCrearGrupo">
                <DialogTitle>Crear Grupo de Maquinas</DialogTitle>
                <DialogContent>
                    <div className='editarHab'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={nombreGrupo ? (nombreGrupo ? nombreGrupo : '') : ''}
                            onChange={(e) => setNombreGrupo(e.target.value)}
                            inputProps={{ className: 'bold' }}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                            error={errorNombreGrupo}
                            helperText={errorNombreGrupo ? "Campo requerido" : ""}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarGrupo}>Cancelar</Button>
                        {creandoGrupo ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarCrearGrupo}>Crear</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoEditarGrupo}
                onClose={comprobarESCEditarGrupo}
                aria-labelledby="responsive-dialog-title" id="dialogEditarGrupo">
                <DialogTitle>Editar Grupo: {nombreGrupoCopia}</DialogTitle>
                <DialogContent>
                    <div className='editarHab minimoAnchoContenido'>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            className='observaciones'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            value={nombreGrupo ? nombreGrupo : ''}
                            onChange={(e) => setNombreGrupo(e.target.value)}
                            InputLabelProps={{ className: 'bold', shrink: true }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarEditarGrupo}>Cancelar</Button>
                        {editandoGrupo ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarEditarGrupo}>Guardar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoBorrarGrupo}
                onClose={comprobarESCBorrarGrupo}
                aria-labelledby="responsive-dialog-title" id="dialogBorrarGrupo">
                <DialogTitle>Seguro que quiere borrar el Grupo?</DialogTitle>
                <DialogContent>
                    <div className=''>
                        {idGrupo + " - " + nombreGrupo}
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={cancelBorrarGrupo}>Cancelar</Button>
                        {borrandoGrupo ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className=" colorGrupo borrarBoton" onClick={deleteGrupo}>Borrar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoListaCaracteristicasGrupo}
                onClose={comprobarESCListaCaracteristicasGrupo}
                aria-labelledby="responsive-dialog-title" id="listaCaracteristicasGrupo">
                <DialogTitle>Caracteristicas del Grupo: {idGrupo + " - " + nombreGrupo}</DialogTitle>
                <DialogContent>
                    <div className=' containerAcciones sinMargen'>
                        <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                            <HtmlTooltip
                                placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">
                                            Añadir Caracteristica al Grupo del Hotel
                                        </Typography>
                                    </React.Fragment>
                                }
                            >
                                <Button
                                    className="boton"
                                    onClick={() => mostrarCrearFichaGrupo()}
                                >
                                    <AddCircleOutlineIcon className='icon-button ' />
                                </Button>
                            </HtmlTooltip>
                            <HtmlTooltip
                                placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">
                                            Actualizar Caracteristicas asociadas al Grupo
                                        </Typography>
                                    </React.Fragment>
                                }
                            >
                                <Button
                                    className="boton"
                                    onClick={() => getCaracteristicasGrupos(idGrupo, 1)}
                                >
                                    <RotateLeftIcon className='icon-button ' />
                                </Button>
                            </HtmlTooltip>
                            <HtmlTooltip
                                placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">{"Filtrar"}</Typography>
                                    </React.Fragment>
                                }
                            >
                                <Button
                                    className={activoFiltro === true ? "boton filtro pintado" : "boton filtro"}
                                    onClick={mostrarFiltros}
                                >
                                    <FilterAltIcon className='icon-button' />
                                </Button>
                            </HtmlTooltip>
                        </ButtonGroup>
                    </div>
                    <div>
                        {tablaGruposCaracteristica}
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarListaCaracteristicasGrupo}>Cancelar</Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoFiltros}
                onClose={comprobarESC}
                aria-labelledby="responsive-dialog-title" id="dialogoFiltros" className='dialogMaquinaCrearParte'>
                <DialogTitle>Filtros</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => { e.preventDefault(); }}>
                        <div className='bloqueCaracteristicaGrupo'>
                            <TextField
                                label="ID"
                                variant="outlined"
                                margin="normal"
                                type="number"
                                onKeyPress={(ev) => { comprobarEnter(ev) }}
                                name="id"
                                size="small"
                                value={datosFiltroGrupo.id ? datosFiltroGrupo.id : ''}
                                onChange={actualizarFiltros}
                            />
                            <TextField
                                className='fifty'
                                variant="outlined"
                                margin="normal"
                                label="Hotel"
                                name="id_hotel"
                                id="id_hotel"
                                size="small"
                                value={hotelTrabajo ? hotelTrabajo : ""}
                                onChange={(e) => setHotelTrabajo(e.target.value)}
                                select
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                        }
                                    }
                                }}
                            >
                                {hotelesLista.map((hotel) => (
                                    <MenuItem key={hotel.value} value={hotel.value} >
                                        {hotel.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Caracteristica"
                                variant="outlined"
                                margin="normal"
                                name="id_caracteristica"
                                size="small"
                                value={datosFiltroGrupo.id_caracteristica != '' ? datosFiltroGrupo.id_caracteristica : ''}
                                onChange={actualizarFiltros}
                                select
                            >
                                {listaCaracteristicaSelect
                                    .map((grupo) => (
                                        <MenuItem key={grupo.id} value={grupo.id}>
                                            {grupo.nombre}
                                        </MenuItem>
                                    ))}
                            </TextField>
                            <TextField
                                label="Tipo"
                                variant="outlined"
                                margin="normal"
                                name="id_caracteristica_tipo"
                                size="small"
                                value={datosFiltroGrupo.id_caracteristica_tipo !== -1 ? datosFiltroGrupo.id_caracteristica_tipo : ''}
                                onChange={actualizarFiltros}
                                select
                            >
                                {listaTiposCaracteristicas
                                    .map((grupo) => (
                                        <MenuItem key={grupo.id} value={grupo.id}>
                                            {grupo.nombre}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={eliminarFiltros}>Cancelar</Button>
                        <Button className="colorGrupo" onClick={ocultarFiltros}>Filtrar</Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoCrearFichaGrupo}
                onClose={comprobarESCFichaGrupo}
                aria-labelledby="responsive-dialog-title" id="crearFichaGrupo" className='dialogMaquinaCrearParte'>
                <DialogTitle>Crear Caracteristica al Grupo: {idGrupo + " - " + nombreGrupo}</DialogTitle>
                <DialogContent>
                    <div className='bloqueCaracteristicaGrupo'>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            label="Hotel"
                            name="id_hotel"
                            id="id_hotel"
                            size="small"
                            value={datosFichaGrupo.id_hotel ? datosFichaGrupo.id_hotel : ""}
                            onChange={actualizarCrearFichaGrupo}
                            select
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                    }
                                }
                            }}
                        >
                            {hotelesLista.map((hotel) => (
                                <MenuItem key={hotel.value} value={hotel.value} >
                                    {hotel.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Caracteristica"
                            variant="outlined"
                            margin="normal"
                            name="id_caracteristica"
                            size="small"
                            value={datosFichaGrupo.id_caracteristica != '' ? datosFichaGrupo.id_caracteristica : ''}
                            onChange={actualizarCrearFichaGrupo}
                            select
                        >
                            {listaCaracteristicaSelect
                                .map((grupo) => (
                                    <MenuItem key={grupo.id} value={grupo.id}>
                                        {grupo.nombre}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <TextField
                            label="Tipo"
                            variant="outlined"
                            margin="normal"
                            name="id_caracteristica_tipo"
                            size="small"
                            value={datosFichaGrupo.id_caracteristica_tipo !== -1 ? datosFichaGrupo.id_caracteristica_tipo : ''}
                            onChange={actualizarCrearFichaGrupo}
                            select
                        >
                            {listaTiposCaracteristicas
                                // .sort((a, b) => (a.nombre > b.nombre) ? 1 : -1)
                                .map((grupo) => (
                                    <MenuItem key={grupo.id} value={grupo.id}>
                                        {grupo.nombre}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <TextField
                            label="Valor"
                            variant="outlined"
                            margin="normal"
                            name="valor"
                            size="small"
                            value={datosFichaGrupo.valor ? datosFichaGrupo.valor : ''}
                            onChange={actualizarCrearFichaGrupo}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarCrearFichaGrupo}>Cancelar</Button>
                        {creandoFichaGrupo ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarCrearFichaGrupo}>Crear</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoBorrarFichaGrupo}
                onClose={comprobarESCBorrarFichaGrupo}
                aria-labelledby="responsive-dialog-title" id="dialogBorrarFichaGrupo">
                <DialogTitle>Seguro que quiere borrar la Caracteristica del Grupo?</DialogTitle>
                <DialogContent>
                    <div className=''>
                        <p>{"Grupo: " + idGrupo + " - " + nombreGrupo}</p>
                        <p className='center'>Información Caracteristica a borrar:</p>
                        <div className='containerTablas'>
                            <div className='infoBorrar infoBorraPrimero'>
                                <span>ID:</span>
                                <span>{datosFichaGrupo.id}</span>
                            </div>
                            <div className='infoBorrar'>
                                <span>Caracteristica:</span>
                                <span>{datosFichaGrupo.id_caracteristica}</span>
                            </div>
                            <div className='infoBorrar'>
                                <span>Tipo:</span>
                                <span>{datosFichaGrupo.id_caracteristica_tipo}</span>
                            </div>
                            <div className='infoBorrar'>
                                <span>Valor:</span>
                                <span>{datosFichaGrupo.valor}</span>
                            </div>
                            <div className='infoBorrar'>
                                <span>Hotel:</span>
                                <span>{datosFichaGrupo.id_hotel}</span>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={cancelBorrarFichaGrupo}>Cancelar</Button>
                        {borrandoFichaGrupo ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className=" colorGrupo borrarBoton" onClick={confirmarBorrarFichaGrupo}>Borrar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen}
                open={estadoEditarFichaGrupo}
                onClose={comprobarESCEditarFichaGrupo}
                aria-labelledby="responsive-dialog-title" id="editarFichaGrupo" className='dialogMaquinaCrearParte'>
                <DialogTitle>Editar Caracteristica del Grupo: {idGrupo + " - " + nombreGrupo}</DialogTitle>
                <DialogContent>
                    <div className='bloqueCaracteristicaGrupo'>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            label="Hotel"
                            name="id_hotel"
                            id="id_hotel"
                            size="small"
                            value={datosFichaGrupo.id_hotel ? datosFichaGrupo.id_hotel : ""}
                            onChange={actualizarCrearFichaGrupo}
                            select
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                    }
                                }
                            }}
                        >
                            {hotelesLista.map((hotel) => (
                                <MenuItem key={hotel.value} value={hotel.value} >
                                    {hotel.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Caracteristica"
                            variant="outlined"
                            margin="normal"
                            name="id_caracteristica"
                            size="small"
                            value={datosFichaGrupo.id_caracteristica != '' ? datosFichaGrupo.id_caracteristica : ''}
                            onChange={actualizarCrearFichaGrupo}
                            select
                        >
                            {listaCaracteristicaSelect
                                .map((grupo) => (
                                    <MenuItem key={grupo.id} value={grupo.id}>
                                        {grupo.nombre}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <TextField
                            label="Tipo"
                            variant="outlined"
                            margin="normal"
                            name="id_caracteristica_tipo"
                            size="small"
                            value={datosFichaGrupo.id_caracteristica_tipo !== -1 ? datosFichaGrupo.id_caracteristica_tipo : ''}
                            onChange={actualizarCrearFichaGrupo}
                            select
                        >
                            {listaTiposCaracteristicas
                                // .sort((a, b) => (a.nombre > b.nombre) ? 1 : -1)
                                .map((grupo) => (
                                    <MenuItem key={grupo.id} value={grupo.id}>
                                        {grupo.nombre}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <TextField
                            label="Valor"
                            variant="outlined"
                            margin="normal"
                            name="valor"
                            size="small"
                            value={datosFichaGrupo.valor ? datosFichaGrupo.valor : ''}
                            onChange={actualizarCrearFichaGrupo}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarEditarFichaGrupo}>Cancelar</Button>
                        {editandoFichaGrupo ?
                            <Button className="colorGrupo" >
                                <CircularProgress className="botonCarga" color="inherit" />
                            </Button>
                            : <Button className="colorGrupo" onClick={confirmarEditarFichaGrupo}>Guardar</Button>
                        }
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default ConfMaquinas;
