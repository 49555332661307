// constantes

const dataInicial = {
    array:[]
}

const SET_ID = "SET_ID";
const GET_ID = "GET_ID";

// reducer
export default function idParteReducer(state = dataInicial, action){
    switch(action.type){
        case SET_ID:
            return {...state, array:action.payload}
        default:
            return state;
    }
}

// acciones
export const get_id = () => (dispatch, getState) =>{
    dispatch({
        type: GET_ID,
    })

}
export const set_id = (data) => (dispatch, getState) =>{
    dispatch({
        type: SET_ID,
        payload:data
    })
}